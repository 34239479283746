import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';


@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
    private platform: Platform
  ) { }

  is(platformName: Platforms) {
    return this.platform.is(platformName);
  }

  ready() {
    return this.platform.ready();
  }

}
