import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { WindowService } from '../window/window.service';
import { PlatformService } from '../platform/platform.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ErrorsService } from '../errors/errors.service';
import { Router } from '@angular/router';
import { EnvService } from '../env/env.service';


/**
* Service used to autentify a user of the app to the Auth server 
* 
* authenticationState observer will be updated accordingly
*/
const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const IS_IMPERSONATE = 'is_impersonate'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * Auth serveur url : to upate this var, go in the dev & prod environnement files
  */
  AuthUrl = environment.authUrl;
  userInfos: any = null;
  authenticationState: BehaviorSubject<any> = new BehaviorSubject(null);
  httpOptions: any = {};
  httpOptionsImageObs: any = new BehaviorSubject(null);
  httpOptionsImage: any = null;
  refreshTokenKey: string | null = null;
  accessTokenObs: any = new BehaviorSubject(null);
  isImpersonate: boolean = false;
  environment = environment;
  /**
  *  Call checkToken
  */
  constructor(private http: HttpClient,
    private helper: JwtHelperService,
    private storage: LocalStorageService,
    private windowService: WindowService,
    private platformService: PlatformService,
    private errorsService: ErrorsService,
    private router: Router,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
    this.AuthUrl = this.environment.authUrl;
    console.log('AUTH SERVICE Constructor()');
    this.httpOptions = this.getHeaderWithoutToken();
    this.checkToken();
    this.createLoginHeader(this.environment.basicLoginToken);
  }


  /**
  * return header for back service
  * @return {any} The header array
  */
  getHeader() {
    return this.httpOptions;
  }

  /**
* return header for image pipe 
* @return {any} The header array
*/
  getImageHeaderObs() {
    return this.httpOptionsImageObs.asObservable();
  }

  /**
   * return header for image pipe
   * @return {any} The header array
  */
  getImageHeader() {
    return this.httpOptionsImage;
  }

  /**
  * SetUp header with Bearer token and blob a content type
  * @param {string} token Access tocken
  */
  createImageheader(token: string) {
    console.log('AUTHSERVICE createImageheader');
    this.httpOptionsImage = {
      headers: new HttpHeaders({
        'Content-Type': 'image/png',
        'Authorization': `Bearer ${token}`
      })
    };
    this.httpOptionsImageObs.next({
      headers: new HttpHeaders({
        // 'Content-Type': 'image/png',
        'Authorization': `Bearer ${token}`
      })
    });
  }

  /**
  * SetUp header with Bearer token
  * @param {string} token Access tocken
  */
  createHeader(token: string) {
    console.log('AUTHSERVICE CreateHeader');
    this.createImageheader(token);
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  /**
  * SetUp header with Basic token
  * @param {string} token Basic Access tocken
  */
  createLoginHeader(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/ld+json',
      })
    };
  }

  /**
  * Create header without token
  */
  getHeaderWithoutToken() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/ld+json',
      })
    };
    return httpOptions;
  }


  /**
  * Create custom header
  * @param {Object} header infos
  */
  getCustomHeader(customHeader: any) {
    let httpOptions = {
      headers: new HttpHeaders(customHeader)
    };
    return httpOptions;
  }

  /**
   * Create header for patch request
   * @returns {any} The header array
   */
  getHeaderForPatch() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${this.accessTokenObs.value}`
      })
    };
  }

  getHeaderWithoutTokenPatch() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/merge-patch+json'
      })
    };
  }

  /**
  * Check if Access token is stored and valid. 
  * If valid create headers and update authenticationState to true
  * If missing update authenticationState to false.
  * If expired will call refreshTocken()
  */
  checkToken() {
    console.log('AUTH SERVICE - CheckToken()');
    this.storage.get(REFRESH_TOKEN_KEY).then((token: any) => {
      console.log('AUTH SERVICE checkToken(); refresh token recovred from storage');
      console.log(token);
      this.refreshTokenKey = token;
    });
    this.storage.get(TOKEN_KEY).then((token: any) => {
      console.log('AUTH SERVICE checkToken(); token recovred from storage');
      console.log(token);
      if (token) {
        this.userInfos = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        let expire = this.userInfos.exp;
        if (!isExpired) {
          console.log('AUTHSERVICE checkToken : token in DB and valid until :');
          console.log(expire);
          this.createHeader(token);
          this.accessTokenObs.next(token);
          this.authenticationState.next(true);
          this.autoRefreshToken(expire);
        } else {
          console.log('AUTHSERVICE checkToken : token in DB but expired');
          this.refreshToken();
        }
      } else {
        console.log('AUTH SERVICE checkToken(); No token found in storage');
        this.authenticationState.next(false);
      }
    });
    this.storage.get(IS_IMPERSONATE).then((isImpersonate: any) => {
      console.log('AUTH SERVICE checkToken(); IS_IMPERSONATE recovred from storage');
      console.log(isImpersonate);
      this.isImpersonate = isImpersonate;
    });
  }

  /**
  * Called by checkToken() when success : will call refreshToken() when token expire
  * @param {number} exp Token expiration in secondes
  */
  autoRefreshToken(exp: any) {
    let d = new Date();
    let now = d.getTime();
    let refreshdelay = exp * 1000 - now - 1;
    if (refreshdelay > 152460250) {
      refreshdelay = 152460250;
    }
    console.log('AUTH SERVICE autoRefreshToken() Will refresh in ');
    console.log(exp);
    console.log(refreshdelay);
    if (this.windowService.isPlatformBrowser()) {
      setTimeout(() => {
        console.log('autoRefreshToken() timmeout ended calling refreshToken()v1');
        this.refreshToken();
      }, refreshdelay);
    }
  }


  /**
  * If refresh is stored call requestNewAccessToken() else remove Access from DB and set authenticationState to false
  */
  refreshToken() {
    console.log('AUTH refreshToken()');
    this.storage.get(REFRESH_TOKEN_KEY).then((refreshToken: any) => {
      console.log('refresh token from storage =');
      console.log(refreshToken);
      if (refreshToken) {
        this.requestNewAccessToken(refreshToken).subscribe((res: any) => {
          console.log('RefreshTokenSuccess returned : ' + res);
        });
      } else {
        this.storage.remove(TOKEN_KEY);
        console.log("AUTH refreshToken() before logout");
        this.logout();
        this.authenticationState.next(false);
      }
    });
  }

  /**
  * Request a new AccessToken, store it, create header and set authenticationState to true if success
  *
  * @param {string} refreshToken Take Tefresh Token
  * @returns AuthServer response
  */
  requestNewAccessToken(refreshToken: string | null = this.refreshTokenKey) {
    console.log('requestNewAccessToken()');
    if (refreshToken) {
      let credentials = { "refresh_token": refreshToken.toString() };
      console.log('requestNewAccessToken() true if refreshToken');
      console.log(this.refreshTokenKey);
      console.log(`${this.environment.refreshToken}/${refreshToken}/token`);
      console.log(this.getHeaderWithoutToken());
      return this.http.post(`${this.environment.refreshToken}/${refreshToken}/token`, {}, this.getHeaderWithoutToken()).pipe(
        tap((res: any) => {
          console.log('AUTHSERVICE requestNewAccessToken, res =');
          console.log(res);
          this.storage.set(TOKEN_KEY, res['accessToken']);
          this.storage.set(REFRESH_TOKEN_KEY, res['refreshToken']);
          this.createHeader(res['accessToken']);
          this.userInfos = this.helper.decodeToken(res['accessToken']);
          let expire = this.userInfos.exp;
          console.log('AUTHSERVICE requestNewAccessToken, user =');
          console.log(this.userInfos);
          this.accessTokenObs.next(res['accessToken']);
          this.authenticationState.next(true);
          this.autoRefreshToken(expire);
        }),
        catchError(e => {
          console.log('requestNewAccessToken() catchError');
          this.logout();
          this.router.navigateByUrl('login');
          e = this.errorsService.formatError(e);
          this.errorsService.displayError(e);
          throw e;
        })
      );
    } else {
      console.log("AUTH requestNewAccessToken() before logout");
      if (!this.isImpersonate) {
        this.logout();
      }
      throw Error('No refresh token stored');
    }
  }

  /**
  * Register user to the Auth Server
  *
  * @param {credential} credentials Takes email password and more if specified on login page
  * @returns AuthServer response
  */
  register(credentials: any) {
    this.createLoginHeader(this.environment.basicLoginToken);
    return this.http.post(`${this.environment.registerPath}`, credentials, this.httpOptions).pipe(
      tap((res: any) => {
      }),
      catchError(e => {
        console.log(e);
        e = this.errorsService.formatError(e);
        this.errorsService.displayError(e);
        throw e;
      })
    );
  }

  /**
  * Log user to the Auth Server. Store token, call createHeader() and modify authentification state if success
  * @param {credentials} credentials Takes email password and more if specified on login page
  * @returns AuthServer response
  */
  login(credentials: any) {
    console.log('AUTH login :');
    console.log(this.platformService.is('ios'));
    let credentialWithFrom: any = credentials;
    if (this.environment.isPwa) {
      credentialWithFrom.from = 'web';
    } else if (this.platformService.is('ios')) {
      credentialWithFrom.from = 'app_ios';
    } else if (this.platformService.is('android')) {
      credentialWithFrom.from = 'app_android';
    }

    this.createLoginHeader(this.environment.basicLoginToken);
    console.log(this.httpOptions);
    return this.http.post(`${this.environment.loginPath}`, credentialWithFrom, this.httpOptions)
      .pipe(
        tap((res: any) => {
          let token = res['accessToken'];
          console.log('AUTHSERVICE login, res =');
          console.log(res);
          this.storage.set(TOKEN_KEY, token);
          this.storage.set(REFRESH_TOKEN_KEY, res['refreshToken']);
          this.refreshTokenKey = res['refreshToken'];
          this.createHeader(token);
          this.userInfos = this.helper.decodeToken(token);
          let expire = this.userInfos.exp;
          console.log('AUTHSERVICE login, user =');
          console.log(this.userInfos);
          this.accessTokenObs.next(token);
          this.authenticationState.next(true);
          this.autoRefreshToken(expire);
        }),
        catchError(e => {
          console.log('login() error');
          console.log(e);
          e = this.errorsService.formatError(e);
          this.errorsService.displayError(e);
          throw e;
        })
      );
  }

  /**
  * Creates headersinfos and set oauth state after SSO login or register
  **/
  setAuthAndTokenInfoAfterSso(tokenInfos: any) {
    console.log('AUTHSERVICE setAuthAndTokenInfoFromAfterSso, tokenInfos =');
    console.log(tokenInfos);
    this.storage.set(TOKEN_KEY, tokenInfos['accessToken']);
    this.storage.set(REFRESH_TOKEN_KEY, tokenInfos['refreshToken']);
    this.refreshTokenKey = tokenInfos['refreshToken'];
    this.createHeader(tokenInfos['accessToken']);
    this.userInfos = this.helper.decodeToken(tokenInfos['accessToken']);
    let expire = this.userInfos.exp;
    console.log('');
    console.log(this.userInfos);
    this.accessTokenObs.next(tokenInfos['accessToken']);
    this.authenticationState.next(true);
    this.autoRefreshToken(expire);
  }

  /** 
  *delete user password 
  **/
  deleteAccount() {
    console.log('AUTHSERVICE deleteAccount()');
    return this.http.delete(`${this.environment.registerPath}/me`, this.httpOptions).pipe(
      tap((res: any) => {
        this.logout();
      }),
      catchError(e => {
        console.log(e);
        e = this.errorsService.formatError(e);
        this.errorsService.displayError(e);
        throw e;
      })
    );
  }

  /**
  * reset Password 
  **/
  resetPassword(email: string) {
    console.log('AUTHSERVICE resetPassword()');
    return this.http.post(`${this.AuthUrl}${this.environment.resetPasswordPath}`, { 'email': email }, this.getHeaderWithoutToken()).pipe(
      tap((res: any) => {
        return true;

      }),
      catchError(e => {
        console.log(e);
        e = this.errorsService.formatError(e);
        this.errorsService.displayError(e);
        throw e;
      })
    );
  }

  /**
  * Remove token from DB and modify authntification state
  */
  logout() {
    this.storage.clear()?.then(res => {
      console.log('AUTHSERVICE logout() all storage cleared');
    });
    this.authenticationState.next(false);
    console.log("AUTHSERVICE logout() completed");
  }

  /**
  * update Password 
  **/
  updatePassword(oldPass: string | null = null, newPass: string | null = null) {
    console.log('AUTHSERVICE updatePassword()');
    let body = { password: newPass };
    return this.http.put(`${this.environment.userUpdate}`, body, this.httpOptions).pipe(
      tap((res: any) => {
        console.log('AUTH SERVICE - password updated');
      }),
      catchError(e => {
        console.log(e);
        e = this.errorsService.formatError(e);
        this.errorsService.displayError(e);
        throw e;
      })
    );
  }


  /**
  * Return Authentification state
  * @returns {BehaviorSubject} Authentification state
  */
  isAuthenticated() {
    return this.authenticationState.value;
  }

  /**
   * Return Authentification state as observable
   * @returns {BehaviorSubject} Authentification state
   */
  isAuthenticatedObs() {
    return this.authenticationState.asObservable();
  }



  /** PWA **/

  /**
  * Get from autologin token a new AccessToken & refreshToken, store it, create header and set authenticationState to true if success
  *
  * @param {string} refreshToken Take Tefresh Token
  * @returns AuthServer response
  */
  validateAutoLoginToken(autoLoginToken: string | null = null) {
    if (autoLoginToken) {
      return this.http.get(`${this.environment.autoLoginToken}/${autoLoginToken}/validate`, this.getHeaderWithoutToken()).pipe(
        tap((res: any) => {
          console.log('AUTHSERVICE validateAutoLoginToken, res =');
          console.log(res);
          if (res?.tokens?.accessToken) {
            let token = res.tokens.accessToken;
            this.storage.set(TOKEN_KEY, token);
            this.storage.set(REFRESH_TOKEN_KEY, res.tokens['refreshToken']);
            this.refreshTokenKey = res.tokens['refreshToken'];
            this.createHeader(token);
            this.userInfos = this.helper.decodeToken(token);
            let expire = this.userInfos.exp;
            console.log('AUTHSERVICE login, user =');
            console.log(this.userInfos);
            this.accessTokenObs.next(token);
            this.authenticationState.next(true);
            this.autoRefreshToken(expire);
            return "success";
          } else {
            return null;
          }
        }),
        catchError(e => {
          console.log('validateAutoLoginToken() error');
          e = this.errorsService.formatError(e);
          this.errorsService.displayError(e);
          throw e;
        })
      );
    } else {
      return;
    }
  }


  /** 
   * Used to login for debug and for impersonate
   * 
  */
  loginWithToken(accessToken: string) {
    this.storage.set(TOKEN_KEY, accessToken);
    this.storage.set(IS_IMPERSONATE, true);
    this.isImpersonate = true;
    this.createHeader(accessToken);
    this.userInfos = this.helper.decodeToken(accessToken);
    let expire = this.userInfos.exp;
    console.log('AUTHSERVICE loginWithToken, user =');
    console.log(this.userInfos);
    this.accessTokenObs.next(accessToken);
    this.authenticationState.next(true);
    this.autoRefreshToken(expire);
  }

  /**
   * Return Access token as observable
  */
  getAccessTokenObs() {
    return this.accessTokenObs.asObservable();
  }

}