import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';



const apisUrl = {
  backUrl: 'https://api.staging.clubofficine.net',
  authUrl: 'https://auth.staging.clubofficine.net',
  msgUrl: 'https://messaging.staging.clubofficine.net',
  offerUrl: 'https://offer.staging.clubofficine.net',
  boosterUrl: 'https://booster.staging.clubofficine.net',
  companyUrl: 'https://company.staging.clubofficine.net',
  recruiterUrl: 'https://recruiter.staging.clubofficine.net',
  proposeUrl: 'https://propose.staging.clubofficine.net',
  candidateUrl: 'https://candidate.staging.clubofficine.net',
  ssoUrl: 'https://oauth.staging.clubofficine.net',
  storageUrl: 'https://storage.staging.clubofficine.net',
  notificationUrl: 'https://device-notification.staging.clubofficine.net',
  subscription: 'https://subscription.staging.clubofficine.net',
  websiteUrl: 'https://website2.staging.clubofficine.net',
  simulator: "https://salary-simulator.staging.clubofficine.net",
  img: "https://offer-img-generator.staging.clubofficine.net",
  associateUrl: "https://associates.staging.clubofficine.net",
  mercureUrl: 'https://mercure.staging.clubofficine.net',
  userUrl: 'https://user.staging.clubofficine.net',
}

const stagingEnvironment = {
  websiteUrl: 'https://website2.staging.clubofficine.net',
  websiteExtention: '.net',
  isPwa: true,
  appVersion: '6.0.0',
  production: false,
  interimUid: 'da742cfc-3b79-4d1a-b1eb-0064c340bf5c',
  transactionnaireUid: '28e3bd83-ded5-4b48-8822-4d43273a0806',
  maintenanceUrl: apisUrl.storageUrl + '/api/static/status',
  backUrl: apisUrl.backUrl,
  authUrl: apisUrl.authUrl,
  msgUrl: apisUrl.authUrl,
  awsLocationRegion: 'eu-central-1', // Exemple : 'eu-west-1'
  awsLocationApiKeyApp: '',
  awsLocationApiKeyWeb: '',
  awsPlaceIndexName: 'herePlaceIndex',
  registerOnWebSite: 'https://www.clubofficine.fr/login',
  updateProfilOnWebSite: 'https://www.clubofficine.fr/candidat/profile/edition',
  loginPath: apisUrl.authUrl + '/api/token',
  refreshToken: apisUrl.authUrl + '/api/refresh-token',
  registerPath: apisUrl.authUrl + '/api/account',
  userUpdate: apisUrl.authUrl + '/users/me',
  usersPath: apisUrl.authUrl + '/users/me',
  account: apisUrl.authUrl + "/api/account/me",
  resetPasswordPath: "/api/reset-password-token",
  lastUpdatesPath: "/last-updates",
  basicLoginToken: '',    // REPLACE WITH '' in prod
  linkedinClientId: '77udzk5q0otmi7',
  poleEmploiClientId: "PAR_clubofficine_d7cbda099628551495133f0935f8e0ed319fcc2a08d56092102c8fe4a5505dfe",
  googleClientIdWeb: "533368438371-7ct8pp3r128mek8bvrfn6mtaulql13pa.apps.googleusercontent.com",
  googleClientIdAndroid: "533368438371-8tesqgtsiglvjnno9dr8d3p96j13pgf6.apps.googleusercontent.com",
  googleClientIdIos: "533368438371-o90clgm70t486u53oda8aet0r5hhs4jf.apps.googleusercontent.com",
  appleClientId: "fr.clubofficine.clubofficinesso2",
  facebookClientId: "577076597705851",
  threads: apisUrl.msgUrl + "/api/threads",
  message: apisUrl.msgUrl + "/api/messages",
  messageArchive: apisUrl.msgUrl + "/api/thread-participants",
  offer: apisUrl.offerUrl + "/api/offer",
  offers: apisUrl.offerUrl + "/api/offer",
  company: apisUrl.companyUrl + "/api/company",
  recruiter: apisUrl.recruiterUrl + "/api/recruiter",
  proposes: apisUrl.proposeUrl + "/api/proposal",
  readBy: apisUrl.msgUrl + "/api/read_bies",
  candidate: apisUrl.candidateUrl + "/api/candidate",
  candidates: apisUrl.candidateUrl + "/api/search/candidate",
  favorite: apisUrl.candidateUrl + "/api/favorite",
  favoriteOffer: apisUrl.offerUrl + "/api/favorite",
  favoriteOffers: apisUrl.offerUrl + "/api/offer/favorites",
  // sms: apisUrl.candidateUrl + "/api/validation/sms",
  tracking: apisUrl.candidateUrl + "/api/tracking/offer",
  linkedinSso: apisUrl.ssoUrl + "/api/linkedin",
  googleSso: apisUrl.ssoUrl + "/api/google",
  facebookSso: apisUrl.ssoUrl + "/api/facebook",
  facebookRedirectUri: apisUrl.websiteUrl + "/sso-facebook",
  appleRedirectUri: apisUrl.websiteUrl + "/sso-apple",
  googleRedirectUri: apisUrl.websiteUrl + "/sso-google",
  linkedinRedirectUri: apisUrl.websiteUrl + "/sso-linkedin",
  poleEmploiSso: apisUrl.ssoUrl + "/api/pole-emploi",
  appleSso: apisUrl.ssoUrl + "/api/apple",
  storageFile: apisUrl.storageUrl + "/api/storage",
  sessionUrl: apisUrl.storageUrl + "/api/session",
  randomProfilPicture: apisUrl.storageUrl + '/api/random-profile-picture',
  formationsList: apisUrl.candidateUrl + "/api/formations",
  jobsList: apisUrl.offerUrl + "/api/qualifications",
  missionsList: apisUrl.offerUrl + "/api/job_missions",
  languesList: apisUrl.candidateUrl + "/api/languages",
  remunerationList: apisUrl.offerUrl + "/api/salary_variables/1",
  skillsList: apisUrl.candidateUrl + "/api/skills",
  specialExperiencesList: apisUrl.candidateUrl + "/api/special_experiences",
  structuresList: apisUrl.offerUrl + "/api/company_categories",
  softwaresList: apisUrl.offerUrl + "/api/software",
  specialitiesList: apisUrl.companyUrl + "/api/specialities",
  registerDevice: apisUrl.notificationUrl + "/api/devices",
  notificationUrl: apisUrl.notificationUrl,
  booster: apisUrl.boosterUrl + '/api/booster_request',
  boosterIstest: true,
  resendValidationEmailCandidate: apisUrl.candidateUrl + "/api/validation/email/resend",
  resendValidationEmailRecruiter: apisUrl.recruiterUrl + "/api/validation/email/resend",
  resendValidationSmsCandidate: apisUrl.candidateUrl + "/api/validation/phone/resend",
  evaluation: apisUrl.candidateUrl + "/api/evaluation",
  recommendation: apisUrl.candidateUrl + "/api/recommendation",
  subscriptions: apisUrl.subscription + "/api/subscriptions/me",
  orders: apisUrl.subscription + "/api/orders/me",
  ordersAlerts: apisUrl.subscription + "/api/orders/alerts",
  cities: apisUrl.offerUrl + "/api/locality_heads_ups?page=1&itemsPerPage=200",
  simulator: apisUrl.simulator + "/api/salary_simulator",
  comparator: apisUrl.simulator + "/api/user-salaries",
  estimator: apisUrl.simulator + "/api/pharmacy-datas",
  gtmId: "GTM-5GMGNST",
  conversionId: "xxx",
  autoLoginToken: apisUrl.authUrl + '/api/auto_login_token',
  products: apisUrl.subscription + "/api/real_products",
  paymentLink: apisUrl.subscription + "/api/payment/link",
  payWith: apisUrl.subscription + '/api/orders/pay-with-',
  invoice: apisUrl.subscription + '/api/invoice',
  stripeConfig: {
    dynamic_tax_rates: ["txr_1J2bApHkKnSsMzKxrpigRuZK", "txr_1J2b5UHkKnSsMzKxjz8fVjyG"],
    standardPublicKey: "pk_test_pDqR8jRJrGuvXJwxmjSlIJl7"
  },
  emailValidationCandidate: apisUrl.candidateUrl + '/api/validation/email',
  emailValidationRecruiter: apisUrl.recruiterUrl + '/api/validation/email',
  imgFromId: apisUrl.img + '/api/offer',
  associateWishes: apisUrl.associateUrl + '/api/wishes',
  associatePropose: apisUrl.associateUrl + '/api/proposes',
  transactionSearch: apisUrl.associateUrl + '/api/associates/search',
  transactionProjects: apisUrl.associateUrl + '/api/projects',
  transactionLogs: apisUrl.associateUrl + '/api/project-logs',
  transactionProjectFavorites: apisUrl.associateUrl + '/api/project-favorites',
  transactionWishFavorites: apisUrl.associateUrl + '/api/wish-favorites',
  transactionGenerateDescription: apisUrl.associateUrl + '/api/projects/generate-ad-content',
  transactionPremium: apisUrl.associateUrl + '/api/projects/premium',
  creditBallance: apisUrl.associateUrl + '/api/consumables/balance',
  inMailCredits: apisUrl.associateUrl + '/api/inmail-consumables',
  publicationCredits: apisUrl.associateUrl + '/api/publication-consumables',
  globalCredits: apisUrl.associateUrl + '/api/credit-consumables',
  firebase: {
    apiKey: "AIzaSyAm_axv5XxtSBdMWURfoomTJ5GqBxXeBm4",
    authDomain: "clubofficine-8d18b.firebaseapp.com",
    databaseURL: "https://clubofficine-8d18b.firebaseio.com",
    projectId: "clubofficine-8d18b",
    storageBucket: "clubofficine-8d18b.appspot.com",
    messagingSenderId: "533368438371",
    appId: "1:533368438371:web:1a1a2cbcca88f1ee0b4256",
    measurementId: "G-FTKY11BGWL",
    vapidKey: "BImsdROo-DLeqs9ntcYnAljEk6BzS2ev80HPps3Oog97qfUBoGYzif_ROk89GZ0tp08QAEd4NykEhbv508nD7Bo"
  },
  resume: apisUrl.candidateUrl + '/api/candidate/resume',
  resumePhoto: apisUrl.candidateUrl + '/api/candidate/resume-photo',
  // doublonsCheck: apisUrl.candidateUrl + '/api/candidate/duplicate',
  // mergeDoublons: apisUrl.candidateUrl + '/api/candidate/merging',
  MERCURE_PUBLISH_URL: apisUrl.mercureUrl + '/.well-known/mercure',
  MERCURE_TOPIC_MESSAGING_BASE_URL: apisUrl.mercureUrl + '/.well-known/mercure',
  candidateDuplicatesCheck: apisUrl.candidateUrl + '/api/candidate/duplicate',
  mergeCandidatesDuplicates: apisUrl.candidateUrl + '/api/candidate/merging',
  recruiterDuplicatesCheck: apisUrl.recruiterUrl + '/api/recruiter/duplicate',
  mergeRecruiterDuplicates: apisUrl.recruiterUrl + '/api/recruiter/merging',
  sendMergeTokenRecruiter: apisUrl.recruiterUrl + '/api/recruiter/send-merge-token',
  unreadMessageCount: apisUrl.msgUrl + '/api/threads/unread/count',
  user: apisUrl.userUrl + '/api/users',
  fileValidations: apisUrl.userUrl + '/api/file_validations',
  passwordResetRequest: apisUrl.userUrl + '/api/users/password-reset-request',
  passwordResetValidation: apisUrl.userUrl + '/api/users/password-reset-validation',
};




@Injectable({
  providedIn: 'root'
})
export class EnvService {
  switchDate = new Date('2025-03-01T16:00:00'); // Samedi 1er mars à 16h
  envVariables: any = environment; // Par défaut, on utilise les var d'environment.ts

  constructor() {
    //  Si date pas passée alors on ecrase envVariables par staging
    if (new Date() < this.switchDate) {
      this.envVariables = stagingEnvironment;
    }
  }

  getEnvironmentVariables() {
    return this.envVariables;
  }
}
