import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { CookiesComponentModule } from './components/cookies/cookies.module';


// Modales
import { environment } from '../environments/environment';
import { SmsModalPageModule } from './pages/sms-modal/sms-modal.module';
import { MaintenanceModalPageModule } from './pages/maintenance-modal/maintenance-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';


registerLocaleData(localeFr, 'fr');

export function jwtOptionsFactory(storage: any) {
    return {
        tokenGetter: () => {
            return storage.get('access_token');
        },
        whitelistedDomains: ['localhost:5000']
    }
}


@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        IonicStorageModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage],
            }
        }),
        CookiesComponentModule,
        SmsModalPageModule,
        MaintenanceModalPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorsInterceptor,
        multi: true
    }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
