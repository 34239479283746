import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';

@Pipe({
	name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

	constructor(
		private http: HttpClient,
		private auth: AuthService
	) { }


	async transform(src: string) {
		console.log('authImagePipe');
		console.log(src);
		const headers = this.auth.getImageHeader();
		let promise = new Promise((resolve, reject) => {
			this.http.get(src, headers)
				.toPromise()
				.then(
					(res: any) => { // Success
						console.log('authImagePipe res =');
						console.log(res);

						resolve(res);
					},
					msg => { // Error
						reject(msg);
						console.log('authImagePipe error =');
						console.log(msg);
					}
				);
		});
		return promise;
	}
}


