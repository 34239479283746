import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFromApiValue'
})
export class TextFromApiValuePipe implements PipeTransform {

  transform(value: any, fieldName: string = ''): any {
    console.log("textFromApiValue pipe");
    console.log(value);
    console.log(fieldName);
    switch (fieldName) {
      case "languageLevel":
        switch (value) {
          case "elementary":
            return "Notions élémentaires"
            break;
          case "limited":
            return "Compétence professionnelle limitée"
            break;
          case "operational":
            return "Compétence professionnelle"
            break;
          case "fluent":
            return "Compétence professionnelle complète"
            break;
          case "native":
            return "Bilingue ou langue natale"
            break;
          default:
            return ""
            break;
        }
        break;

      default:
        return ""
        break;
    }
    return null;
  }

}
