<div>
  <p *ngIf="experiences && !experiences[0]"> Aucune expérience enregistrée</p>
  <ion-grid *ngIf="experiences && experiences[0]" class="blockContentGrid ion-no-padding">
    <ion-row *ngFor="let experience of experiences; let index=index" [ngClass]="{'firstRow': index==0}"
      (click)="addExperience(experience, experiences, index)">
      <ion-col size="2.8" class="ion-no-padding">
        <div *ngIf="experience.startAt && experience.endAt && !experience.inOffice" class="date"> De
          {{experience.startAt | dateOrTime:'yearMonth' }} à
          {{experience.endAt | dateOrTime:'yearMonth'}}</div>
        <div *ngIf="experience.startAt && experience.inOffice" class="date"> Depuis
          {{experience.startAt | dateOrTime:'yearMonth' }}</div>
      </ion-col>
      <ion-col size="8.4" class="ion-no-padding">
        <h5 class="companyName"> <span
            *ngIf="experience?.qualification?.shortLabel">{{experience.qualification.shortLabel}} - </span> <span
            *ngIf="experience?.companyName">{{experience.companyName}}</span> </h5>
        <div class="companyInfos"> <span *ngIf="experience?.companyType?.label">{{experience.companyType.label}} |
          </span>
          <span *ngIf="experience?.address?.city">{{experience.address.city}}</span>
        </div>
        <div *ngIf="experience?.description" class="description">{{experience.description}}
        </div>
      </ion-col>
      <ion-col size="0.6"><ion-icon name="pencil"></ion-icon></ion-col>
    </ion-row>
  </ion-grid>
</div>