import { Injectable, Inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(@Inject(DOCUMENT) private _doc: Document) {
    console.log("SERVER-WINDOW-SERVICE cst()");
    _doc.body.classList.remove("body--isServer");

  }
  getWindow(): Object {
    return window;
  }

  getWidth(): number {
    return window.innerWidth;
  }

  getheight(): number {
    return window.innerHeight;
  }

  getResizeEvents() {
    return fromEvent(window, 'resize');
  }

  open(url: string, type: string = "_blank", options: string | undefined = undefined) {
    var newWin = window.open(url, type, options);
    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      alert("Veuillez désactiver votre bloqueur de pop-up et cliquez à nouveau sur le lien");
    }
  }

  getCrypto() {
    const crypto: any = window.crypto;
    return crypto;
  }

  getLocationOrigin() {
    const locationOrigin: any = window.location.origin;
    return locationOrigin;
  }

  getMessagesEvents() {
    return fromEvent(window, 'message');
  }

  close() {
    window.close();
  }

  getUserAgent() {
    return navigator.userAgent;
  }

  isPlatformServer() {
    return false;
  }

  isPlatformBrowser() {
    return true;
  }


}
