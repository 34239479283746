import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { map, tap, catchError, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../../services/profil/profil.service';
import { Storage } from '@ionic/storage';
import { SettingsService } from '../../services/settings/settings.service';
import { AlgoliaService } from '../../services/algolia/algolia.service';
import { EnvService } from '../env/env.service';


@Injectable({
  providedIn: 'root'
})

/** 
 * Service used to manage candidates
 */
export class CandidateService {
  userFields = [
    'firstname', 'lastname', 'maidenName', 'email', 'birthdate',
    'phone', 'personalAddress', 'projectListening', 'rpps', 'sponsorCode', 'isAnonymous'
  ];
  filesValidationFields = ['ProfilePicture', 'Identity', 'Graduation', 'Resume'];
  userUid: string | null = null;
  candidate: any = null;
  candidateObs: BehaviorSubject<any> = new BehaviorSubject(null);
  ssoApple: string | null = null;
  candidatesReadyObs: BehaviorSubject<any> = new BehaviorSubject(null);
  candidatesReady: boolean = false;
  userRoles: Array<string> = ['ROLE_CANDIDATE'];
  candidatesViewedList: Array<any> = [];
  profilCompletion: number | null = null;
  profilCompletionObs: BehaviorSubject<any> = new BehaviorSubject(null);
  environment = environment;

  constructor(
    private algoliaService: AlgoliaService,
    private settingsService: SettingsService,
    private storage: Storage,
    private profilService: ProfilService,
    private alertController: AlertController,
    private backApiService: BackApiService,
    private envService: EnvService
  ) {
    console.log('CANDIDATESERVICE constructor()');
    this.environment = this.envService.getEnvironmentVariables();
    this.storage.get('candidatesViewedList').then((val) => {
      if (val) {
        this.candidatesViewedList = val;
      }
    });
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
    });
    this.settingsService.getSsoApple().subscribe((sso: string) => {
      this.ssoApple = sso;
    });
    this.profilService.getUserRolesObs().pipe(distinctUntilChanged()).subscribe(roles => {
      console.log('CANDIDATESERVICE initializeApp() roles =');
      console.log(roles);
      if (roles && roles.sort().join() !== this.userRoles.sort().join()) {
        this.userRoles = roles;
      }
    });
    this.getLogout();
  }



  /**
   * Calculate the number of positive, negative and neutral evaluations for each candidate
   * @param candidates 
   */
  calculateEvaluationsStats(candidates: any) {
    console.log('CANDIDATESERVICE calculateEvaluationsStats() start');
    if (this.userRoles.includes('ROLE_RECRUITER')) {
      if (candidates[0]) {
        candidates.forEach((candidate: any) => {
          if (candidate.evaluations?.[0]) {
            let nbPositive = 0;
            let nbNegative = 0;
            let nbNetral = 0;
            candidate.evaluations.forEach((evaluation: any) => {
              if (evaluation?.appreciation === 1) {
                nbPositive++;
              } else if (evaluation?.appreciation === -1) {
                nbNegative++;
              } else if (evaluation?.appreciation === 0) {
                nbNetral++;
              }
            });
            if (nbPositive || nbNegative || nbNetral) {
              candidate.evaluationsStats = { nbPositive: nbPositive, nbNegative: nbNegative, nbNetral: nbNetral };
            }
          }
        });
      }
    }
  }

  /**
   * Retrieve the recruiter collection of favorites
   */
  getFavorites(page: any = 1) {
    console.log('CANDIDATESERVICE getFavorites()');
    if (page) {
      page = '&page=' + page;
    }
    return this.backApiService.getData(`${this.environment.favorite}s?owner.uid=${this.userUid}${page}&itemsPerPage=40`, true).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE getFavorites() res retourned =');
        console.log(res);
        let candidates: Array<any> = [];
        if (res?.['member'] && res?.['member']?.[0]) {
          res['member'].forEach((element: any) => {
            candidates.push(element.candidate);
          });
          res['member'] = candidates;
        }
        this.calculateEvaluationsStats(candidates);
        if (candidates?.[0]) {
          this.orderCandidatesMobileAddresses(candidates);
          this.removePassedUnavailabilities(candidates);
        }
        console.log('CANDIDATESERVICE getFavorites() res maped retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE getFavorites() res returned error");
        throw e;
      }));
  }

  /**
   * Add a favorite to favorite collection
   * @param candidateUid
   * @returns 
   */
  postFavorite(candidateUid: string | null = null) {
    let body = {
      "candidate": {
        "uuid": candidateUid
      }
    }
    console.log('CANDIDATESERVICE postFavorite()');
    console.log(body);
    return this.backApiService.postData(`${this.environment.favorite}`, body, true, false).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE postFavorite() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postFavorite() res returned error");
        this.showAlert("Impossible de sauvegarder aux favoris. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
   * Delete a favorite from favorite collection
   * @param favoriteUid 
   * @returns 
   */
  deleteFavorite(favoriteUid: string | null = null) {
    console.log('CANDIDATESERVICE deleteFavorite()');
    console.log(favoriteUid);
    return this.backApiService.deleteData(`${this.environment.favorite}`, '/' + favoriteUid).pipe(map((res: any) => {
      console.log('CANDIDATESERVICE deleteFavorite() res retourned =');
      console.log(res);
      return res;
    }),
      catchError(e => {
        console.log("CANDIDATESERVICE deleteFavorite() res returned error");
        throw e;
      }));
  }


  /**
   * Retrieves the candidate observable.
   * @returns {Observable<any>} The candidate observable.
   */
  getCandidateObs() {
    return this.candidateObs;
  }


  /**
   * Sets the candidate for the service.
   * 
   * @param candidate - The candidate object to be set.
   */
  setCandidate(candidate: any) {
    const candidateCopy = JSON.parse(JSON.stringify(candidate));
    this.candidate = this.formatCandidate(candidateCopy);
    this.candidateObs.next(this.candidate);
  }

  formatCandidate(candidate: any) {
    this.calculateEvaluationsStats([candidate]);
    this.removePassedUnavailabilities([candidate]);
    this.calculProfilCompletion(candidate);
    if (this.candidate?.wish?.searchAddresses?.[0]) {
      this.candidate.wish.searchAddresses = this.orderCandidateMobileAddresses(this.candidate?.wish?.searchAddresses);
    }
    return candidate;
  }

  /**
   * Retrieves the logout status and performs necessary actions.
   */
  getLogout() {
    this.profilService.getLogOutObs().subscribe(logOut => {
      if (logOut === true) {
        this.setCandidate(null);
      }
    });
  }

  /**
  * Get detail of a candidate
  * @param {string} uid the candidate uid to request
  * @return {BehaviorSubject} searchObs candidate
  */
  getCandidate(uid: string | null = this.userUid) {
    console.log('CANDIDATESERVICE getCandidate()');
    console.log(uid);
    console.log('CANDIDATESERVICE getCandidate() requette =');
    console.log(`${this.environment.candidate}/${uid}`);
    return this.backApiService.getData(`${this.environment.candidate}/${uid}`, true).pipe(
      map((res: any) => {
        if (res && res.isPhoneValidated && this.ssoApple) {
          this.settingsService.setSsoApple(false);
          console.log("CANDIDATESERVICE getCandidate() true if ssoApple");
        }
        console.log('CANDIDATESERVICE getCandidate() res retourned =');
        console.log(res);
        //  this.calculateEvaluationsStats([res]);
        //  this.removePassedUnavailabilities([res]);
        this.setCandidate(res);
        // this.candidate = res;
        //  this.calculProfilCompletion();
        // if (this.candidate?.wish?.searchAddresses?.[0]) {
        //   this.candidate.wish.searchAddresses = this.orderCandidateMobileAddresses(this.candidate?.wish?.searchAddresses);
        // }
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE getCandidate() res returned error");
        console.log(e);
        throw e;
      }));
  }



  /**
  * Get list of candidates
  * @param {Object} filters the filters to apply
  * @param {string} page the page to request
  * @return {BehaviorSubject} searchObs candidates
  */
  getCandidates(filters: any = {}, page: string = '') {
    //     filters: any = {job:"", zones:[], intermediates:"", contracts:[], type:[], structures:[], workingTime:"", housing:false};
    console.log('CANDIDATESERVICE getCandidates() start');
    console.log(JSON.parse(JSON.stringify(filters)));
    let formatedFilters = '';
    if (filters) {
      console.log('CANDIDATESERVICE getCandidates() filter = true');
      if (filters.job && filters.job[0]) {
        filters.job.forEach((res: any, index: number) => {
          formatedFilters = formatedFilters + '&qualification.uid[]=' + filters.job[index];
        });
      }
      if (filters.intermediates && filters.intermediates != "") {
        formatedFilters = formatedFilters + '&category=' + filters.intermediates;
      }
      if (filters.housing && filters.housing == true) {
        formatedFilters = formatedFilters + '&wish.mobileIfHoused=true';
      } else {
        formatedFilters = formatedFilters + '&wish.mobileIfHoused=false';
      }
      if (filters.structures && filters.structures[0]) {
        filters.structures.forEach((res: any, index: number) => {
          formatedFilters = formatedFilters + '&wish.companyCategories.uid[]=' + filters.structures[index];
        });
      }
      if (filters.contracts && filters.contracts[0]) {
        filters.contracts.forEach((res: any, index: number) => {
          formatedFilters = formatedFilters + '&wish.contractType[]=' + filters.contracts[index];
        });

      }
      if (filters.zones && filters.zones[0]) {
        formatedFilters = formatedFilters + '&address.latitude=' + filters.zones[0].latitude;
        formatedFilters = formatedFilters + '&address.longitude=' + filters.zones[0].longitude;
      }
      if (filters.workingTime && filters.workingTime[0]) {
        if (filters.workingTime && filters.workingTime[0] && !filters.workingTime[1]) {
          formatedFilters = formatedFilters + '&time=' + filters.workingTime[0];
        }
      }
      if (filters?.candidatesIdsList?.[0]) {
        formatedFilters = '&wish.mobileIfHoused=false';
        filters.candidatesIdsList.forEach((element: string) => {
          formatedFilters = formatedFilters + '&ids[]=' + element;
        });
      }
    }
    console.log("CANDIDATESERVICE getCandidates() filters");
    console.log(formatedFilters);
    console.log(filters);
    if (page != '') {
      page = '&page=' + page;
      console.log(`${this.environment.candidates}?{page}`);
    }
    console.log('CANDIDATESERVICE getCandidates() requette =');
    console.log(`${this.environment.candidates}?${formatedFilters}${page}`);
    let addHeaders = true;
    if (!this.userUid) {
      addHeaders = false;
    }
    console.log("CANDIDATESERVICE getOffer() addHeaders = ");
    console.log(this.userUid);
    console.log(addHeaders);
    return this.backApiService.getData(`${this.environment.candidates}?${formatedFilters}${page}&itemsPerPage=20`, addHeaders).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE getCandidates() res retourned =');
        console.log(res);
        this.calculateEvaluationsStats(res['member']);
        this.removePassedUnavailabilities(res['member']);
        if (!this.candidatesReady) {
          this.candidatesReady = true;
          this.candidatesReadyObs.next(this.candidatesReady);
        }
        if (res['member']?.[0]) {
          this.updateCandidatesWithViewedList(res['member']);
        }
        if (res?.['member']?.[0]) {
          this.orderCandidatesMobileAddresses(res?.['member']);
        }
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE getCandidates() res returned error");
        console.log(e);
        this.showAlert("Impossible de récuperer les candidats. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  removePassedUnavailabilities(candidates: any) {
    if (candidates?.[0]) {
      candidates.forEach((candidate: any) => {
        if (candidate?.wish?.unavailabilities?.[0]) {
          let now = new Date();
          let unavailabilities = candidate.wish?.unavailabilities;
          unavailabilities = unavailabilities.filter((unavailability: any) => {
            let endDate = new Date(unavailability.unavailableTo);
            return endDate >= now; // Le critère est uniquement que la date de fin ne soit pas dépassée
          });
          candidate.wish.unavailabilities = unavailabilities;
        }
      });
    }
  }



  /**
   * Updates the candidates with the viewed list.
   * 
   * @param candidates - An array of candidates to be updated.
   * @returns void
   */
  updateCandidatesWithViewedList(candidates: Array<any>) {
    if (this.candidatesViewedList.length > 0) {
      candidates.forEach(candidate => {
        if (this.candidatesViewedList.includes(candidate.id)) {
          candidate.viewed = true;
        }
      });
    }
  }


  /**
   * Adds a candidate to the viewed list.
   * 
   * @param candidateId - The ID of the candidate to be added.
   */
  addCandidateToViewedList(candidateId: string) {
    if (!this.candidatesViewedList.includes(candidateId)) {
      this.candidatesViewedList.push(candidateId);
      this.storage.set('candidatesViewedList', this.candidatesViewedList);
    }
  }


  /**
   * Retrieves the view candidates based on the provided offer UID and page number.
   * 
   * @param offerUid - The UID of the offer (optional, default: null).
   * @param page - The page number (optional, default: 1).
   * @returns An Observable that emits the retrieved view candidates.
   * @throws Throws an error if the retrieval fails.
   */
  getViewCandidates(offerUid: string | null = null, page: number = 1) {
    console.log('CANDIDATESERVICE getViewCandidates()');
    console.log("CANDIDATESERVICE getViewCandidates() addHeaders = ");
    console.log(this.userUid);
    return this.backApiService.getData(`${this.environment.tracking}?offer.uid=${offerUid}&page=${page}&itemsPerPage=20`, true).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE getViewCandidates() res retourned =');
        console.log(res);
        if (res['member']?.[0]) {
          for (let i = 0; i < res['member'].length; i++) {
            res['member'][i].candidate.createdAt = res['member'][i].createdAt;
            res['member'][i] = res['member'][i].candidate;
          }
        } else {
          return [];
        }
        if (!this.candidatesReady) {
          this.candidatesReady = true;
          this.candidatesReadyObs.next(this.candidatesReady);
        }
        this.calculateEvaluationsStats(res);
        this.removePassedUnavailabilities(res['member']);
        if (res['member']?.[0]) {
          this.updateCandidatesWithViewedList(res['member']);
        }
        if (res?.['member']?.[0]) {
          this.orderCandidatesMobileAddresses(res?.['member']);
        }
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE getViewCandidates() res returned error");
        this.showAlert("Impossible de récuperer les candidats. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }



  /**
 * Helper function to separate user and candidate fields
 * @param {any} data Complete data object to separate
 * @returns {Object} Separated user and candidate data
 */
  // private separateUserCandidateAndFileData(data: any): { userUpdate: any, candidateUpdate: any } {
  //   const userUpdate: any = {};
  //   const candidateUpdate: any = {};

  //   Object.entries(data).forEach(([key, value]) => {
  //     if (this.userFields.includes(key)) {
  //       userUpdate[key] = value;
  //     } else {
  //       candidateUpdate[key] = value;
  //     }
  //   });

  //   return { userUpdate, candidateUpdate };
  // }
  private separateUserCandidateAndFileData(data: any): {
    userUpdate: any,
    candidateUpdate: any,
    filesUpdate: Array<{ field: string, value: any }>
  } {
    const userUpdate: any = {};
    const candidateUpdate: any = {};
    const filesUpdate: any[] = [];

    Object.entries(data).forEach(([key, value]) => {
      console.log('CANDIDATESERVICE separateUserCandidateAndFileData()');
      console.log(key);
      console.log(value);
      console.log(this.filesValidationFields);
      console.log(this.filesValidationFields.includes(key));
      if (this.filesValidationFields.includes(key)) {
        console.log('CANDIDATESERVICE separateUserCandidateAndFileData() is file');
        filesUpdate.push(value);
      } else if (this.userFields.includes(key)) {
        userUpdate[key] = value;
      } else {
        candidateUpdate[key] = value;
      }
    });

    return { userUpdate, candidateUpdate, filesUpdate };
  }


  private handleFileUpdates(userUid: string, filesUpdate: Array<any>): Observable<any[]> {
    console.log('CANDIDATESERVICE handleFileUpdates()');
    console.log(filesUpdate);
    console.log(userUid);
    if (filesUpdate.length === 0) {
      return of([]);
    }

    const fileUploadObservables = filesUpdate.map(file => {
      return this.profilService.postFile(userUid, file?.uid, file?.validationName, file?.analyseDocument).pipe(
        catchError(error => {
          console.error(`Error uploading ${file.field}:`, error);
          this.showAlert(`Impossible de mettre à jour le fichier ${file.field}. Vérifiez votre connexion ou réessayez plus tard.`);
          throw error;
        })
      );
    });

    return forkJoin(fileUploadObservables);
  }

  /**
   * PATCH candidate data to Candidate API
   * @param {string} uid User ID
   * @param {any} candidateData Candidate data to update
   * @returns {Observable<any>} Candidate API response
   */
  private patchCandidateData(uid: string, candidateData: any): Observable<any> {
    console.log('CANDIDATESERVICE patchCandidateData() start');
    console.log(uid);
    console.log(candidateData);

    if (Object.keys(candidateData).length === 0) {
      return of(null);
    }
    if (candidateData?.experiences?.length > 1) {
      candidateData.experiences = this.removeDuplicateExperiences(candidateData.experiences);
    }

    return this.backApiService.patchData(
      `${this.environment.candidate}/${uid}`,
      candidateData,
      true,
      false
    ).pipe(tap((res: any) => {
      console.log('CANDIDATESERVICE patchCandidateData() res');
      console.log(JSON.parse(JSON.stringify(res)));
      console.log(res);
      this.setCandidate(res);
    }),
      catchError(error => {
        console.error('Error updating candidate data:', error);
        this.showAlert("Impossible de mettre à jour les informations du profil candidat. Vérifiez votre connexion ou réessayez plus tard.");
        throw error;
      })
    );
  }


  /**
   * Updates the candidate data for a given user.
   *
   * @param {string | null} [uid=this.userUid] - The unique identifier of the user. Defaults to the current user's UID.
   * @param {any} candidate - The candidate data to be updated.
   * @returns {Observable<any>} An observable that emits the combined result of the user and candidate updates.
   *
   * @throws {Error} If no UID is provided.
   *
   * The function performs the following steps:
   * 1. Separates the user and candidate data from the provided candidate object.
   * 2. Creates an array of API calls to update the user and candidate data if there are changes.
   * 3. If there are no changes, returns an observable that emits null.
   * 4. If there are changes, performs the API calls in parallel using `forkJoin`.
   * 5. Combines the results of the API calls:
   *    - Takes all fields from the candidate data except user fields.
   *    - Adds only the user fields from the user data.
   *    - Adds fields from the user data that are not present in the candidate data.
   * 6. Updates the candidate observable and candidate object with the combined result.
   * 7. Calculates the profile completion.
   * 8. Returns the combined result.
   * 9. Catches and logs any errors, shows an alert, and rethrows the error.
   */
  patchCandidateUserAndFiles(uid: string | null = this.userUid, candidate: any): Observable<any> {
    console.log('CANDIDATESERVICE patchCandidateUserAndFiles()');
    console.log(uid);
    console.log(JSON.parse(JSON.stringify(candidate)));

    if (!uid) {
      return throwError(() => new Error('No UID provided'));
    }

    const { userUpdate, candidateUpdate, filesUpdate } = this.separateUserCandidateAndFileData(candidate);

    // Initialiser l'objet résultat avec des valeurs nulles
    const resultObj = {
      user: null,
      filesUpdate: null,
      candidate: null
    };

    // Commencer avec un observable qui émet l'objet résultat initial
    let updateChain: Observable<any> = of(resultObj);

    // Traiter les mises à jour de fichiers en premier (si elles existent)
    if (filesUpdate.length > 0) {
      updateChain = updateChain.pipe(
        switchMap(currentResult => {
          return this.handleFileUpdates(uid, filesUpdate).pipe(
            map(filesResult => {
              currentResult.filesUpdate = filesResult;
              return currentResult;
            }),
            catchError(error => {
              console.error('Error updating files:', error);
              // On capture l'erreur mais on continue le flux
              return of(currentResult);
            })
          );
        })
      );
    }

    // Ensuite traiter les mises à jour utilisateur (si elles existent)
    if (Object.keys(userUpdate).length > 0) {
      updateChain = updateChain.pipe(
        switchMap(currentResult => {
          return this.profilService.patchUser(uid, userUpdate).pipe(
            map(userResult => {
              currentResult.user = userResult;
              return currentResult;
            }),
            catchError(error => {
              console.error('Error updating user:', error);
              // On capture l'erreur mais on continue le flux
              return of(currentResult);
            })
          );
        })
      );
    }

    // Enfin traiter les mises à jour du candidat (si elles existent)
    if (Object.keys(candidateUpdate).length > 0) {
      updateChain = updateChain.pipe(
        switchMap(currentResult => {
          return this.patchCandidateData(uid, candidateUpdate).pipe(
            map(candidateResult => {
              currentResult.candidate = candidateResult;
              return currentResult;
            }),
            catchError(error => {
              console.error('Error updating candidate:', error);
              // On capture l'erreur mais on continue le flux
              return of(currentResult);
            })
          );
        })
      );
    }

    // Finaliser le traitement
    return updateChain.pipe(
      tap(updateResults => {
        console.log('CANDIDATESERVICE patchCandidateUserAndFiles() updateChain completed');
        console.log(updateResults);

        // if candidate has not been updated but user or file has been updated, then make a get request to update the candidate
        if (!updateResults.candidate && (updateResults.user || updateResults.filesUpdate)) {
          this.getCandidate(uid).pipe(take(1)).subscribe();
        }
      }),
      catchError(error => {
        console.error('CANDIDATESERVICE patchCandidateUserAndFiles() error:', error);
        this.showAlert("Une erreur est survenue lors de la mise à jour du profil. Veuillez réessayer plus tard.");
        throw error;
      })
    );
  }




  /**
  * POST detail of a candidate throw the API
  * @return {BehaviorSubject} searchObs candidate
  */
  postCandidate() {
    console.log('CANDIDATESERVICE postCandidate()');
    let candidate: any = {};
    if (this.settingsService.getFromCampaign()) {
      candidate = { fromCampaign: this.settingsService.getFromCampaign() };
    }
    console.log('CANDIDATESERVICE postCandidate() requette =');
    console.log(`${this.environment.candidate}`);
    console.log(candidate);
    return this.backApiService.postData(`${this.environment.candidate}`, candidate, true, false).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE postCandidate() res retourned =');
        console.log(res);
        this.setCandidate(res);
        //  this.calculProfilCompletion();
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postCandidate() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur le profil. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }


  /**
   * Calculates the profile completion percentage based on the candidate's information.
   * The profile completion percentage is calculated by assigning weights to different sections of the candidate's information.
   * 
   * @returns void
   */
  calculProfilCompletion(candidate: any = this.candidate) {
    console.log("CANDIDATESERVICE calculProfilCompletion() start");
    this.profilCompletion = 0;
    if (candidate?.phone?.number) {  // Coordonnées
      this.profilCompletion += 25;
    }
    if (candidate?.wish && candidate?.wish.companyCategories && candidate?.wish.searchAddresses && candidate?.wish.contractType && candidate?.wish.weeklyWorkingTime && candidate?.wish.weeklyWorkingTime.mornings) {
      this.profilCompletion += 5;  // Critères de recherche
    }
    if (candidate?.description && candidate?.description != '' && candidate?.description?.length > 10) {  // Résumé
      this.profilCompletion += 5;
    }
    if (candidate?.graduation && !candidate?.qualificationRefusedAt) {
      this.profilCompletion += 10;  //Diplome
    }
    if (candidate?.profilePicture && candidate?.profilePicture.uid && !candidate?.profilePicture?.isRefused) {
      this.profilCompletion += 10;  //Photo de profil
    }
    if (candidate?.identityCard && candidate?.identityCard?.status !== 'Rejected') {
      this.profilCompletion += 10;  // Piece idetité
    }
    if (candidate?.experiences && candidate?.experiences[0]) {
      this.profilCompletion += 10;  // Experience 1
    }
    if (candidate?.experiences && candidate?.experiences[2]) {
      this.profilCompletion += 5;  // Experience 3
    }
    if (candidate?.formations && candidate?.formations[0]) {  // Formations complémentaires
      this.profilCompletion += 5;
    }
    if (candidate?.skills && candidate?.skills[0]) {  // Compétences
      this.profilCompletion += 5;
    }
    if (candidate?.softwares && candidate?.softwares[0]) {  // Logiciels
      this.profilCompletion += 5;
    }
    if (candidate?.languages && candidate?.languages[0]) {  // langues
      this.profilCompletion += 5;
    }
    this.profilCompletionObs.next(this.profilCompletion);
  }


  /**
   * Retrieves the profile completion observable.
   *
   * @returns The profile completion observable.
   */
  getProfilCompletion() {
    return this.profilCompletionObs;
  }





  /**
   * Create a new candidate evaluation
   * @param evaluation
   * @returns response from api
   */
  postEvaluation(evaluation: any) {
    let body = evaluation;
    if (body.comment === null) {
      body.comment = '';
    }
    console.log('CANDIDATESERVICE postEvaluation()');
    console.log(body);
    return this.backApiService.postData(`${this.environment.evaluation}`, body, true, false).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE postEvaluation() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postEvaluation() res returned error");
        this.showAlert("Impossible de sauvegarder l'évaluation. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
   * update a new candidate evaluation
   * @param evaluation
   * @returns response from api
   */
  patchEvaluation(evaluation: any) {
    let body = evaluation;
    if (body.comment === null) {
      body.comment = '';
    }
    console.log('CANDIDATESERVICE postEvaluation()');
    console.log(body);
    return this.backApiService.patchData(`${this.environment.evaluation}/${evaluation.uuid}`, body, true, false).pipe(
      map((res: any) => {
        console.log('CANDIDATESERVICE postEvaluation() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postEvaluation() res returned error");
        this.showAlert("Impossible de sauvegarder l'évaluation. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
  * Envoie une invitation de recommandation à une adresse e-mail spécifique.
  * @param emailTo Adresse e-mail à laquelle envoyer l'invitation de recommandation.
  * @return {Observable<any>} Observable contenant la réponse du service back-end.
  **/
  postRecommandationInvitation(emailTo: string) {
    return this.backApiService.postData(this.environment.recommendation + '/invitation', { email: emailTo }).pipe(
      tap((res: any) => {
        console.log("CANDIDATESERVICE cretateRecommandationInvitation()");
        console.log(res);
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postRecommandationInvitation() res returned error");
        if (e?.error?.message.includes('already invited')) {
          this.showAlert("Une invitation de recommandation a déjà été envoyée à cette adresse e-mail");
        } else {
          this.showAlert("Impossible d'envoyer l'invitation de recommandation. Une erreur est survenue");
        }
        throw e;
      }));
  }

  /**
  * Récupère une invitation de recommandation en utilisant un token d'invitation.
  * @param recoTokenInvitation Token d'invitation utilisé pour récupérer l'invitation de recommandation.
  * @return {Observable<any>} Observable contenant la réponse du service back-end.
  **/
  getRecommandationInvitation(recoTokenInvitation: string) {
    return this.backApiService.getData(this.environment.recommendation + '/invitation/' + recoTokenInvitation).pipe(
      tap((res: any) => {
        console.log("CANDIDATESERVICE viewRecommandationInvitation()");
        console.log(res);
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE getRecommandationInvitation() res returned error");
        this.showAlert("Impossible de reccupérer la recommandation. Une erreur est survenue");
        throw e;
      }));
  }

  /**
  * Crée une recommandation en utilisant un token d'invitation, un identifiant de candidat et les données de la recommandation.
  * @param recoTokenInvitation Token d'invitation de recommandation.
  * @param recoUidCandidate Identifiant unique du candidat pour la recommandation.
  * @param recommandationData Les données associées à la recommandation.
  * @return {Observable<any>} Observable contenant la réponse du service back-end.
  **/
  postRecommandation(recoTokenInvitation: string, recoUidCandidate: string, recommandationData: Object) {
    return this.backApiService.postData(this.environment.recommendation + '/' + recoTokenInvitation + '/' + recoUidCandidate, recommandationData).pipe(
      tap((res: any) => {
        console.log("CANDIDATESERVICE postRecommandation()");
        console.log(res);
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE postRecommandation() res returned error");
        this.showAlert("Impossible de créer la recommandation. Une erreur est survenue");
        throw e;
      }));
  }

  /**
  * Supprime une recommandation en utilisant un identifiant de recommandation unique.
  * @param recoUid Identifiant unique de la recommandation à supprimer.
  * @return {Observable<any>} Observable contenant la réponse du service back-end.
  **/
  deleteRecommandation(recoUid: string) {
    return this.backApiService.deleteData(this.environment.recommendation + '/' + recoUid).pipe(
      tap((res: any) => {
        console.log("CANDIDATESERVICE deleteRecommandation()");
        console.log(res);
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE deleteRecommandation() res returned error");
        this.showAlert("Impossible de supprimer la recommandation. Une erreur est survenue");
        throw e;
      }));
  }

  /**
* Init company calendaraccording to avalaibilities
**/
  setWeeklyWorkingTime(candidate: any) {
    let dispos = [[false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false]];
    if (candidate?.wish?.weeklyWorkingTime) {
      let week = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      week.forEach((day, index) => {
        if (candidate.wish.weeklyWorkingTime.mornings?.[0] && candidate.wish.weeklyWorkingTime.mornings.indexOf(day) != -1) {
          dispos[index][0] = true;
        }
        if (candidate.wish.weeklyWorkingTime.afternoons?.[0] && candidate.wish.weeklyWorkingTime.afternoons.indexOf(day) != -1) {
          dispos[index][1] = true;
        }
        if (candidate.wish.weeklyWorkingTime.nights?.[0] && candidate.wish.weeklyWorkingTime.nights.indexOf(day) != -1) {
          dispos[index][2] = true;
        }
      });
    }
    return dispos;
  }


  /**
   * Parses the resume with the given resume UID.
   * 
   * @param resumeUid - The UID of the resume to parse.
   * @returns A promise that resolves with the parsed result.
   * @throws If an error occurs during parsing.
   */
  parseResume(resumeUid: any) {
    return this.backApiService.postData(this.environment.resume, {}).pipe(  // { resumeUid: resumeUid }
      tap((res: any) => {
        console.log("CANDIDATESERVICE parseResume()");
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE parseResume() res returned error");
        //  this.showAlert("Impossible d'analyser le CV. Erreur : " + e?.error?.message);
        throw e;
      }));
  }

  /**
   * Extracts the photo from a resume.
   * 
   * @param resumeUid The unique identifier of the resume.
   * @returns An Observable that emits the response containing the extracted photo.
   * @throws If an error occurs during the extraction process.
   */
  extractPhotoFromResume(resumeUid: string) {
    return this.backApiService.postData(this.environment.resumePhoto, { resumeUid: resumeUid }).pipe(
      tap((res: any) => {
        console.log("CANDIDATESERVICE extractPhotoFromResume()");
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("CANDIDATESERVICE extractPhotoFromResume() res returned error");
        //  this.showAlert("Impossible d'extraire la photo du CV. Erreur : " + e?.error?.message);
        throw e;
      }));
  }


  /**
   * Checks for duplicates in the candidate data.
   * 
   * @returns An Observable that emits the response from the server.
   * @throws Throws an error if there is an error while checking for duplicates.
   */
  checkDuplicates() {
    console.log('CANDIDATESERVICE checkDuplicates()');
    return this.backApiService.getData(`${this.environment.candidateDuplicatesCheck}`, true).pipe(
      tap((res: any) => {
        console.log('CANDIDATESERVICE checkDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('CANDIDATESERVICE checkDuplicates() error=');
        console.log(e);
        throw e;
      }));
  }


  /**
   * Merges duplicates of a candidate.
   * 
   * @param candidateToMergeUid - The UID of the candidate to merge.
   * @param updateEmail - A boolean indicating whether to update the email during the merge.
   * @returns An Observable that emits the response from the merge operation.
   * @throws Throws an error if the merge operation fails.
   */
  mergeDuplicates(candidateToMergeUid: string, updateEmail: boolean) {
    console.log('CANDIDATESERVICE mergeDuplicates()');
    return this.backApiService.postData(`${this.environment.mergeCandidatesDuplicates}`, { 'targetUuid': candidateToMergeUid, 'updateEmail': updateEmail }).pipe(
      tap((res: any) => {
        console.log('CANDIDATESERVICE mergeDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('CANDIDATESERVICE mergeDuplicates() error=');
        console.log(e);
        throw e;
      }));
  }

  /**
   * Orders the candidate mobile addresses.
   * 
   * @param addresses - The array of addresses to be ordered.
   * @returns The ordered array of addresses with mobile addresses first, followed by other addresses.
   */
  orderCandidateMobileAddresses(addresses: any) {
    console.log('CANDIDATESERVICE orderCandidateMobileAddresses()');
    let mobileAddresses: Array<any> = [];
    let otherAddresses: Array<any> = [];
    let worldwideAddressFound = false;
    if (!addresses) {
      return;
    }
    addresses.forEach((address: any) => {
      if (address?.address?.latitude == 43.2543 && address?.address?.longitude == 5.4057) {
        if (!worldwideAddressFound) {
          mobileAddresses.push(address);
          worldwideAddressFound = true;
        }
      } else {
        otherAddresses.push(address);
      }
    });
    return mobileAddresses.concat(otherAddresses);
  }

  /**
   * Orders the mobile addresses of candidates.
   * 
   * @param candidates - The array of candidates.
   */
  orderCandidatesMobileAddresses(candidates: any) {
    console.log('CANDIDATESERVICE orderCandidatesMobileAddresses()');
    candidates.forEach((candidate: any) => {
      if (candidate.wish?.searchAddresses?.[0]) {
        candidate.wish.searchAddresses = this.orderCandidateMobileAddresses(candidate.wish.searchAddresses);
      }
    });
  }

  /**
   * Supprime les doublons d'expériences
   * Un doublon est défini comme ayant le même companyName, startAt et endAt
   * 
   * @param experiences Liste d'expériences à nettoyer
   * @returns Liste d'expériences sans doublons
   */
  removeDuplicateExperiences(experiences: any[]): any[] {
    console.log('CANDIDATESERVICE removeDuplicateExperiences()');
    console.log(experiences);
    if (!experiences || !Array.isArray(experiences)) {
      return [];
    }

    const uniqueMap = new Map<string, any>();

    experiences.forEach(exp => {
      const companyName = exp.companyName || '';
      const startAt = exp.startAt || '';
      const endAt = exp.endAt || '';
      const key = `${companyName}_${startAt}_${endAt}`;

      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, exp);
      }
    });

    return Array.from(uniqueMap.values());
  }

  deleteCandidate(candidateUid: string | null = this.userUid) {
    console.log('CANDIDATESERVICE deleteCandidate()');
    return this.backApiService.deleteData(`${this.environment.candidate}/${candidateUid}`).pipe(
      tap((res: any) => {
        console.log('CANDIDATESERVICE deleteCandidate() res=');
        console.log(res);
        this.setCandidate(null);
        return res;
      }),
      catchError(e => {
        console.log('CANDIDATESERVICE deleteCandidate() error=');
        console.log(e);
        throw e;
      }));
  }


}
