import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SettingsService } from '../../services/settings/settings.service';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { ProfilService } from '../../services/profil/profil.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import { RecruiterService } from '../../services/recruiter/recruiter.service';
import { Router } from '@angular/router';
import { SupportService } from 'src/app/services/support/support.service';

@Component({
  selector: 'app-maintenance-modal',
  templateUrl: './maintenance-modal.page.html',
  styleUrls: ['./maintenance-modal.page.scss'],
})
export class MaintenanceModalPage implements OnInit {
  mobileUrlPrefix: string = 'tabs/';
  pageDetail: string = "";
  outdatedVersion: boolean = false;
  outdatedVersionForce: boolean = false;
  rateApp: boolean = false;
  userType: string | null = null;
  deleteOffer: boolean = false;
  deletedReason: any = null;
  user: any = null;
  nbByPass: number = 0;
  pageFunction: string | null = null;
  pageTitle: string | null = null;
  pageSubtitle: string | null = null;
  pageImage: string | null = null;
  pageLink: string | null = null;
  pageImgHeight: number = 200;
  initialBreakpoint: number = 1;
  openPageUrl: string | null = null;
  skipButtonName: string | null = null;
  openPageUrlButtonName: string | null = null;
  infoId: number | null = null;
  subscriptionList: any = [];

  constructor(
    private platform: Platform,
    private settingsService: SettingsService,
    private modalController: ModalController,
    public alertController: AlertController,
    private profilService: ProfilService,
    private candidateService: CandidateService,
    private recruiterService: RecruiterService,
    private router: Router,
    private supportService: SupportService,
    private navParams: NavParams) { }

  ngOnDestroy() {
    this.subscriptionList.forEach((subs: any) => {
      subs.unsubscribe();
    });
  }

  ngOnInit() {
    this.settingsService.trackModalPage('Maintenance - modale');

    let displayTabsSubs = this.settingsService.getDisplayTabs().subscribe((res: any) => {
      if (res) {
        this.mobileUrlPrefix = 'tabs/';
      } else {
        this.mobileUrlPrefix = '';
      }
    });
    this.subscriptionList.push(displayTabsSubs);

    this.pageFunction = this.navParams.data['type'];
    this.initialBreakpoint = this.navParams.data['initialBreakpoint'];
    if (this.pageFunction == 'outdatedVersionForce') {
      this.outdatedVersion = true;
      this.outdatedVersionForce = true;
    }
    else if (this.pageFunction == 'outdatedVersion') {
      this.outdatedVersion = true;
    } else if (this.pageFunction == 'rateApp') {
      this.rateApp = true;
      this.userType = 'candidate';
    } else if (this.pageFunction == 'deleteOffer') {
      this.deleteOffer = true;
      this.userType = 'recruiter';
    } else if (this.pageFunction == 'maintenance') {
      this.pageDetail = this.navParams.data['detail'];
      this.pageTitle = "ClubOfficine est en cours de maintenance."
    } else if (this.pageFunction == 'welcomeMessage') {
      this.pageDetail = this.navParams.data['detail']['welcomeMessageDetail'];
      this.pageTitle = this.navParams.data['detail']['welcomeMessageTitle'];
    } else if (this.pageFunction == 'info') {
      this.pageDetail = this.navParams.data['info']?.detail;
      this.pageSubtitle = this.navParams.data['info']?.subtitle;
      this.pageTitle = this.navParams.data['info']?.title;
      this.pageImage = this.navParams.data['info']?.image;
      this.pageLink = this.navParams.data['info']?.link;
      this.openPageUrl = this.navParams.data['info']?.openPageUrl;
      this.skipButtonName = this.navParams.data['info']?.skipButtonName;
      this.openPageUrlButtonName = this.navParams.data['info']?.openPageUrlButtonName;
      this.infoId = this.navParams.data['info']?.id;
      if (this.navParams.data['info']?.pageImgHeight) {
        this.pageImgHeight = this.navParams.data['info']?.pageImgHeight;
      }

    }
    console.log("MAINTENANCEMODAL ngOnInit()");

    if (this.profilService.getuserRole() == 'ROLE_CANDIDATE') {
      this.candidateService.getCandidateObs().subscribe(user => {
        this.user = user;
      });
    } else {
      this.recruiterService.getMyRecruiterProfilObs().subscribe(user => {
        this.user = user;
      });
    }
  }

  validDeletedReason() {
    console.log("MAINTENANCEMODAL validDeletedReason()");
    console.log(this.deletedReason);
    if (this.deletedReason == 'provided-cbo') {
      this.settingsService.setRateEvent('deleteOffer-provided-cbo');
    }
    this.closeModal(this.deletedReason);
  }

  refresh() {
    this.settingsService.checkMaintenance().subscribe((res: any) => {
      if (res.maintenance === false) {
        this.closeModal();
      }
    });
  }

  update() {
    console.log('MAINTENANCEMODAL update()');
    if (this.platform.is('ios')) {
      Browser.open({ url: "https://itunes.apple.com/app/id1492444372", windowName: "_system" });
    } else {
      Browser.open({ url: "https://play.google.com/store/apps/details?id=fr.clubofficine.clubofficine&hl=fr", windowName: "_system" });
    }
  }

  async rate() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Votre avis compte ?',
      //   subHeader: 'Subtitle',
      message: 'Laissez un avis en quelques secondes seulement. Votre contribution est précieuse pour nous aider à améliorer et faire connaître notre application !',
      buttons: [
        {
          text: 'Plus tard',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.closeModal();
          },
        },
        {
          text: 'Oui',
          handler: () => {
            console.log('Confirm Okay');
            this.settingsService.setRateApp(true);
            if (this.platform.is('ios')) {
              Browser.open({ url: "https://itunes.apple.com/app/id1492444372?action=write-review", windowName: "_system" });
            } else {
              Browser.open({ url: "https://play.google.com/store/apps/details?id=fr.clubofficine.clubofficine&hl=fr", windowName: "_system" });
            }
            this.closeModal();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async complain() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Dites-nous pourquoi',
      //   subHeader: 'Subtitle',
      message: 'Afin d\'améliorer nos services, auriez-vous une minute pour nous expliquer pourquoi ?',
      buttons: [
        {
          text: 'Plus tard',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.settingsService.setRateApp(true);
            this.closeModal();
          },
        },
        {
          text: 'Oui',
          handler: () => {
            console.log('Confirm Okay');
            this.settingsService.setRateApp(true);
            this.openSupport(undefined, undefined, 'Insatisfaction', 'autre', '');
            this.closeModal();
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  openSupport(supportUrl = undefined, option = undefined, subject: string | undefined = undefined, nature: string | undefined = undefined, description: string | undefined = undefined) {
    this.supportService.openSupport(supportUrl, option, subject, nature, description);
  }

  byPassMaintenance() {
    if (this.nbByPass > 11) {
      this.closeModal();
    }
    this.nbByPass++;
  }

  async closeModal(deletedReason: string | null = null, infosViewedId: number | null = null) {
    if (this.infoId) {
      infosViewedId = this.infoId;
    }
    console.log('MAINTENANCEMODAL closeModal()');
    await this.modalController.dismiss({ deletedReason: deletedReason, infosViewedId: infosViewedId }, 'close');
  }


  openUrl(url: string | null, option: string = '_blank') {
    if (url == null) {
      return;
    }
    Browser.open({ url: url, windowName: option });
  }

  navigateTo(page: string | null = this.openPageUrl) {
    if (page == null) {
      return;
    }
    this.closeModal();
    this.router.navigateByUrl(this.mobileUrlPrefix + page);
  }

}
