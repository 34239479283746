import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../../services/profil/profil.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { PlatformService } from '../../services/platform/platform.service';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  userUid: string | null = null;
  registrationStatus: any = null;
  environment: any = environment;

  constructor(
    private storage: LocalStorageService,
    private profilService: ProfilService,
    private alertController: AlertController,
    private backApiService: BackApiService,
    private platformService: PlatformService,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
    console.log('NOTIFICATIONSERVICE constructor()');
  }


  /**
  * Register a device
  * @param {string} token the notification token
  * @return {BehaviorSubject} api response
  */

  registerDevice(token: string | null = null) {
    console.log('NOTIFICATIONSERVICE RegisterDevice()');
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
      let type = 'ios';
      if (this.platformService.is('android')) {
        type = 'android';
      } else {
        type = 'web';
      }
      let body = { 'ownerUid': this.userUid, 'type': type, 'version': this.environment.appVersion, 'subscribed': true, 'token': token };
      if (!token || !this.userUid) {
        return;
      }
      this.backApiService.postData(`${this.environment.registerDevice}`, body, true, false).subscribe((res: any) => {
        console.log('NOTIFICATIONSERVICE RegisterDevice() res retourned =');
        console.log(res);
        this.registrationStatus = res;
        this.setRegistrationStatus(res);
        return res;
      }, error => {
        console.log("NOTIFICATIONSERVICE RegisterDevice() res returned error");
        this.showAlert("Impossible d'activer les notifications, verifiez votre connexion et reessayez plus tard.");
      });
    });
  }

  /**
  * update api registration status
  * @param {any} status 
  **/
  setRegistrationStatus(status: any) {
    console.log('NOTIFICATIONSERVICE setRegistrationStatus()');
    console.log(status);
    this.storage.set('registrationStatus', status);
  }
  /**
  * Get api registration status
  * @return {any} 	
  **/
  checkRegistrationStatusAndRegister(token: string | null = null) {
    console.log('NOTIFICATIONSERVICE checkRegistrationStatusAndRegister()');
    this.storage.get('registrationStatus').then((registrationStatus: any) => {
      console.log('NOTIFICATIONSERVICE checkRegistrationStatusAndRegister get =');
      console.log(registrationStatus);
      if (!registrationStatus) {
        this.registerDevice(token);
      }
    });
  }


  /**
   * Unregisters a device.
   *
   * @param registrationStatus - The registration status of the device.
   * @returns A promise that resolves with the response from the server.
   * @throws If an error occurs during the unregistration process.
   */
  unregisterDevice(registrationStatus: any) {
    console.log('NOTIFICATIONSERVICE unregisterDevice()');
    return this.backApiService.deleteData(`${this.environment.notificationUrl}`, `${registrationStatus['@id']}`).pipe(map((res: any) => {
      console.log('NOTIFICATIONSERVICE unregisterDevice() res retourned =');
      console.log(res);
      this.getRegistrationStatus().then((res2: any) => {
        if (res2 && res2.subscribed) {
          res2.subscribed = false;
          this.setRegistrationStatus(res2);
        }
      });
      return res;
    }),
      catchError(e => {
        throw e;
        console.log("NOTIFICATIONSERVICE unregisterDevice() res returned error");
      }));
  }

  getRegistrationStatus() {
    return this.storage.get('registrationStatus');
  }

  getStoredToken() {
    console.log('NOTIFICATIONSERVICE getStoredToken()');
    return this.storage.get('registrationStatus');
  }



  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

}
