import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { SettingsService } from '../../services/settings/settings.service';
import { AuthService } from '../auth/auth.service';
import { WindowService } from '../window/window.service';
import { ErrorsService } from '../errors/errors.service';
import { EnvService } from '../env/env.service';


@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  candidate: any = {};
  user: any = null;
  userObs: BehaviorSubject<any> = new BehaviorSubject(null);
  userUid: string | null = null;
  userRoles: Array<string> = [];
  userRolesObs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  userUidObs: BehaviorSubject<any> = new BehaviorSubject(null);
  isNew: boolean = false;
  premium: boolean = false;
  premiumObs: BehaviorSubject<any> = new BehaviorSubject(false);
  userEmail: string | null = "";
  userEmailObs: BehaviorSubject<any> = new BehaviorSubject(null);
  userSubs: Subscription | null = null;
  logOutObs: BehaviorSubject<any> = new BehaviorSubject(null);
  ssoApple: string | null = null;
  environment: any = environment;

  constructor(
    private backApiService: BackApiService,
    private storage: LocalStorageService,
    private alertController: AlertController,
    private settingsService: SettingsService,
    private windowService: WindowService,
    private errorsService: ErrorsService,
    private auth: AuthService,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
    console.log('PROFIL cst()');
    this.restoreStoredcandidate();
    this.settingsService.getSsoApple().subscribe((sso: string) => {
      this.ssoApple = sso;
    });
  }



  /**
   * Initializes the profile service.
   * 
   * This method performs the following tasks:
   * 1. Checks if the application is a PWA (Progressive Web App) and if so, calls the `checkIfLoggedFromSsoThenInitProfil()` method.
   * 2. Initializes the user UID.
   * 3. Initializes the user role.
   * 4. Initializes the user email.
   * 5. Subscribes to the `userUid` observable and performs the following actions if a `userUid` is available:
   *    - Subscribes to the `isAuthenticatedObs()` observable and performs the following actions if the `authState` is `false` and the application is running in a browser environment:
   *      - Clears the user data after logout by calling the `clearUserAfterLogout()` method.
   */
  init() {
    console.log("PROFILSERVICE init()");
    if (this.environment.isPwa) {
      this.checkIfLoggedFromSsoThenInitProfil();
    }
    this.initUserUid();
    this.initUserRole();
    this.initUserEmail();
    this.getuserUidObs().subscribe(userUid => {
      if (userUid) {
        this.auth.isAuthenticatedObs().subscribe(authState => {
          console.log("profilService authState");
          console.log(authState);
          console.log(this.userRoles);
          console.log(this.windowService.isPlatformBrowser());
          if (authState === false && this.windowService.isPlatformBrowser()) {
            console.log("profilService authState before timmeout");
            console.log("profilService authState after timmeout");
            console.log(this.auth.isAuthenticated());
            this.clearUserAfterLogout();
            console.log("profilService authState after timmeout clearUserAfterLogout() :");
          }
        });
      }
    });
  }

  /**
   * Clears the user data after logout.
   * 
   * This method clears the user data and resets the necessary properties to their default values.
   * It also notifies the logOutObs subject to indicate that the user has logged out.
   * 
   * @remarks
   * This method should be called when the user logs out of the application.
   */
  clearUserAfterLogout() {
    console.log("PROFILSERVICE clearUserAfterLogout() start");
    if (!this.user && !this.userRoles) {
      return;
    }
    this.logOutObs.next(true);
    this.candidate = {};
    this.userUid = null;
    this.isNew = false;
    this.userUidObs.next(null);
    this.userEmail = null;
    this.user = null;
    this.userObs.next(null);
    this.premiumObs.next(null);
    this.premium = false;
    this.userRoles = [];
    this.userRolesObs.next([]);
    this.userEmailObs.next(null);
    //  this.settingsService.clearSubscribtions();
    console.log("PROFILSERVICE clearUserAfterLogout() end");
  }

  getLogOutObs() {
    return this.logOutObs;
  }

  getIsPremium() {
    return this.premium;
  }

  getIsPremiumObs() {
    return this.premiumObs.asObservable();
  }

  /**
   * Checks the user's role until payment is made.
   * 
   * This method iterates 20 times with a delay of 2 seconds between each iteration.
   * If the user is not a premium user, it initializes and saves the user's profile.
   * 
   * @remarks
   * This method is typically used to continuously check the user's role until the payment is successfully made. // Should be replaced when Subscription/premium on Mercure is implemented
   * 
   * @example
   * ```typescript
   * checkRoleUntilPaiement();
   * ```
   */
  checkRoleUntilPaiement() {
    for (let i = 0; i < 20; i++) {
      setTimeout(() => {
        if (!this.getIsPremium()) {
          this.initAndSaveUserProfil().subscribe(() => {
            console.log("checkRoleUntilPaiement PAYEMENTSUCCES initAndSaveUserProfil " + i + " " + this.getIsPremium());
          });
        }
      }, 2000 * i);
    }
  }

  /**
   * Initializes and saves the user profile.
   * 
   * This method retrieves user information from the API, updates the user's profile, and saves it to storage.
   * It also updates the user's role and premium status based on the retrieved information.
   * 
   * @returns A promise that resolves to the retrieved user information.
   * @throws If an error occurs during the API call.
   */
  initAndSaveUserProfil() {
    console.log('PROFILSERVICE - initAndSaveUserProfil() start');
    return this.getUserFromApi().pipe(take(1),
      map((res: any) => {
        console.log('PROFILSERVICE - initAndSaveUserProfil() getUserInfosFromApi()');
        console.log(res);
        if (res?.uuid !== this.userUid) {
          this.userUid = res.uuid;
          this.userUidObs.next(this.userUid);
        }
        if (res?.uuid !== this.user?.uuid) {
          this.user = res;
          this.userObs.next(res);
        }
        let roles = res.roles;
        console.log('PROFILSERVICE initAndSaveUserProfil() - userUidObs.next()');
        console.log(this.user);
        this.storage.set('userUid', this.userUid);
        this.premiumObs.next(false);
        this.premium = false;
        console.log('PROFILSERVICE initAndSaveUserProfil() - user is NOT premium');
        console.log(roles);

        // this.userRoles = []; // Reset roles array
        roles = this.filterAuthorizedRoles(roles);
        if (roles?.includes('ROLE_RECRUITER_PREMIUM')) {
          this.premiumObs.next(true);
          this.premium = true;
          console.log('PROFILSERVICE initAndSaveUserProfil() - user is premium');
        }

        // Update roles in settings and storage
        if (roles.length > 0) {
          if (!this.areRolesEqual(roles, this.userRoles)) {
            this.userRoles = roles;
            this.userRolesObs.next(this.userRoles);
          }
          this.settingsService.setUserRoles(this.userRoles);
          this.storage.set('userRoles', this.userRoles);
          console.log('PROFILSERVICE initAndSaveUserProfil() - user roles set to:', this.userRoles);
        } else {
          this.settingsService.setUserRoles(null);
        }

        this.userEmail = res.email;
        this.storage.set('userEmail', this.userEmail);
        return res;
      }),
      catchError(e => {
        throw e;
      }));
  }

  areRolesEqual(roles: string[], currentRoles: string[]): boolean {
    if (roles.length !== currentRoles.length) return false;
    return roles.every(role => currentRoles.includes(role));
  }

  filterAuthorizedRoles(roles: string[]) {
    const authorizedRoles = ['ROLE_CANDIDATE', 'ROLE_RECRUITER', 'ROLE_RECRUITER_PREMIUM', 'ROLE_ASSOCIATE', 'ROLE_TRANSACTIONNAIRE'];
    return roles.filter(role => authorizedRoles.includes(role));
  }


  getIsNew() {
    return this.isNew;
  }

  setIsNew(isNew: boolean) {
    this.isNew = isNew;
  }

  /**
   * Initializes the user UID.
   * Retrieves the user UID from storage and updates the `userUid` property.
   * If the retrieved user UID is different from the current `userUid`, it updates the `userUid` property,
   * emits the updated `userUid` through the `userUidObs` observable,
   * and logs the updated `userUid` to the console.
   * If no user UID is stored in the storage, it logs a message indicating that no user UID is stored.
   */
  initUserUid() {
    console.log('PROFILSERVICE initUserUid()');
    this.storage.get('userUid').then((userUid: any) => {
      if (userUid && userUid != this.userUid) {
        this.userUid = userUid;
        this.userUidObs.next(this.userUid);
        console.log('PROFIL SERVICE restoreStoredPersonProfil() userUid = ');
        console.log(this.userUid);
      }
      else {
        console.log('PROFIL SERVICE restoreStoredPersonProfil() no userUid stored');
      }
    });
  }

  /**
   * Initializes the user role.
   * Retrieves the user role from storage and updates the current user role if it is different.
   * Notifies observers of the updated user role.
   * Sets the user role in the settings service.
   * Logs the user role to the console.
   * If no user role is stored, logs a message indicating that no user role is stored.
   */
  initUserRole() {
    this.storage.get('userRoles').then((userRoles: string[]) => {
      if (userRoles && userRoles.length > 0) {
        this.userRoles = userRoles;
        this.userRolesObs.next(this.userRoles);
        this.settingsService.setUserRoles(this.userRoles);
        console.log('PROFIL SERVICE initUserRole() userRoles = ', this.userRoles);
      } else {
        console.log('PROFIL SERVICE initUserRole() no userRoles stored');
      }
    });
  }

  /**
   * Initializes the user email.
   * Retrieves the user email from storage and updates the userEmail property.
   * If the retrieved email is different from the current userEmail, it updates the userEmailObs subject.
   * Logs the retrieved email to the console.
   * If no email is stored, it logs a message indicating that there is no stored email.
   */
  initUserEmail() {
    this.storage.get('userEmail').then((userEmail: any) => {
      if (userEmail && userEmail != this.userEmail) {
        this.userEmail = userEmail;
        this.userEmailObs.next(this.userEmail);
        console.log('PROFIL SERVICE initUserEmail() userEmail = ');
        console.log(this.userEmail);
      }
      else {
        console.log('PROFIL SERVICE initUserEmail() no initUserEmail stored');
      }
    });
  }

  /**
  * Edit users infos like email or mdp
  * @param {credentials} credentials Takes email and password and old password
  * @returns AuthServer response
  */
  editCredential(credentials: any) {
    let url = this.environment.registerPath + '/' + this.userUid;
    console.log('PROFILSERVICE editCredential :');
    console.log(credentials);
    let body = { email: credentials.login, password: credentials.password };
    return this.patchUser(this.userUid, body).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE editCredential');
        console.log(res);
      }),
      catchError(e => {
        console.log(e);
        e = this.errorsService.formatError(e);
        this.errorsService.displayError(e);
        throw e;
      }));
  }


  /**
   * Retrieves user information from the API.
   * 
   * @returns {Observable<any>} An observable that emits the user information.
   * @throws {any} Throws an error if unable to retrieve the user information.
   */
  // getUserInfosFromApi() {
  //   console.log('PROFILSERVICE getUserInfosFromApi() start');
  //   console.log(this.environment.account)
  //   let url = this.environment.account;

  //   return this.backApiService.getData(`${url}`, true).pipe(
  //     map((res: any) => {
  //       console.log('PROFILSERVICE getUserInfosFromApi() res retourned =');
  //       console.log(res);
  //       return res;
  //     }),
  //     catchError(e => {
  //       console.log("PROFILSERVICE getUserUid() res returned error");
  //       this.showAlert("Impossible de récuperer votre identifiant utilisateur. Verifiez votre connexion ou réessayez plus tard");
  //       throw e;
  //     }));
  // }


  getuserUid() {
    return this.userUid;
  }

  getuserUidObs() {
    return this.userUidObs.asObservable();
  }

  getUserRoles(): string[] {
    return this.userRoles;
  }

  getUserJobRole(userRoles: string[] = this.userRoles): string | null {
    if (!userRoles || !Array.isArray(userRoles)) {
      return null;
    }
    const priorityRoles = ['ROLE_CANDIDATE', 'ROLE_RECRUITER'];
    const foundRole = userRoles.find(role => priorityRoles.includes(role));
    return foundRole || null;
  }

  getUserTransactionRole(userRoles: string[] = this.userRoles): string | null {
    if (!userRoles || !Array.isArray(userRoles)) {
      return null;
    }
    const priorityRoles = ['ROLE_ASSOCIATE', 'ROLE_TRANSACTIONNAIRE'];
    const foundRole = userRoles.find(role => priorityRoles.includes(role));
    return foundRole || null;
  }


  getUserRolesObs() {
    console.log('PROFILSERVICE getUserRolesObs()');
    return this.userRolesObs.asObservable();
  }

  hasRole(role: string): boolean {
    return this.userRoles.includes(role);
  }


  getUser() {
    return this.user;
  }

  getUserObs() {
    return this.userObs.asObservable();
  }

  setUser(user: any) {
    this.user = user;
    this.userObs.next(this.user);
    console.log('PROFILSERVICE setUser() user = ');
    console.log(this.user);
  }

  getuserEmail() {
    return this.userEmail;
  }

  getuserEmailObs() {
    return this.userEmailObs.asObservable();
  }

  /**
   * Retrieves the job title based on the job id.
   * 
   * @returns The job title corresponding to the job number. Returns null if the job number is not found.
   */
  getJobFromNumber() {
    switch (this.candidate.job) {
      case "20":
        return "Pharmacien (H/F)";
        break;
      case "21":
        return "Préparateur en Pharmacie (H/F)";
        break;
      case "22":
        return "Etudiant en Pharmacie (H/F)";
        break;
      case "47":
        return "Etudiant en Pharmacie 6e année validée (H/F)";
        break;
      case "28":
        return "Conseiller Dermo Cosmetique - Esthéticienne (H/F)";
        break;
      case "40":
        return "Délégué pharmaceutique (H/F)";
        break;
      case "41":
        return "Interne en Pharmacie (H/F)";
        break;
      case "46":
        return "Orthopédiste-Orthésiste (H/F)";
        break;
      case "23":
        return "Apprenti Préparateur (H/F)";
        break;
      case "30":
        return "Diététicien (H/F)";
        break;
      case "29":
        return "Rayonniste - Preparateur de commande - Aide préparateur (H/F)";
        break;
      case "31":
        return "Administratif - Secrétaire (H/F)";
        break;
      case "39":
        return "Assistant Technique Respiratoire (H/F)";
        break;
      case "42":
        return "Technicien d'analyses biomédicales (H/F)";
        break;
      case "43":
        return "Opticien (H/F)";
        break;
      default:
        return null;
        break;
    }

  }

  /**
   * Return stored candidate data
   * @return {object} candidate  - full profile  
   */
  restoreStoredcandidate() {
    this.storage.get('personProfil').then((candidate: any) => {
      if (candidate) {
        this.candidate = candidate;
        console.log('PROFIL SERVICE restoreStoredPersonProfil() candidate = ');
        console.log(this.candidate);
      }
      else {
        console.log('PROFIL SERVICE restoreStoredPersonProfil() no personalProfile stored');
      }
    }, (error: any) => {
      console.log('restoreStoredcandidate() error');
      console.log(error);
    });
  }

  /**
   * Stores the candidate elements.
   * 
   * @param elements - The elements to be stored.
   */
  storecandidateElements(elements: any) {
    console.log('PROFILSERVICE storecandidatelements() element =');
    console.log(elements);
    Object.keys(elements).forEach(key => {
      console.log(key);
    });
    if (elements['job']) {
      this.candidate.job = elements['job'];
    }
    this.storage.set('personProfil', this.candidate);
  }

  getcandidate() {
    return this.candidate;
  }




  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  /* PWA */
  checkIfLoggedFromSsoThenInitProfil() {
    this.settingsService.checkForSsoLogin().subscribe(loggedFromSso => {
      if (loggedFromSso) {
        this.initAndSaveUserProfil().subscribe((res: any) => {
        },
          error => {
            console.log('PROFILSERVICE checkIfLoggedFromSsoThenInitProfil() initAndSaveUserProfil error');
            console.log(error);
          });
      }
    });
  }



  /**
   * Retrieves the user data based on the provided user ID.
   * If no user ID is provided, it defaults to the current user's ID.
   * 
   * @param {string | null} [uid=this.userUid] - The user ID to fetch data for. Defaults to the current user's ID.
   * @returns {Observable<any>} An observable that emits the user data.
   * 
   * Logs various stages of the process, including:
   * - The initiation of the getUser method.
   * - The user ID being used.
   * - The constructed URL for the API request.
   * - The response received from the API.
   * - Any errors encountered during the API request.
   * 
   * If the response contains a validated phone number and the `ssoApple` flag is true,
   * it updates the `ssoApple` flag in the settings service.
   * 
   * Updates the `userObs` observable and the `user` property with the received user data.
   * 
   * @throws Will throw an error if the API request fails.
   */
  getUserFromApi(userUid: string | null = this.userUid) {
    console.log('PROFILSERVICE getUser()');
    console.log(userUid);
    console.log(`${this.environment.user}/${userUid}`);
    if (!userUid) {
      userUid = 'me';
    }
    return this.backApiService.getData(`${this.environment.user}/${userUid}`, true).pipe(
      tap((res: any) => {
        if (res && res?.phoneValidation?.validatedAt && this.ssoApple) {
          this.settingsService.setSsoApple(false);
          console.log("PROFILSERVICE getUser() true if ssoApple");
        }
        console.log('PROFILSERVICE getUser() res retourned =');
        console.log(res);
        // this.userObs.next(res);
        // this.user = res;
        // this.userUid = res.uuid;
        // this.userUidObs.next(this.userUid);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE getUser() res returned error");
        console.log(e);
        throw e;
      }));
  }

  /**
   * Sends user data to the backend API and updates the user observable.
   * 
   * @param data - The user data to be sent to the backend.
   * @returns An observable that emits the response from the backend API.
   * 
   * Logs the process of sending data and the response received.
   * Updates the user observable and the user property with the response.
   * 
   * @throws Will throw an error if the backend API request fails.
   */
  postUser(data: any) {
    console.log('PROFILSERVICE postUser()');
    console.log(data);
    //  this.authService.createLoginHeader(this.environment.basicLoginToken);
    return this.backApiService.postData(`${this.environment.user}`, data, false).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE postUser() res retourned =');
        console.log(res);
        this.userObs.next(res);
        this.user = res;
        this.userUid = res.uuid;
        this.userUidObs.next(this.userUid);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE postUser() res returned error");
        console.log(e);
        this.errorsService.displayError(e);
        throw e;
      }));
  }


  /**
   * Updates the user data by sending a PATCH request to the backend API.
   * 
   * @param data - The data to be patched for the user.
   * @param uid - The unique identifier of the user. Defaults to the current user's UID if not provided.
   * @returns An Observable that emits the response from the backend API.
   * 
   * @example
   * ```typescript
   * const userData = { name: 'John Doe', email: 'john.doe@example.com' };
   * PROFILSERVICE.patchUser(userData).subscribe(response => {
   *   console.log('User updated:', response);
   * });
   * ```
   * 
   * @remarks
   * This method logs the input data and the response or error from the backend API.
   * It also updates the user observable and the user property with the response data.
   */
  patchUser(uid: string | null = this.userUid, data: any) {
    console.log('PROFILSERVICE patchUser()');
    console.log(data);
    if (data?.maidenName === '' || data?.maidenName === null) {
      delete data.maidenName;
    }
    return this.backApiService.patchData(`${this.environment.user}/${uid}`, data, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE patchUser() res retourned =');
        console.log(res);
        this.userObs.next(res);
        this.user = res;
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE patchUser() res returned error");
        console.log(e);
        throw e;
      }));
  }


  /**
   * Updates the user data on the server.
   *
   * @param data - The data to update the user with.
   * @param uid - The unique identifier of the user. Defaults to the current user's UID if not provided.
   * @returns An observable that emits the server response.
   *
   * @remarks
   * This method sends a PUT request to the server to update the user data. It logs the process and handles the response.
   * If the update is successful, it updates the local user observable and user data.
   * If an error occurs, it logs the error and throws it.
   *
   * @example
   * ```typescript
   * const userData = { name: 'John Doe', email: 'john.doe@example.com' };
   * PROFILSERVICE.putUser(userData, '12345').subscribe(
   *   res => console.log('User updated successfully', res),
   *   err => console.error('Error updating user', err)
   * );
   * ```
   */
  putUser(data: any, uid: string | null = this.userUid) {
    console.log('PROFILSERVICE putUser()');
    console.log(data);
    return this.backApiService.putData(`${this.environment.user}/${uid}`, data, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE putUser() res retourned =');
        console.log(res);
        this.userObs.next(res);
        this.user = res;
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE putUser() res returned error");
        console.log(e);
        throw e;
      }));
  }



  /**
   * Validates the user's profile using the provided validator UID and token.
   * 
   * @param {string | null} [userUid=this.userUid] - The UID of the user whose profile is being validated. Defaults to the service's userUid.
   * @param {string | null} validatorId - The UID of the validator. If `validatorType` is 'sms', this will be set to the user's phone validation ID.
   * @param {string | null} [token=''] - The validation token. Defaults to an empty string.
   * @param {string} [validatorType='email'] - The type of validator being used. Can be 'email' or 'sms'. Defaults to 'email'.
   * 
   * @returns {Observable<string | null>} - An observable that emits "success" if the validation is successful, or null if it fails.
   * 
   * @throws {Error} - Throws an error if either `userUid` or `validatorId` is not provided.
   */
  validateProfile(userUid: string | undefined | null = this.userUid, validatorId: string | undefined | null, token: string | null = '', validatorType: string = 'email') {
    console.log('PROFILSERVICE validateProfile()');
    console.log(userUid);
    console.log(validatorId);
    console.log(token);
    console.log(validatorType);
    console.log(this.user);
    let validatorTypeUrl = validatorType === 'sms' ? 'phone_validation' : 'email_validation';
    let addBearer = true;
    let body = { token: token };
    if (validatorType === 'sms' && this.user?.phoneValidation?.id) {
      validatorId = this.user?.phoneValidation?.id;
    } else if (validatorType === 'email') {
      addBearer = false;
    }
    if (!userUid || !validatorId) {
      throw new Error('User UID or validator UID not provided');
    }
    return this.backApiService.patchData(`${this.environment.user}/${userUid}/${validatorTypeUrl}/${validatorId}`, body, addBearer).pipe(
      map((res: any) => {
        console.log('PROFILSERVICE validateProfile() res retourned =');
        console.log(res);
        if (res) {
          if (validatorType === 'sms' && this.user?.phoneValidation) {
            this.user.phoneValidation.validatedAt = new Date().toISOString();
            this.userObs.next(this.user);
          } else if (validatorType === 'email' && this.user?.emailValidation) {
            this.user.emailValidation.validatedAt = new Date().toISOString();
            this.userObs.next(this.user);
          }
          return "success";
        } else {
          return null;
        }
      }),
      catchError(e => {
        console.log("PROFILSERVICE validateProfile() res returned error");
        console.log(e);
        throw e;
      }));
  }

  resendValidationEmail(userUid: string | null = this.userUid) {
    console.log('PROFILSERVICE resendValidationEmail()');
    console.log(userUid);
    return this.backApiService.postData(`${this.environment.user}/${userUid}/Email_validation_token/resend`, {}, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE resendValidationEmail() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE resendValidationEmail() res returned error");
        console.log(e);
        throw e;
      }));
  }

  resendSmsValidation(userUid: string | null = this.userUid) {
    console.log('PROFILSERVICE resendSmsValidation()');
    console.log(userUid);
    return this.backApiService.postData(`${this.environment.user}/${userUid}/Phone_validation_token/resend`, {}, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE resendSmsValidation() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE resendSmsValidation() res returned error");
        console.log(e);
        throw e;
      }));
  }

  resetPassword(email: string) {
    console.log('PROFILSERVICE resetPassword()');
    console.log(email);
    return this.backApiService.postData(`${this.environment.passwordResetRequest}`, { email: email }, false).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE resetPassword() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE resetPassword() res returned error");
        console.log(e);
        throw e;
      }));
  }

  updatePasswordFromToken(token: string, password: string) {
    console.log('PROFILSERVICE updatePasswordFromToken()');
    console.log(token);
    return this.backApiService.postData(`${this.environment.passwordResetValidation}`, { token: token, password: password }, false).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE updatePasswordFromToken() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE updatePasswordFromToken() res returned error");
        console.log(e);
        throw e;
      }));
  }

  postFile(userUid: string | null = this.userUid, fileUid: string, validationName: string, analyseDocument: boolean) {
    console.log('PROFILSERVICE postFile()');
    console.log(userUid);
    console.log(fileUid);
    console.log(validationName);
    console.log(analyseDocument);
    console.log(this.user);
    if (!userUid || !fileUid) {
      throw new Error('User UID or file UID not provided');
    }

    // Vérification si le fichier existe déjà
    let apiValidationName = validationName;
    if (validationName === 'Identity') {
      apiValidationName = 'identityCard';
    } else if (validationName === 'Graduation') {
      apiValidationName = 'graduation';
    } else if (validationName === 'Profile Picture') {
      apiValidationName = 'profilePicture';
    } else if (validationName === 'Resume') {
      apiValidationName = 'resume';
    } else if (validationName === 'KBIS') {
      apiValidationName = 'kbis';
    }

    if (this.user?.[apiValidationName]?.filesUuid?.[0]) {
      // Si le fichier existe, on fait un patch
      return this.patchFile(userUid, fileUid, validationName, analyseDocument, this.user?.[apiValidationName]?.id);
    }
    let file: any = {};
    file.validationName = validationName;
    file.analyseDocument = analyseDocument;
    if (fileUid && typeof fileUid === 'string') {
      file.filesUuid = [fileUid];
    } else if (fileUid && Array.isArray(fileUid)) {
      file.filesUuid = fileUid;
    }
    return this.backApiService.postData(`${this.environment.fileValidations}`, file, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE postFile() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE postFile() res returned error");
        console.log(e);
        throw e;
      }));
  }

  patchFile(userUid: string | null = this.userUid, fileUid: string, validationName: string, analyseDocument: boolean, validatorId: string, status: string = 'Prepared') {
    console.log('PROFILSERVICE patchFile()');
    console.log(userUid);
    console.log(fileUid);
    console.log(validationName);
    console.log(analyseDocument);
    if (!userUid || !fileUid) {
      throw new Error('User UID or file UID not provided');
    }
    let file: any = {};
    file.validationName = validationName;
    file.analyseDocument = analyseDocument;
    if (fileUid && typeof fileUid === 'string') {
      file.filesUuid = [fileUid];
    } else if (fileUid && Array.isArray(fileUid)) {
      file.filesUuid = fileUid;
    }
    if (status === 'Valid' && validationName === 'Resume') {
      file.resumeStatus = 'Valid';
    }
    file.status = status;
    return this.backApiService.patchData(`${this.environment.user}/${userUid}/file_validation/${validatorId}`, file, true).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE patchFile() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE patchFile() res returned error");
        console.log(e);
        throw e;
      }));
  }

  deleteUser(userUid: string | null = this.userUid) {
    console.log('PROFILSERVICE deleteUser()');
    console.log(userUid);
    return this.backApiService.deleteData(`${this.environment.user}/${userUid}`).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE deleteUser() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE deleteUser() res returned error");
        console.log(e);
        throw e;
      }));
  }






}


