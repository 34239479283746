import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CExperiencePage } from '../../pages/c-experience/c-experience.page';



@Component({
  selector: 'app-experiences-list',
  templateUrl: './experiences-list.component.html',
  styleUrls: ['./experiences-list.component.scss'],
})
export class ExperiencesListComponent implements OnInit {
  @Input() experiences: any = null;
  @Input() isMobile: boolean | null = false;
  @Input() candidate: any = null;
  @Input() autoSaveChanges: boolean | null = true;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() { }

  async addExperience(experience: any = null, experiences: any = null, index: number | null = null) {
    console.log('EXPERIENCELIST_C addExperience()');
    console.log(experience);
    let breakpoints: Array<number> | undefined;
    let initialBreakpoint: number | undefined;
    if (this.isMobile) {
      breakpoints = [0, 0.35, 0.5, 0.8, 1];
      initialBreakpoint = 1;
    }
    const modal = await this.modalController.create({
      component: CExperiencePage,
      canDismiss: true,
      backdropDismiss: true,
      breakpoints: breakpoints,
      initialBreakpoint: initialBreakpoint,
      componentProps: {
        "experience": experience,
        "experiences": experiences,
        "candidate": this.candidate,
        "autoSaveChanges": this.autoSaveChanges,
        "index": index
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      let experiences = null;
      console.log('EXPERIENCELIST_C addExperience()');
      console.log(dataReturned);
      console.log(experience);
      if (dataReturned.data && experience) {
        console.log('EXPERIENCELIST_C addExperience() experiences retourned');
        console.log(dataReturned.data);
        // if (dataReturned.data.candidate) {
        //   this.candidate = dataReturned.data;
        // }
        // if (dataReturned.data.experiences) {
        //   this.experiences = dataReturned.data.experiences;
        // }
        if (dataReturned.data.experiences && index !== null) {
          experiences = dataReturned.data.experiences;
        }
      }
    });
    return await modal.present();
  }

}
