import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateOrTime'
})
export class DateOrTimePipe implements PipeTransform {

  constructor() { }

  transform(value: any, format: string = 'dayOrTime'): any {
    console.log('DateOrTimePipe transform()');
    console.log(value);
    console.log(format);
    let day = value.toString().substr(0, 10);
    let theDate = formatDate(new Date().getTime(), "y-MM-dd", 'fr-FR', 'CET');
    switch (format) {
      case "dayOrTime":
        if (day != theDate) {
          return formatDate(new Date(value).getTime(), "dd/MM/y", 'fr-FR', 'CET');
        } else {
          return formatDate(new Date(value).getTime(), "H:mm", 'fr-FR', 'CET');
        }
      case "dayAndTime":
        if (day != theDate) {
          return formatDate(new Date(value).getTime(), "dd/MM/y à H:mm", 'fr-FR', 'CET');
        } else {
          return formatDate(new Date(value).getTime(), "H:mm", 'fr-FR', 'CET');
        }
      case "dayAndTime2":
        return formatDate(new Date(value).getTime(), "dd/MM/yy à H:mm", 'fr-FR', 'CET');
      case "dateOnly":
        return formatDate(new Date(value).getTime(), "dd/MM", 'fr-FR', 'CET');
      case "dateWithYear":
        return formatDate(new Date(value).getTime(), "dd/MM/y", 'fr-FR', 'CET');
      case "dateWithYear2":
        return formatDate(new Date(value).getTime(), "dd/MM/yy", 'fr-FR', 'CET');
      case "yearMonth":
        return formatDate(new Date(value).getTime(), "MM/y", 'fr-FR', 'CET');
      case "daysFromToday":
        let differenceInTime = (new Date().getTime() - new Date(value).getTime());
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);
        if (differenceInDays < 1) {
          return 'Publiée il y a ' + Math.ceil((differenceInTime) / (1000 * 3600)) + ' heure(s)';
        } else {
          return 'Publiée il y a ' + Math.ceil(differenceInDays) + ' jour(s)';
        }
      case "time":
        return value.substring(11, 16);
      default:
        break;
    }
  }

}