import { Pipe, PipeTransform } from '@angular/core';
import { ReferencesService } from '../../services/references/references.service';


@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  references = [];
  constructor(
    private referencesService: ReferencesService
  ) {
    this.references = this.referencesService.getList('environments');
  }

  transform(value: string): any {
    for (let i = 0; i < this.references.length; i++) {
      if (this.references?.[i]?.['uid'] == value) {
        return this.references[i]['label'];
      }
    }
    return value;
  }

}
