import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
// import { BehaviorSubject, Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _storage: Storage | null = null;
  private storageReady: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private storage: Storage) {
    this.init();
  }

  private async init() {
    const storage = await this.storage.create();
    this._storage = storage;
    this.storageReady.next(true);
    console.log('STORAGE_S cst() ready');
  }

  isStorageReady() {
    return firstValueFrom(this.storageReady);
  }


  public async set(key: string, value: any) {
    await this.isStorageReady();
    return this._storage?.set(key, value);
  }

  public async get(key: string) {
    console.log("STORAGE_S get() before await")
    console.log(key);
    await this.isStorageReady();
    console.log("STORAGE_S get() after await")
    return this._storage?.get(key);
  }

  public async remove(key: string) {
    await this.isStorageReady();
    return this._storage?.remove(key);
  }

  public async removeAll() {
    await this.isStorageReady();
    return this._storage?.length().then(length => {
      if (length > 0 && this._storage) {
        return this._storage.forEach((key, value, index) => {
          return this._storage?.remove(key);
        });
      } else {
        return null;
      }
    });
  }

  public async clear() {
    await this.isStorageReady();
    return this._storage?.clear();
  }

}