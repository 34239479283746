import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateOrTimePipe } from './dateOrTime/date-or-time.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { TextFromApiValuePipe } from './textFromApiValue/text-from-api-value.pipe';
import { AuthImagePipe } from './auth-image/auth-image.pipe';
import { TranslatePipe } from './translate/translate.pipe';
import { SizeImgPipe } from './sizeImg/size-img.pipe';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [DateOrTimePipe, TruncatePipe, TextFromApiValuePipe, AuthImagePipe, TranslatePipe, SizeImgPipe],
  exports: [DateOrTimePipe, TruncatePipe, TextFromApiValuePipe, AuthImagePipe, SizeImgPipe, TranslatePipe]
})
export class PipesModule { }

