import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../../services/profil/profil.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SettingsService } from '../settings/settings.service';
import { Browser } from '@capacitor/browser';
import { WindowService } from 'src/app/services/window/window.service';

@Injectable({
  providedIn: 'root'
})
export class RecruiterService {
  userUid: string | null = null;
  recruitersList: any = [];
  myRecruiterProfilObs: BehaviorSubject<any> = new BehaviorSubject(null);
  isPremium: boolean = false;
  myRecruiterProfilSubscribtion: Subscription | undefined;
  isMerging: boolean = false;

  constructor(private storage: LocalStorageService,
    private profilService: ProfilService,
    private alertController: AlertController,
    private backApiService: BackApiService,
    private windowService: WindowService,
    private settingsService: SettingsService) {
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
    });
    /** Update profil after paiement success */
    this.isPremium = this.profilService.getIsPremium();
    this.profilService.getIsPremiumObs().subscribe(isPremium => {
      if (!this.isPremium && isPremium) {
        this.initMyRecruiterProfil();
      }
    });
    console.log('RECRUITERSERVICE constructor()');
    this.restoreStoredrecruiter();
    this.getLogout();
  }


  /**
  * Get detail of my recruiter profil
  * @param {string} uid the recruiter uid to request
  * @return {BehaviorSubject} searchObs recruiter
  */
  initMyRecruiterProfil(uid: string | null = this.userUid) {
    console.log('RECRUITERSERVICE initMyRecruiterProfil()');
    console.log(`${environment.recruiter}/${uid}`);
    if (this.myRecruiterProfilSubscribtion) {
      this.myRecruiterProfilSubscribtion.unsubscribe();
    }
    this.myRecruiterProfilSubscribtion = this.backApiService.getData(`${environment.recruiter}/${uid}`, true).subscribe((res: any) => {
      console.log('RECRUITERSERVICE initMyRecruiterProfil() res retourned =');
      console.log(res);
      if (res) {
        this.setMyRecruiterProfilObs(res);
      }
    }, error => {
      console.log("RECRUITERSERVICE initMyRecruiterProfil() res returned error");
      if (error.status == 404) {
        this.postRecruiter().subscribe(newRecruiter => {
          console.log('RECRUITERSERVICE initMyRecruiterProfil() 404 error -> recruiter created');
        });
      } else {
        this.showAlert("Impossible de récuperer les information sur votre profil. Verifiez votre connexion ou réessayez plus tard");
      }
    });
  }

  getMyRecruiterProfilObs() {
    return this.myRecruiterProfilObs.asObservable();
  }

  setMyRecruiterProfilObs(recruiter: any) {
    this.myRecruiterProfilObs.next(recruiter);
    this.profilService.setUser(recruiter);
  }



  /**
   * Retrieves the logout status and performs necessary actions.
   */
  getLogout() {
    this.profilService.getLogOutObs().subscribe(logOut => {
      if (logOut === true) {
        this.setMyRecruiterProfilObs(null);
      }
    });
  }


  /**
   * Get detail of a recruiter
   * @param {string} uid the recruiter uid to request
   * @return {Observable<any>} recruiter observable
   */
  getRecruiter(uid: string | null = null): Observable<any> {
    console.log('RECRUITERSERVICE getRecruiter()');
    console.log(uid);

    let existingRecruiter: any = null;
    if (uid) {
      existingRecruiter = this.isExistingRecruiter(uid);
    } else if (this.userUid) {
      uid = this.userUid;
    } else {
      this.showAlert("Impossible de récupérer les informations du recruteur. Veuillez vous connecter ou contacter le support.");
      return throwError(() => new Error('Impossible de récupérer les informations du recruteur. Veuillez vous connecter ou contacter le support.'));
    }

    return new Observable<any>((observer) => {
      let useCache = false;

      if (existingRecruiter && existingRecruiter.cachedAt && uid !== this.userUid) {
        const cacheAge = (new Date().getTime() - existingRecruiter.cachedAt.getTime()) / 1000 / 60; // Age en minutes
        if (cacheAge < 10) {
          console.log('RECRUITERSERVICE getRecruiter() using cached data');
          observer.next(existingRecruiter);
          observer.complete();
          useCache = true;
        }
      }

      if (!useCache) {
        console.log('RECRUITERSERVICE getRecruiter() fetching recruiter from API');
        this.backApiService.getData(`${environment.recruiter}/${uid}`, true).pipe(
          take(1),
          catchError(e => {
            console.log("RECRUITERSERVICE getRecruiter() API call returned error");
            console.log(e);
            //  this.showAlert("Impossible de récupérer les informations du recruteur. Vérifiez votre connexion ou réessayez plus tard.");
            observer.error(e);
            return throwError(e);
          })
        ).subscribe(
          (res: any) => {
            console.log('RECRUITERSERVICE getRecruiter() res returned from API:');
            console.log(res);
            if (res?.uid) {
              res.cachedAt = new Date();
              if (existingRecruiter) {
                // Mettre à jour le recruteur existant dans le cache
                const index = existingRecruiter.recruitersListIndex;
                this.recruitersList[index] = res;
              } else {
                // Ajouter un nouveau recruteur dans le cache
                res.recruitersListIndex = this.recruitersList.length;
                this.recruitersList.push(res);
              }
              this.storeRecruiters();
            }
            // Émettre les nouvelles données
            observer.next(res);
            observer.complete();
          }
        );
      }
    });
  }



  // /**
  // * Get detail of a recruiter
  // * @param {string} uid the recruiter uid to request
  // * @return {BehaviorSubject} searchObs recruiter
  // */
  // getRecruiter(uid: string | null = null) {
  //   console.log('RECRUITERSERVICE getRecruiter()');
  //   console.log(uid);
  //   let existingRecruiter: any = null;
  //   if (uid) {
  //     existingRecruiter = this.isExistingRecruiter(uid);
  //   }
  //   if (existingRecruiter && uid != this.userUid) {
  //     console.log('RECRUITERSERVICE getRecruiter() recruiter exist');
  //     if (!existingRecruiter?.cachedAt || (existingRecruiter.cachedAt.getTime() + 7 * 24 * 3600 * 1000) < new Date()) {
  //       this.backApiService.getData(`${environment.recruiter}/${uid}`, true).pipe(
  //         take(1)).subscribe((res: any) => {
  //           if (res?.uid) {
  //             res.cachedAt = new Date();
  //             this.recruitersList[existingRecruiter.recruitersListIndex] = res;
  //             this.storeRecruiters();
  //           }
  //           return res;
  //         }),
  //         catchError(e => {
  //           console.log("RECRUITERSERVICE getRecruiter() update cache res returned error");
  //           throw e;
  //         });
  //     }
  //     return new BehaviorSubject(existingRecruiter);
  //   } else {
  //     console.log('RECRUITERSERVICE getRecruiter() requette =');
  //     console.log(`${environment.recruiter}/${uid}`);
  //     return this.backApiService.getData(`${environment.recruiter}/${uid}`, true).pipe(
  //       tap((res: any) => {
  //         console.log('RECRUITERSERVICE getRecruiter() res retourned =');
  //         console.log(res);
  //         if (res?.uid) {
  //           res.cachedAt = new Date();
  //           this.recruitersList.push(res);
  //           this.storeRecruiters();
  //         }
  //         return res;
  //       }),
  //       catchError(e => {
  //         console.log("RECRUITERSERVICE getRecruiter() res returned error");
  //         throw e;
  //       }));
  //   }
  // }


  /**
  * PUT detail of a recruiter throw the API
  * @param {string} uid the recruiter uid to use
  * @param {string} recruiter the recruiter profil to save
  * @return {BehaviorSubject} searchObs recruiter
  */
  putRecruiter(uid: string | null = this.userUid, recruiter: any) {
    console.log('RECRUITERSERVICE putRecruiter()');
    console.log(uid);
    console.log('RECRUITERSERVICE putRecruiter() requette =');
    return this.backApiService.putData(`${environment.recruiter}/${uid}`, recruiter, true, false).pipe(
      map((res: any) => {
        console.log('RECRUITERSERVICE putRecruiter() res retourned =');
        console.log(res);
        this.setMyRecruiterProfilObs(res);
        return res;
      }),
      catchError(e => {
        console.log("RECRUITERSERVICE putRecruiter() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur le profil. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }






  /**
* POST detail of a recruiter throw the API
* @return {BehaviorSubject} searchObs recruiter
*/
  postRecruiter() {
    console.log('RECRUITERSERVICE postRecruiter()');
    console.log('RECRUITERSERVICE postRecruiter() requette =');
    console.log(`${environment.recruiter}`);
    let recruiter: any = {};
    if (this.settingsService.getFromCampaign()) {
      recruiter = { fromCampaign: this.settingsService.getFromCampaign() };
    }
    return this.backApiService.postData(`${environment.recruiter}`, recruiter, true, false).pipe(
      map((res: any) => {
        console.log('RECRUITERSERVICE postRecruiter() res retourned =');
        console.log(res);
        this.setMyRecruiterProfilObs(res);
        return res;
      }),
      catchError(e => {
        console.log("RECRUITERSERVICE postRecruiter() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur le profil. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
   * Checks if a recruiter with the given UID exists in the recruiters list.
   * 
   * @param uid - The UID of the recruiter to check.
   * @returns The recruiter object if found, otherwise false.
   */
  isExistingRecruiter(uid: string) {
    if (this.recruitersList && this.recruitersList[0]) {
      for (let i = 0; i < this.recruitersList.length; i++) {
        if (uid == this.recruitersList[i].uid && this.recruitersList[i].firstname) {
          this.recruitersList[i].recruitersListIndex = i;
          return this.recruitersList[i];
          break;
        }
      }
      return false;
    }
  }

  /**
   * store recruiter data
   * @return {object} recruiter  - full profile  
   */
  storeRecruiters() {
    console.log('RECRUITERSERVICE storereRecruiter  =');
    console.log(this.recruitersList);
    this.storage.set('recruitersList', this.recruitersList);
  }

  /**
   * Return stored recruiter data
   */
  restoreStoredrecruiter() {
    console.log('RECRUITERSERVICE restoreStoredrecruiter()');
    this.storage.get('recruitersList').then((recruitersList: any) => {
      console.log('RECRUITERSERVICE restoreStoredrecruiter() recruiter = ');
      console.log(this.recruitersList);
      if (recruitersList) {
        this.recruitersList = recruitersList;
      }
      else {
        console.log('RECRUITERSERVICE restoreStoredrecruiter() no recruiter stored');
      }
    });
  }


  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: [{
        text: 'Support',
        handler: () => {
          this.openSupport();
          return true;
        }
      },
      {
        text: 'Ignorer',
        role: 'cancel'
      }
      ]
    });
    alert.then(alert => alert.present());
  }

  /**
* Valide user email in backend with user email and token received from email
*
* @param {string} userEmail 
* @param {string} emailToken token received from autoLoginLink
* @returns AuthServer response
*/
  validateEmailToken(userEmail: string, emailToken: string) {
    return this.backApiService.getData(`${environment.emailValidationRecruiter}/${userEmail}/${emailToken}`, false).pipe(
      map((res: any) => {
        console.log('RECRUITERSERVICE validateEmailToken, res =');
        console.log(res);
        if (res) {
          return "success";
        } else {
          return null;
        }
      }),
      catchError(e => {
        console.log('error validateEmailToken');
        throw e;
      })
    );
  }



  /**
  * reSend validation email
  */
  resendValidationEmail() {
    console.log('RECRUITERSERVICE resendValidationEmail()');
    console.log(environment.resendValidationEmailRecruiter);
    return this.backApiService.getData(`${environment.resendValidationEmailRecruiter}`, true).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE resendValidationEmail() res retourned =');
        return;
      }),
      catchError(e => {
        console.log("RECRUITERSERVICE resendValidationEmail() res returned error");
        this.showAlert("Impossible de renvoyer l'email, veuillez contacter le support");
        throw e;
      }));
  }

  /**
   * Checks for duplicates in the recruiter data.
   * 
   * @returns An Observable that emits the response from the server.
   * @throws Throws an error if there is an error while checking for duplicates.
   */
  checkDuplicates() {
    console.log('RECRUITERSERVICE checkDuplicates()');
    return this.backApiService.getData(`${environment.recruiterDuplicatesCheck}`, true).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE checkDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE checkDuplicates() error=');
        console.log(e);
        throw e;
      }));
  }

  /**
   * Merges duplicate recruiters.
   *
   * @param token - The token used for authentication.
   * @returns An observable that emits the response from the server.
   * @throws An error if the merging process fails.
   */
  mergeRecruiterDuplicates(token: string | null) {
    console.log('RECRUITERSERVICE mergeDuplicates()');
    this.isMerging = true;
    return this.backApiService.postData(`${environment.mergeRecruiterDuplicates}`, { 'token': token }).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE mergeDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE mergeDuplicates() error=');
        console.log(e);
        this.showAlert("Impossible de fusionner, veuillez contacter le support");
        throw e;
      }));
  }

  /**
   * Sends a merge token to a candidate for merging their account.
   * 
   * @param candidateToMergeUid The UID of the candidate to merge.
   * @param updateEmail Indicates whether to update the email during the merge process. Default is false.
   * @returns An Observable that emits the response from the API call.
   * @throws Throws an error if there is an error during the API call.
   */
  sendMergeToken(candidateToMergeUid: string, updateEmail: boolean = false) {
    console.log('RECRUITERSERVICE sendMergeToken()');
    return this.backApiService.postData(`${environment.sendMergeTokenRecruiter}`, { 'targetUuid': candidateToMergeUid, 'updateEmail': updateEmail }).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE sendMergeToken() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE sendMergeToken() error=');
        console.log(e);
        throw e;
      }));
  }

  /**
   * Opens the support page in a browser window.
   * 
   * @param url The URL of the support page. Default is 'https://assistance.clubofficine.fr/hc/fr'.
   * @param browserOption The browser option to use. Default is '_system'.
   */
  openSupport(url = 'https://assistance.clubofficine.fr/hc/fr', browserOption = '_system') {
    if (this.windowService.isPlatformServer()) {
      return;
    }
    console.log("C-OFFER openSupport()");
    Browser.open({ url: url, windowName: browserOption });
  }

  /**
   * Retrieves the value of the isMerging property to know if a merge is in progress.
   * 
   * @returns {boolean} The value of the isMerging property.
   */
  getIsMerging() {
    return this.isMerging;
  }

}
