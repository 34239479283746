import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { SettingsService } from '../settings/settings.service';
import { environment } from 'src/environments/environment';
import { ProfilService } from '../profil/profil.service';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { CandidateService } from '../candidate/candidate.service';
import { RecruiterService } from '../recruiter/recruiter.service';
import { WindowService } from '../window/window.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  supportUrl: string = 'https://assistance.clubofficine.fr/hc/fr/requests/new';
  user: any = null;
  firstname: string = '';
  lastname: string = '';
  userType: string = '';
  email: string = '';
  indicator: string = '';
  number: string = '';
  isMobile: boolean | null = null;
  source: string = 'l_application_mobile';

  constructor(
    private settingsService: SettingsService,
    private profilService: ProfilService,
    private candidateService: CandidateService,
    private recruiterservice: RecruiterService,
    private windowService: WindowService
  ) {
    this.settingsService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    if (environment.isPwa) {
      this.source = 'le_site_internet';
    }
    this.profilService.getuserRoleObs().pipe(filter(val => val != null), take(1)).subscribe(role => {
      if (role == 'ROLE_CANDIDATE') {
        this.candidateService.getCandidateObs().pipe(filter(val => val != null), take(1)).subscribe(candidate => {
          this.user = candidate;
          this.firstname = candidate.firstName;
          this.lastname = candidate.lastName;
          this.email = candidate.email;
          this.indicator = candidate.phone.indicator;
          this.number = candidate.phone.number;
          this.userType = 'candidat';
        });
      } else if (role == 'ROLE_RECRUITER') {
        this.recruiterservice.getMyRecruiterProfilObs().pipe(filter(val => val != null), take(1)).subscribe(recruiter => {
          this.user = recruiter;
          this.firstname = recruiter.firstname;
          this.lastname = recruiter.lastname;
          this.email = recruiter.email;
          this.indicator = recruiter.phone.indicator;
          this.number = recruiter.phone.number;
          this.userType = 'entreprise';
        });
      }
    });
  }

  /**
   * Opens the support page with the provided parameters.
   * 
   * @param supportUrl - The URL of the support page.
   * @param option - The window option for opening the support page. Default is '_system'.
   * @param subject - The subject of the support request. Default is an empty string.
   * @param nature - The nature of the support request. Default is an empty string.
   * @param description - The description of the support request. Default is an empty string.
   */
  openSupport(supportUrl: string | null = this.supportUrl, option = '_system', subject: string = '', nature: string = '', description: string = '') {
    console.log("C-OFFER openSupport()");
    console.log(this.user);
    if (this.windowService.isPlatformServer()) {
      return;
    }
    supportUrl = supportUrl + '?tf_subject=' + subject + '&tf_360019759620=' + nature + '&tf_360019693240=' + this.source + '&tf_anonymous_requester_email=' + this.email + '&tf_description=' + description + '&tf_360019673960=' + this.lastname + '&tf_360019803279=' + this.firstname
      + '&tf_360019803219=' + this.email + '&tf_360019803199=' + this.userType + '&tf_360020651499=' + this.indicator + this.number;
    Browser.open({ url: supportUrl, windowName: option });
  }


}
