import { Injectable } from '@angular/core';
import { CandidateService } from '../../services/candidate/candidate.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ModalController } from '@ionic/angular';
import { WindowService } from '../window/window.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AssociateService } from '../associate/associate.service';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  remindersToDisplay: string | null = null;
  reminders: any = null;
  currentUrl: string | null = null;
  candidate: any = null;
  cProfilReminderObs: BehaviorSubject<any> = new BehaviorSubject(null);


  constructor(private storage: LocalStorageService,
    private modalController: ModalController,
    private router: Router,
    private candidateService: CandidateService,
    private windowService: WindowService,
    private associateService: AssociateService,
  ) {
  }

  getCProfilReminder() {
    return this.cProfilReminderObs;
  }


  /**
   * Watches for navigation events and displays reminders on specific pages.
   * 
   * @remarks
   * This method listens to the router events and checks if the current URL matches a specific page ("/c-profil").
   * If the URL matches and there are reminders to display, it sets a timeout to display the reminders after 1 second.
   * 
   * @returns A Promise that resolves when the reminders are displayed.
   */
  async watchForRemindersDisplayPages() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(async (e: any) => {
      this.currentUrl = e.url;
      console.log('REMINDERS navEvents() =');
      console.log(e);
      if (e.urlAfterRedirects.includes('/c-profil')) {
        if (this.remindersToDisplay) {
          if (this.windowService.isPlatformBrowser()) {
            setTimeout(async () => {
              this.cProfilReminderObs.next(this.remindersToDisplay);
              this.setReminder(this.remindersToDisplay);
            }, 1000);
          }
        }
      }
    });
  }

  /**
   * Initializes the reminders for a given role.
   * 
   * @param role - The role for which the reminders are initialized. Defaults to 'ROLE_CANDIDATE'.
   */
  initReminders(role: string = 'ROLE_CANDIDATE') {
    console.log('REMINDERS initReminders()');
    this.associateService.initMyAssociateWishes();
    this.storage.get('reminders').then((reminders: any) => {
      if (role == 'ROLE_CANDIDATE') {
        if (!reminders) {
          let defaultReminders = {
            picture: { hasPicture: false, nbReminds: 0, lastReminds: null, delayNextReminds: 1.4 },
            experiences: { hasExperiences: false, nbReminds: 0, lastReminds: null, delayNextReminds: 1.6 },
            diplome: { hasDiplome: false, nbReminds: 0, lastReminds: null, delayNextReminds: 1.8 },
            associateWishes: { hasWishes: false, nbReminds: 0, lastReminds: null, delayNextReminds: 1.3 },  // HERE
            firstAppConnection: new Date()
          };
          this.storage.set('reminders', defaultReminders);
        } else if (reminders && !reminders.associateWishes) {
          reminders.associateWishes = { hasWishes: false, nbReminds: 0, lastReminds: null, delayNextReminds: 1.3 };
          this.storage.set('reminders', reminders);
        }
        else {
          console.log('REMINDERS initReminders() reminder exist');
          this.reminders = reminders;
          combineLatest([this.candidateService.getCandidateObs(), this.associateService.getMyAssociateWishesObs()])
            .subscribe(([candidate, associateWishes]) => {
              console.log("REMINDERS_S initReminders() after combine latest");
              console.log(candidate);
              console.log(associateWishes);
              if (!candidate || !associateWishes) {
                return;
              }
              if (candidate && !this.candidate) {
                this.candidate = candidate;
                if (candidate.photo?.path) {
                  this.reminders.picture.hasPicture = true;
                } else {
                  this.reminders.picture.hasPicture = false;
                }
                if (candidate.experiences?.[0]) {
                  this.reminders.experiences.hasExperiences = true;
                } else {
                  this.reminders.experiences.hasExperiences = false;
                }
                if (candidate.qualificationFile) {
                  this.reminders.diplome.hasDiplome = true;
                } else {
                  this.reminders.diplome.hasDiplome = false;
                }
                if (candidate.projectListening && !associateWishes?.toComplete) {
                  this.reminders.associateWishes.hasWishes = true;
                } else {
                  this.reminders.associateWishes.hasWishes = false;
                }
                this.storage.set('reminders', this.reminders);
                console.log(this.reminders);
                if (!this.reminders.picture.hasPicture && this.reminders.picture.nbReminds < 4 && new Date(this.reminders.firstAppConnection).getTime() + 3600000 < new Date().getTime() && (!this.reminders.picture.lastReminds || new Date(this.reminders.picture.lastReminds).getTime() + this.reminders.picture.delayNextReminds * 86400000 < new Date().getTime())) {
                  this.remindersToDisplay = 'picture';
                } else if ((candidate.qualification.uid == '70b16584-b084-4047-b361-c7262a3b1d47' || candidate.qualification.uid == '2b0a6f66-66d1-409b-af70-b3fe28025d6e' || candidate.qualification.uid == '2fa8529a-ead0-457c-a3fa-598e32754a86') && !this.reminders.associateWishes.hasWishes && this.reminders.associateWishes.nbReminds < 4 && new Date(this.reminders.firstAppConnection).getTime() + 120000 < new Date().getTime() && (!this.reminders.associateWishes.lastReminds || new Date(this.reminders.associateWishes.lastReminds).getTime() + this.reminders.associateWishes.delayNextReminds * 86400000 < new Date().getTime())) {
                  this.remindersToDisplay = 'associateWishes';
                } else if (!this.reminders.diplome.hasDiplome && this.reminders.diplome.nbReminds < 4 && new Date(this.reminders.firstAppConnection).getTime() + 3600000 < new Date().getTime() && (!this.reminders.diplome.lastReminds || new Date(this.reminders.diplome.lastReminds).getTime() + this.reminders.diplome.delayNextReminds * 86400000 < new Date().getTime())) {
                  this.remindersToDisplay = 'diplome';
                } else if (!this.reminders.experiences.hasExperiences && this.reminders.experiences.nbReminds < 4 && new Date(this.reminders.firstAppConnection).getTime() + 3600000 < new Date().getTime() && (!this.reminders.experiences.lastReminds || new Date(this.reminders.experiences.lastReminds).getTime() + this.reminders.experiences.delayNextReminds * 86400000 < new Date().getTime())) {
                  this.remindersToDisplay = 'experiences';
                  console.log("REMINDERS initReminders() case experience");
                }
                this.watchForRemindersDisplayPages();
                console.log("REMINDERS initReminders() end");
                console.log(this.reminders);
                console.log(this.remindersToDisplay);
              }
            });

        }
      }
    });
  }


  /**
   * Sets a reminder based on the provided remindersToDisplay value.
   * 
   * @param {string | null} remindersToDisplay - The value indicating which reminder to set.
   * @returns {void}
   */
  setReminder(remindersToDisplay: string | null) {
    console.log("REMINDERS setReminder()");
    console.log(remindersToDisplay);
    switch (remindersToDisplay) {
      case 'picture':
        this.reminders.picture.nbReminds++;
        this.reminders.picture.lastReminds = new Date();
        this.reminders.picture.delayNextReminds = this.reminders.picture.delayNextReminds * this.reminders.picture.delayNextReminds;
        break;
      case 'experiences':
        this.reminders.experiences.nbReminds++;
        this.reminders.experiences.lastReminds = new Date();
        this.reminders.experiences.delayNextReminds = this.reminders.experiences.delayNextReminds * this.reminders.experiences.delayNextReminds;
        break;
      case 'diplome':
        this.reminders.diplome.nbReminds++;
        this.reminders.diplome.lastReminds = new Date();
        this.reminders.diplome.delayNextReminds = this.reminders.diplome.delayNextReminds * this.reminders.diplome.delayNextReminds;
        break;
      case 'associateWishes':
        this.reminders.associateWishes.nbReminds++;
        this.reminders.associateWishes.lastReminds = new Date();
        this.reminders.associateWishes.delayNextReminds = this.reminders.associateWishes.delayNextReminds * this.reminders.associateWishes.delayNextReminds;
        break;
      default:
        break;
    }
    this.storage.set('reminders', this.reminders);
  }




}
