import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { Subscription, BehaviorSubject, throwError, Observable } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../../services/profil/profil.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RecruiterService } from '../../services/recruiter/recruiter.service';
import { TransferState, makeStateKey } from '@angular/core';
import { WindowService } from '../window/window.service';
import { EnvService } from '../env/env.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  userUid: string | null = null;
  companiesList: any = [];
  myCompany: any = null;
  myCompanyObs: BehaviorSubject<any> = new BehaviorSubject(null);
  companiesSubscribtion: Array<Subscription> = [];
  profilCompletion: number | null = null;
  profilCompletionObs: BehaviorSubject<any> = new BehaviorSubject(null);
  environment: any = environment;

  constructor(private storage: LocalStorageService,
    private profilService: ProfilService,
    private alertController: AlertController,
    private recruiterService: RecruiterService,
    private backApiService: BackApiService,
    private windowService: WindowService,
    private transferState: TransferState,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
    });
    console.log('COMPANYSERVICE constructor()');
    this.restoreStoredCompanies();
  }


  /**
   * Resets the state after a user logs out.
   */
  resetAfterLogout() {
    this.userUid = null;
    this.myCompany = null;
    this.myCompanyObs.next(null);
    this.companiesList = [];
    this.storeCompany();
  }

  /**
  * Get detail of my company 
  * @param {string} uid the recruiter uid to request
  * @return {BehaviorSubject} searchObs recruiter
  */
  initMyCompanies(company: any = null) {
    console.log('COMPANYSERVICE initMyCompanies() start');
    console.log(company);

    // Réinitialiser la liste des entreprises
    this.companiesList = [];

    // Annuler les souscriptions existantes
    if (this.companiesSubscribtion && this.companiesSubscribtion.length > 0) {
      this.companiesSubscribtion.forEach(subs => {
        subs.unsubscribe();
      });
      this.companiesSubscribtion = [];
    }

    // Vérifier si companiesUids existe et contient une propriété uuid
    if (company && company.uuid) {
      const uuid = company.uuid;

      // Créer une nouvelle souscription pour récupérer les données de l'entreprise
      const companySubs = this.backApiService.getData(`${this.environment.company}/${uuid}`, true).pipe(
        map((companyData: any) => {
          if (!companyData.siren) {
            companyData.siren = "";
          }
          return companyData;
        })
      ).subscribe(
        (res: any) => {
          console.log('COMPANYSERVICE initMyCompanies() res retourned =');
          console.log(res);
          this.companiesList.push(res);
          this.myCompany = res;
          this.myCompanyObs.next(res);
          this.calculProfilCompletion();
        },
        error => {
          console.log("COMPANYSERVICE initMyCompanies() res returned error");
          this.showAlert("Impossible de récupérer les informations sur votre entreprise. Vérifiez votre connexion ou réessayez plus tard");
        }
      );

      // Ajouter la souscription à la liste des souscriptions
      this.companiesSubscribtion.push(companySubs);
    }
  }
  // initMyCompanies(companiesUids: any = null) {
  //   console.log('COMPANYSERVICE initMyCompanies() start');
  //   console.log(companiesUids);
  //   if (companiesUids[0]) {
  //     this.companiesList = [];
  //     if (this.companiesSubscribtion[0]) {
  //       this.companiesSubscribtion.forEach(subs => {
  //         subs.unsubscribe();
  //       });
  //       this.companiesSubscribtion = [];
  //     }
  //     companiesUids.forEach((res: any, index: number) => {
  //       let companySubs = this.backApiService.getData(`${this.environment.company}/${res.uid}`, true).pipe(
  //         map((companyData: any) => {
  //           if (!companyData.siren) {
  //             companyData.siren = "";
  //           }
  //           return companyData;
  //         })).subscribe((res: any) => {
  //           console.log('COMPANYSERVICE initMyCompanies() res retourned =');
  //           console.log(res);
  //           this.companiesList.push(res);
  //           if (index + 1 == companiesUids.length && this.companiesList?.[0]) {
  //             this.myCompaniesObs.next(this.companiesList);
  //             this.calculProfilCompletion();
  //           }
  //         }, error => {
  //           console.log("COMPANYSERVICE initMyCompanies() res returned error");
  //           this.showAlert("Impossible de récuperer les information sur votre entreprise. Verifiez votre connexion ou réessayez plus tard");
  //         });
  //       this.companiesSubscribtion.push(companySubs);
  //     });
  //   }
  // }

  getMyCompanyObs() {
    return this.myCompanyObs.asObservable();
  }



  /**
   * Get detail of a company
   * @param {string} uid the company uid to request
   * @return {Observable<any>} company observable
   */
  getCompany(uid: string): Observable<any> {
    console.log('COMPANYSERVICE getCompany()');
    console.log(uid);

    let addHeader = !!this.userUid;
    const existingCompany = this.isExistingCompany(uid);

    const DATA_KEY: any = makeStateKey(uid);
    const dataFromTransferState = this.transferState.hasKey(DATA_KEY)
      ? this.transferState.get(DATA_KEY, null)
      : null;

    return new Observable<any>((observer) => {
      let useCache = false;

      if (existingCompany && existingCompany.cachedAt) {
        const cacheAge = (new Date().getTime() - existingCompany.cachedAt.getTime()) / 1000 / 60; // Age en minutes
        if (cacheAge < 10) {
          console.log('COMPANYSERVICE getCompany() using cached data');
          observer.next(existingCompany);
          observer.complete();
          useCache = true;
        }
      }

      if (!useCache) {
        console.log('COMPANYSERVICE getCompany() fetching company from API');
        this.backApiService.getData(`${this.environment.company}/${uid}`, addHeader).pipe(
          take(1),
          catchError(e => {
            console.log("COMPANYSERVICE getCompany() API call returned error");
            this.showAlert("Impossible de récupérer les informations sur l'entreprise. Vérifiez votre connexion ou réessayez plus tard");
            observer.error(e);
            return throwError(e);
          })
        ).subscribe(
          (res: any) => {
            console.log('COMPANYSERVICE getCompany() res returned from API:');
            console.log(res);
            if (!res.siren) {
              res.siren = "";
            }
            if (res?.uuid) {
              res.cachedAt = new Date();
              if (existingCompany) {
                // Mettre à jour l'entreprise existante dans le cache
                const index = existingCompany.companiesListIndex;
                this.companiesList[index] = res;
              } else {
                // Ajouter une nouvelle entreprise dans le cache
                res.companiesListIndex = this.companiesList.length;
                this.companiesList.push(res);
              }
              this.storeCompany();
            }
            if (this.windowService.isPlatformServer()) {
              this.transferState.set(DATA_KEY, res);
            }
            // Émettre les nouvelles données
            observer.next(res);
            observer.complete();
          }
        );
      }
    });
  }




  /**
  * POST detail of a company throw the API
  * @param {string} company the company profil to save
  * @return {BehaviorSubject} searchObs company
  */
  postCompany(company: any) {
    console.log('COMPANYSERVICE postCompany()');
    return this.backApiService.postData(`${this.environment.company}`, company, true, false).pipe(
      map((res: any) => {
        console.log('COMPANYSERVICE postCompany() res retourned =');
        console.log(res);
        this.myCompany = res;
        this.myCompanyObs.next(res);
        return res;
      }),
      catchError(e => {
        console.log("COMPANYSERVICE postCompany() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur l'entreprise. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
  * PUT detail of a company throw the API
  * @param {string} uid the company uid to use
  * @param {string} company the company profil to save
  * @return {BehaviorSubject} searchObs company
  */
  putCompany(uid: string, company: any) {
    console.log('COMPANYSERVICE putCompany()');
    console.log(uid);
    return this.backApiService.putData(`${this.environment.company}/${uid}`, company, true, false).pipe(
      map((res: any) => {
        console.log('COMPANYSERVICE putCompany() res retourned =');
        console.log(res);
        this.myCompany = res;
        this.myCompanyObs.next(res);
        this.calculProfilCompletion();
        return res;
      }),
      catchError(e => {
        console.log("COMPANYSERVICE putCompany() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur l'entreprise. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
   * Checks if a company with the given UID exists in the company list.
   * 
   * @param uid - The UID of the company to check.
   * @returns The company object if it exists, otherwise false.
   */
  isExistingCompany(uid: string) {
    if (this.companiesList && this.companiesList[0]) {
      for (let i = 0; i < this.companiesList.length; i++) {
        if (uid == this.companiesList[i].uuid) {
          this.companiesList[i].companiesListIndex = i;
          return this.companiesList[i];
          break;
        }
      }
      return false;
    }
  }

  setMyRecruiterCompany(company: any) {
    this.companiesList.push(company);
    this.myCompany = company;
    this.myCompanyObs.next(company);
  }

  /**
   * store recruiter data
   * @return {object} recruiter  - full profile  
   */
  storeCompany() {
    console.log('COMPANYSERVICE storereCompany  =');
    console.log(this.companiesList);
    this.storage.set('companiesList', this.companiesList);
  }

  /**
   * Return stored recruiter data
   */
  restoreStoredCompanies() {
    console.log('COMPANYSERVICE restoreStoredCompanies()');
    this.storage.get('companiesList').then((companiesList: any) => {
      console.log('COMPANYSERVICE restoreStoredCompanies() recruiter = ');
      console.log(this.companiesList);
      if (companiesList) {
        this.companiesList = companiesList;
      }
      else {
        console.log('COMPANYSERVICE restoreStoredCompanies() no recruiter stored');
      }
    });
  }

  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }



  /**
   * Calculates the profile completion for a company.
   * 
   * This method calculates the profile completion for a company based on various criteria such as address, KBIS validation status,
   * email validation, category, weekly time slots, software, and company environment. The profile completion is updated and emitted
   * through the `profilCompletionObs` subject.
   */
  calculProfilCompletion() {
    console.log("RECRUITERSERVICE calculProfilCompletion() start");
    console.log(this.myCompany);
    this.profilCompletion = 0;
    let company = this.myCompany;
    this.recruiterService.getMyRecruiterProfilObs().subscribe(recruiter => {
      if (recruiter && this.profilCompletion === 0) {
        console.log("RECRUITERSERVICE calculProfilCompletion() recruiter =");
        console.log(recruiter);
        if (company?.address?.formatedAddress) {
          this.profilCompletion += 20;
        }
        if (recruiter?.kbisValidationStatus == 'Prepared' || recruiter?.kbisValidationStatus == 'Valid') {
          this.profilCompletion += 20;
        }
        if (recruiter?.isEmailValidated) {
          this.profilCompletion += 20;
        }
        if (company?.category?.uid) {
          this.profilCompletion += 10;
        }
        if (company?.weeklyTimeSlot?.tuesday[0]?.startTime || company?.weeklyTimeSlot?.friday[0]?.startTime) {
          this.profilCompletion += 10;
        }
        if (company?.software?.uid) {
          this.profilCompletion += 10;
        }
        if (company?.companyEnvironment) {
          this.profilCompletion += 10;
        }
        this.profilCompletionObs.next(this.profilCompletion);
      }
    });
  }

  getProfilCompletion() {
    return this.profilCompletionObs;
  }

}
