import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

interface FormattedError {
  message: string;
  code?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor(
    public alertController: AlertController,
  ) { }


  formatError(error: any, defaultMessage: string = 'Une erreur inattendue est survenue'): FormattedError {
    let formattedError: FormattedError = {
      message: defaultMessage,
      data: error
    };

    // Find message
    if (typeof error?.error === 'string') {
      formattedError.message = error.error;
    } else if (error?.error?.message) {
      formattedError.message = error.error.message;
    } else if (Array.isArray(error?.error) && error.error[0]?.message) {
      formattedError.message = error.error[0].message;
    }

    // Find code
    if (error?.error?.code) {
      formattedError.code = error.error.code;
    } else if (error?.status) {
      formattedError.code = `HTTP_${error.status}`;
    }

    return formattedError;
  }

  /**
   * Displays an error message based on the provided error object.
   * 
   * @param error - The error object to display.
   */
  displayError(error: any) {
    console.log("ERROR_S displayError() error", error);
    if (error.code?.includes('AUT009')) {
      return;
    }

    let message = '';

    // Cas 1: Vérifier si erreur contient un objet error avec propriété message
    if (error.error && error.error.message) {
      message = error.error.message;
    }
    // Cas 2: Vérifier si erreur contient un objet error avec propriété description
    else if (error.error && error.error.description) {
      message = error.error.description;
    }
    // Cas par défaut: utiliser le message et le code s'ils existent
    else {
      message = error.code ?
        `${error.message} (${error.code})` :
        error.message;
    }

    if (message == 'La ressource existe déjà' && error?.error?.code?.includes('USR')) {
      message = 'Cette adresse email est déjà utilisée';
    }

    this.presentAlert("Erreur", message);
  }



  /**
   * Presents an alert with the given title and message.
   * 
   * @param title - The title of the alert.
   * @param message - The message of the alert.
   * @returns A promise that resolves when the alert is presented.
   */
  async presentAlert(title: string, message: string) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      cssClass: 'error-alert',
      buttons: ['OK']
    });

    await alert.present();
  }


}
