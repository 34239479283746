import { Platform, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { tap, map, retryWhen, delayWhen } from 'rxjs/operators';
import { timer } from 'rxjs';
import { AuthService } from '../auth/auth.service';


/**
* Service used communicate with the backend
* 
* support GET POST PUT 
*/

@Injectable({
  providedIn: 'root'
})
export class BackApiService {
  lastRefreshToken: any = null;


  constructor(private auth: AuthService, private http: HttpClient, private helper: JwtHelperService, private storage: Storage,
    private plt: Platform, private alertController: AlertController) {
  }


  /**
  * 	Send a GET to the api
  * @param {string} path the endpoint to target
  * @return {string} The serveur response
  */
  getData(path: string = "", addJWT: boolean = true, customHeader: any = false) {
    let httpOptions;
    if (addJWT) {
      httpOptions = this.auth.getHeader();
    } else if (customHeader) {
      httpOptions = this.auth.getCustomHeader(customHeader);
    } else {
      httpOptions = this.auth.getHeaderWithoutToken();
    }
    console.log('BACKAPI getData() requette & header =');
    console.log(httpOptions);
    console.log(path);
    return this.http.get(path, httpOptions).pipe(
      tap((res: any) => {
      }),
      retryWhen((errors: any) => {
        let nbRetries = 0;
        let delay = 2000;
        return errors
          .pipe(
            delayWhen(() => timer(delay)),
            map((e: any) => {
              nbRetries++;
              console.log('BACKAPISERVICE retryWhen fired :');
              console.log(errors);
              console.log(e);
              console.log(nbRetries);
              console.log(path);
              if (nbRetries >= 8 || (nbRetries >= 1 && e?.status == 404) || (nbRetries >= 2 && e?.status != 503 && e.status != 0) || (nbRetries >= 2 && path.includes('api/candidate/resume'))) {
                throw e;
              }
              delay = delay * 1.6;
            }));
      }));
  }


  /**
  * 	Send a POST to the api
  * @param {string} path the endpoint to target
  * @return {string} The serveur response
  */
  postData(path: string = "", data: any = "", addJWT: boolean = true, customHeader: any = false) {
    console.log('BACKAPISERVICE postData() ');
    let httpOptions: any;
    if (addJWT) {
      httpOptions = this.auth.getHeader();
      console.log('BACKAPISERVICE postData() addJWT');
    } else if (customHeader) {
      httpOptions = this.auth.getCustomHeader(customHeader);
      console.log('BACKAPISERVICE postData() customHeader');
    } else {
      httpOptions = this.auth.getHeaderWithoutToken();
      console.log('BACKAPISERVICE postData() no JWT');
    }
    console.log('BACKAPISERVICE postData()')
    console.log(httpOptions);
    console.log(`${path}`);
    console.log(data);

    return this.http.post(path, data, httpOptions).pipe(
      tap((res: any) => {
        console.log('BACKSERVICE postData() res = ');
        console.log(res);
        return res;
      }),
      retryWhen((errors: any) => {
        let nbRetries = 0;
        let delay = 2000;
        return errors
          .pipe(
            delayWhen(() => timer(delay)),
            map((e: any) => {
              nbRetries++;
              console.log('BACKAPISERVICE retryWhen fired');
              console.log(e);
              console.log(nbRetries);
              if (nbRetries >= 8 || (nbRetries >= 2 && e?.status != 503 && e.status != 0) || (nbRetries >= 2 && path.includes('api/candidate/resume')) || (nbRetries >= 2 && path.includes('api/candidate/resume'))) {
                console.log('BACKAPISERVICE retryWhen fired throw e');
                throw e;
              }
              delay = delay * 1.6;
            }));
      }));
  }

  /**
  *   Send a PUT to the api
  * @param {string} path the endpoint to target
  * @return {string} The serveur response
  */
  putData(path: string = "", data: any = "", addJWT: boolean = true, customHeader: any = false) {
    console.log('BACKAPISERVICE putData() ');
    let httpOptions;
    if (addJWT) {
      httpOptions = this.auth.getHeader();
    } else if (customHeader) {
      httpOptions = this.auth.getCustomHeader(customHeader);
    } else {
      httpOptions = this.auth.getHeaderWithoutToken();
    }
    console.log(httpOptions);
    console.log(`${path}`);
    console.log(data);
    return this.http.put(path, data, httpOptions).pipe(
      tap((res: any) => {
        console.log('BACKSERVICE putData() res = ');
        console.log(res);
      }),
      retryWhen((errors: any) => {
        let nbRetries = 0;
        let delay = 2000;
        return errors
          .pipe(
            delayWhen(() => timer(delay)),
            map((e: any) => {
              nbRetries++;
              if (nbRetries >= 8 || (nbRetries >= 2 && e?.status != 503 && e.status != 0)) {
                throw e;
              }
              delay = delay * 1.6;
            }));
      }));
  }



  /**
  *   Send a DELETE to the api
  * @param {string} path the endpoint to target
  * @param {string} id the id to target 
  * @return {string} The serveur response
  */
  deleteData(path: string = "", id: any = "") {
    console.log('BACKAPISERVICE deleteData() ');
    let httpOptions = this.auth.getHeader();
    if (id && id.charAt(0) != "/") {
      id = '/'.concat(id);
    }
    return this.http.delete(`${path}${id}`, httpOptions).pipe(
      tap((res: any) => {
        console.log('BACKSERVICE deleteData() res = ');
        console.log(res);
      }),
      retryWhen((errors: any) => {
        let nbRetries = 0;
        let delay = 2000;
        return errors
          .pipe(
            delayWhen(() => timer(delay)),
            map((e: any) => {
              nbRetries++;
              console.log('BACKAPISERVICE retryWhen fired');
              if (nbRetries >= 8 || (nbRetries >= 2 && e?.status != 503 && e.status != 0) || (nbRetries >= 2 && path.includes('api/candidate/resume'))) {
                throw e;
              }
              delay = delay * 1.6;
            }));
      }));
  }

  /**
  * Send a PATCH to the api using merge-patch+json content type
  * @param {string} path the endpoint to target
  * @param {any} data the data to send
  * @param {boolean} addJWT whether to add JWT token
  * @param {any} customHeader optional custom header
  * @return {Observable} The server response
  */
  patchData(path: string = "", data: any = "", addJWT: boolean = true, customHeader: any = false) {
    console.log('BACKAPISERVICE patchData() ');
    let httpOptions;

    if (addJWT) {
      httpOptions = this.auth.getHeaderForPatch();
    } else if (customHeader) {
      httpOptions = this.auth.getCustomHeader(customHeader);
    } else {
      httpOptions = this.auth.getHeaderWithoutTokenPatch();
    }

    console.log('BACKAPISERVICE patchData() headers:');
    console.log(httpOptions);
    console.log(`Path: ${path}`);
    console.log('Data:', data);

    return this.http.patch(path, data, httpOptions).pipe(
      tap((res: any) => {
        console.log('BACKSERVICE patchData() response:');
        console.log(res);
      }),
      retryWhen((errors: any) => {
        let nbRetries = 0;
        let delay = 2000;

        return errors.pipe(
          delayWhen(() => timer(delay)),
          map((e: any) => {
            nbRetries++;
            console.log('BACKAPISERVICE patchData retryWhen fired:', {
              error: e,
              attempt: nbRetries
            });

            // Stop retrying if:
            // - We've tried 8 times
            // - We've tried twice and the error isn't a 503 or timeout
            if (nbRetries >= 8 || (nbRetries >= 2 && e?.status != 503 && e.status != 0)) {
              throw e;
            }

            // Exponential backoff
            delay = delay * 1.6;
          })
        );
      })
    );
  }


  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }


}