import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WindowService } from '../window/window.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { LocalStorageService } from '../local-storage/local-storage.service';



/**
 * DataLayerService class.
 * This service handles the data layer functionality for the application.
 */
@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  window: any;
  userRole: string = 'visitor';
  urlBrowserU: string = '//browser-update.org/update.min.js';
  urlAxeptioScriptElement: any = null;
  urlBrowtherUScriptElement: any = null;
  analyticsEnvironnement: string = 'web';
  firstPageViewEvent: boolean = false;
  currentUrl: string = '';
  consentKey = 'cookie_consent';
  acceptCookiesObs = new BehaviorSubject<string>('pending');
  acceptCookies: string = 'pending';

  constructor(
    private windowService: WindowService,
    private storage: LocalStorageService
  ) {
    this.window = this.windowService.getWindow(); // intialise the window to what we get from our window service
    if (!environment.isPwa) {
      this.analyticsEnvironnement = 'mobile';
    } else {
      this.initCookiesConsent();
    }
  }

  setUserRole(role: string | null) {
    if (!role) {
      return;
    }
    this.userRole = role;
  }

  /**
   * Initializes the external party libraries.
   * This function loads the necessary scripts asynchronously and executes them after a delay of 6000 milliseconds.
   * @returns A promise that resolves when all the scripts have been loaded and executed.
   */
  async initExternalPartyLibrairies() {
    setTimeout(async () => {
      console.log("DATALAYER_S initExternalPartyLibrairies() start");
      await Promise.all([
        this.loadScriptBrowserU(this.urlBrowserU, this.urlBrowtherUScriptElement)
      ]);
    }, 6000);
  }

  /**
   * Loads a script in the browser.
   * 
   * @param scriptUrl - The URL of the script to load.
   * @param urlScriptElement - The script element to append the loaded script to.
   * @returns A promise that resolves when the script is loaded successfully.
   */
  private loadScriptBrowserU(scriptUrl: string, urlScriptElement: any) {
    return new Promise((resolve, reject) => {
      urlScriptElement = document.createElement('script');
      urlScriptElement.src = scriptUrl;
      urlScriptElement.onload = resolve;
      document.body.appendChild(urlScriptElement);
    })
  }




  /**
   * Initializes the cookies consent.
   * 
   * This method retrieves the consent value from the storage and updates the necessary variables and observables accordingly.
   * If the consent is 'refused', it also performs additional actions such as disabling Google Analytics cookies.
   */
  initCookiesConsent() {
    console.log("DATALAYER_S initCookiesConsent() start");
    this.storage.get(this.consentKey).then((consent: any) => {
      if (consent) {
        console.log("DATALAYER_S initCookiesConsent() consent: ", consent);
        this.acceptCookies = consent;
        this.acceptCookiesObs.next(consent);
        if (consent == 'refused') {
          (window as any).gtmConsentGiven = false;
          this.window['ga-disable-G-ZNKTFMS3H9'] = true;
          this.window.dataLayer.push({
            event: 'consentUpdated',
            consentGiven: false
          });
          this.deleteAndDisableGACookies();
        }
      }
    });
  }

  /**
   * Deletes and disables Google Analytics cookies.
   */
  deleteAndDisableGACookies() {
    console.log("DATALAYER_S deleteAndDisableGACookies() start");
    const cookiesToDelete = ['_ga', '_gid', '_gat', '_gcl_au', '_ga_ZNKTFMS3H9'];
    cookiesToDelete.forEach(cookie => {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
  }


  /**
   * Retrieves the cookies consent as an observable.
   *
   * @returns An observable that emits the cookies consent.
   */
  getCookiesConsent() {
    return this.acceptCookiesObs.asObservable();
  }

  /**
   * Sets the cookies consent.
   * 
   * @param consent - The consent value.
   * @returns void
   */
  setCookiesConsent(consent: string): void {
    this.acceptCookies = consent;
    this.acceptCookiesObs.next(consent);
    this.storage.set(this.consentKey, consent);

    if (consent == 'refused') {
      this.deleteAndDisableGACookies();
    }
  }

  /**
   * Pushes the given object to the data layer.
   * If cookies are refused, it deletes and disables GA cookies.
   * 
   * @param obj - The object to be pushed to the data layer.
   */
  pushToDataLayer(obj: any) {
    if (this.acceptCookies == 'refused') {
      this.deleteAndDisableGACookies();
      return;
    }
    if (obj && this.window?.dataLayer) {
      this.window.dataLayer.push(obj);
    }
  }

  /**
   * Pushes the given object to Firebase.
   * 
   * @param obj - The object to be pushed to Firebase.
   */
  pushToFirebase(obj: any) {
    if (this.acceptCookies == 'refused') {
      return;
    }
    if (obj && obj.event && obj.event === 'virtualPageview') {
      FirebaseAnalytics.setCurrentScreen({ screenName: obj.pageTitle });
    }
    else if (obj) {
      let name = obj.event;
      delete obj.event;
      FirebaseAnalytics.logEvent({ name: name, params: obj });
    }
  }


  /**
   * Logs a page view event.
   * 
   * @param url - The URL of the page.
   * @param role - The role of the user (optional, defaults to the current user role).
   */
  logPageView(url: string, role: string | null = this.userRole) {
    if (url.includes('/al/')) {
      return;
    }
    if (url.includes('/tabs')) {
      url = url.replace('/tabs', '');
    }
    if (!role) {
      role = this.userRole;
    } else {
      this.userRole = role;
    }
    this.currentUrl = url;
    const hit = {
      'event': 'virtualPageview',
      'pageUrl': environment.websiteUrl + url,
      'pageTitle': url,
      'visitorType': role,
      'environnement': this.analyticsEnvironnement
    };
    if (environment.isPwa) {
      this.pushToDataLayer(hit);
    } else {
      this.pushToFirebase(hit);
    }
    if (!this.firstPageViewEvent && environment.isPwa && environment.production) {
      this.firstPageViewEvent = true;
      this.initExternalPartyLibrairies();
    }
  }


  /**
   * Logs an event with the given details.
   * 
   * @param event - The event to log.
   * @param details - The details of the event.
   */
  logEvent(event: any, details: any) {
    let hit = details;
    hit.event = event;
    hit.environnement = this.analyticsEnvironnement;
    hit.visitorType = this.userRole;
    hit.pageUrl = environment.websiteUrl + this.currentUrl;
    hit.pageTitle = this.currentUrl;
    if (environment.isPwa) {
      this.pushToDataLayer(hit);
    } else {
      this.pushToFirebase(hit);
    }
  }

  /**
   * Tracks an exception.
   * 
   * @param exception - The exception to track.
   */
  trackException(exception: any) {
    let hit = {
      'exception': exception,
      'exDescription': exception,
      'exFatal': false
    };
  }


}
