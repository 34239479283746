import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
// import { DataService } from '../../services/data/data.service';
import { ProfilService } from '../../services/profil/profil.service';

import { CandidateService } from '../../services/candidate/candidate.service';
import { AlertController } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { RecruiterService } from 'src/app/services/recruiter/recruiter.service';
import { SupportService } from 'src/app/services/support/support.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.page.html',
  styleUrls: ['./sms-modal.page.scss'],
})
export class SmsModalPage implements OnInit {
  isLoading = false;
  userUid: string | null = null;
  userRole: string | null = null;
  disableButton: boolean = false;
  userProfil: any = null;
  customPopoverOptions: any = {
    cssClass: 'mediumPopover'
  };
  token: string | null = null;
  originalPhone = { indicator: "", number: "" };
  duplicate: any = null;
  mergeView: boolean = false;
  emailSelected: string | null = null;
  mergeStep: string | null = null;
  fusionConfirmtext: string | null = 'Oui, Fusionner';
  mobileUrlPrefix: string = 'tabs/';
  isMobile: boolean | null = null;
  submitButtonClicked: boolean = false;
  resendButtonClicked: boolean = false;
  displayValidationInput: boolean = true;

  constructor(private alertController: AlertController,
    private loadingController: LoadingController,
    private candidateService: CandidateService,
    private profilService: ProfilService,
    private modalController: ModalController,
    private navParams: NavParams,
    private settingsService: SettingsService,
    private router: Router,
    private recruiterService: RecruiterService,
    private supportService: SupportService
  ) { }

  ngOnInit() {
    this.settingsService.getDisplayTabs().subscribe((res: any) => {
      if (res) {
        this.mobileUrlPrefix = 'tabs/';
      } else {
        this.mobileUrlPrefix = '';
      }
    });
    this.settingsService.trackModalPage('Candidat - Validation SMS - modale');
    this.userRole = this.navParams.data['userRole'];
    this.userProfil = this.navParams.data['userProfil'];
    this.isMobile = this.navParams.data['isMobile'];
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
      if (this.userProfil && (!this.userProfil.phone || !this.userProfil.phone.number)) {
        this.userProfil.phone = { indicator: "33", number: "" };
      }
      this.originalPhone = { indicator: this.userProfil.phone.indicator, number: this.userProfil.phone.number };
      if (this.userProfil?.phone?.number?.length < 6) {
        this.displayValidationInput = false;
      }
    });
    if (this.userRole == 'ROLE_CANDIDATE') {
      this.checkDuplicates();
    } else if (this.userRole == 'ROLE_RECRUITER') {
      this.mergeStep = 'recruiterMergeStep-1';
      this.duplicate = this.navParams.data?.['duplicate']?.['hydra:member']?.[0];
      this.mergeView = true;
    }
    console.log('SMSMODAL ngOnInit() =');
    console.log(this.navParams.data);
    console.log(this.userProfil);
    console.log(this.duplicate);
    console.log(this.userRole);
    console.log(this.mergeStep);
  }


  validSms() {
    this.submitButtonClicked = true;
    if (!this.token) {
      return;
    }
    this.presentLoading("Validation en cours...");
    console.log("SMSMODAL Validation en cours...");
    if (!this.token) {
      return;
    }
    this.token = this.token.toString();
    while (this.token.length < 6) {
      this.token = '0' + this.token;
    }
    this.candidateService.validSms(this.token.toString()).subscribe((res: any) => {
      console.log("SMSMODAL validSms() res= ");
      console.log(res);
      if (!this.duplicate) {
        this.dismiss();
        this.closeModal('Success');
      } else {
        console.log('SMSMODAL validSms() duplicate');
        console.log(this.duplicate);
        this.dismiss();
        this.mergeView = true;
        this.mergeStep = 'candidateMergeStep-1';
      }

    }, error => {
      console.log('SMSMODAL validSms() failed');
      console.log(error);
      this.presentAlert();
      this.dismiss();
    });
  }

  resendCode() {
    this.resendButtonClicked = true;
    if (this.userProfil?.phone?.number?.length < 6) {
      return;
    }
    this.displayValidationInput = true;
    this.presentLoading();
    console.log('SMSMODAL resendCode()');
    if (this.originalPhone.indicator != this.userProfil.phone.indicator || this.originalPhone.number != this.userProfil.phone.number) {
      this.candidateService.putCandidate(undefined, { "phone": this.userProfil.phone }).subscribe((res: any) => {
        this.userProfil = res;
        this.checkDuplicates();
        this.dismiss();
      }, error => {
        this.dismiss();
      });
    } else {
      this.candidateService.resendValidationSms().subscribe((res: any) => {
        this.dismiss();
      }, error => {
        this.dismiss();
      });
    }
    this.disableButton = true;
    setTimeout(() => {
      this.disableButton = false;
    }, 10000);
  }

  async closeModal(action: any = null) {
    console.log('SMSMODAL closeModal()');
    await this.modalController.dismiss(action, 'close');
  }

  checkDuplicates() {
    console.error('SMSMODAL checkDuplicates()');
    console.log('SMSMODAL checkDuplicates()');
    this.candidateService.checkDuplicates().subscribe((res: any) => {
      console.log('SMSMODAL checkDuplicates() res=');
      console.log(res);
      if (res?.['hydra:totalItems'] >= 1) {
        this.duplicate = res['hydra:member'][0];
        // this.mergeView = true;
        // this.mergeStep = 'candidateMergeStep-1';
      }
    }, error => {
      console.log('SMSMODAL checkDuplicates() error=');
      console.log(error);
    });
  }


  async presentLoading(title: string = 'Envoi en cours...') {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const loading = await this.loadingController.create({
      message: title,
      duration: 8000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }
  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => { });
  }

  openSupport(url: string | undefined = undefined, browserOption: string = '_system', subject: string = '', nature: string = '', description: string = '') {
    console.log("C-OFFER openSupport()");
    this.supportService.openSupport(url, browserOption, subject, nature, description);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Erreur',
      message: 'Le code de validation est incorrecte',
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertConfirmFusion() {
    let message = 'Cette action est définitive et irréversible. Toutes vos candidatures, vos messages, votre photo, diplôme, etc. seront rassemblés.';
    if (this.userRole == 'ROLE_RECRUITER') {
      message = 'Cette action est définitive et irréversible. Toutes vos candidatures, vos messages, vos offres, les informations de votre entreprise, votre accès premium et vos factures seront conservés et rassemblés.';
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmez la fusion ?',
      message: message,
      buttons: [
        {
          text: 'Annuler',
          cssClass: 'secondary',
        },
        {
          text: 'Fusionner',
          handler: () => {
            console.log('SMSMODAL presentAlertConfirmFusion()');
            console.log(this.emailSelected);
            console.log(this.userProfil.email);
            let updateEmail: boolean = false;
            if (this.emailSelected == this.duplicate.email) {
              updateEmail = true;
            }
            if (this.userRole == 'ROLE_CANDIDATE') {
              this.mergeCandidateDuplicates(this.duplicate.uuid, updateEmail);
            } else {
              this.fusionConfirmtext = "J'ai cliqué sur le lien";
              this.sendMergeToken(this.duplicate.uuid, updateEmail);
            }

          }
        }
      ]
    });
    await alert.present();
  }

  sendMergeToken(recruiterToMergeUid: string, updateEmail: boolean = false) {
    this.presentLoading('Préparation de la fusion...');
    console.log('SMSMODAL sendMergeToken()');
    this.recruiterService.sendMergeToken(recruiterToMergeUid, updateEmail).subscribe((res: any) => {
      console.log('SMSMODAL sendMergeToken() res=');
      console.log(res);
      this.dismiss();
      this.mergeStep = 'recruiterMergeStep-email-validation';
    }, error => {
      this.dismiss();
      console.log('SMSMODAL sendMergeToken() error=');
      console.log(error);
    });
  }

  mergeCandidateDuplicates(candidateToMergeUid: string, updateEmail: boolean = false) {
    this.presentLoading('Fusion en cours...');
    console.log('SMSMODAL mergeDuplicates()');
    this.candidateService.mergeDuplicates(candidateToMergeUid, updateEmail).subscribe((res: any) => {
      console.log('SMSMODAL mergeDuplicates() res=');
      console.log(res);
      this.dismiss();
      if (res?.hasAddedExperiences === true) {
        this.selectExperiences();
      } else {
        this.closeModal('Success');
        this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/c-profil/merge');
      }
    }, error => {
      this.dismiss();
      console.log('SMSMODAL mergeDuplicates() error=');
      console.log(error);
    });
  }

  requestFusion() {
    console.log('SMSMODAL requestFusion()');
    console.log(this.mergeStep);
    console.log(this.userProfil);
    console.log(this.duplicate);
    if (this.mergeStep == 'candidateMergeStep-1' && (!this.userProfil.isEmailValidated || this.userProfil.isEmailValidated && this.duplicate.isEmailValidated)) {
      console.log('SMSMODAL requestFusion() candidateMergeStep-1');
      this.mergeStep = 'candidateMergeStep-email';
      this.selectDefaultEmail();
      return;
    } else if (this.mergeStep == 'candidateMergeStep-1' && this.userProfil.isEmailValidated && !this.duplicate.isEmailValidated) {
      console.log('SMSMODAL requestFusion() candidateMergeStep-1 option 2');
      this.presentAlertConfirmFusion();
      return;
    } else if (this.mergeStep == 'candidateMergeStep-email') {
      this.presentAlertConfirmFusion();
      return;
    } else if (this.mergeStep == 'candidateMergeStep-experiences') {
      this.candidateService.putCandidate(this.userProfil.uid, { experiences: this.userProfil.experiences }).subscribe((res: any) => {
        this.closeModal('Success');
        this.router.navigateByUrl(this.mobileUrlPrefix + 'candidat/c-profil/merge');
      });
      return;
    }
    else if (this.mergeStep == 'recruiterMergeStep-1') {
      this.mergeStep = 'recruiterMergeStep-email';
      this.selectDefaultEmail();
      return;
    } else if (this.mergeStep == 'recruiterMergeStep-email') {
      this.presentAlertConfirmFusion();
      return;
    } else if (this.mergeStep == 'recruiterMergeStep-email-validation') {
      this.presentAlertRefresh();
      console.log('SMSMODAL requestFusion() recruiterMergeStep-email-validation');
      console.log(JSON.parse(JSON.stringify(this.router.url)));
      if (!this.router.url.includes('recruter/')) {
        this.router.navigateByUrl(this.mobileUrlPrefix + 'recruteur/r-profil');
      }
      this.closeModal('Success');
      return;
    }
  }

  selectDefaultEmail() {
    console.log('SMSMODAL selectDefaultEmail()');
    if (this.duplicate.isEmailValidated && !this.userProfil.isEmailValidated || this.userRole == 'ROLE_RECRUITER' && !this.userProfil.isEmailValidated) {
      this.emailSelected = this.duplicate.email;
    } else {
      this.emailSelected = this.userProfil.email;
    }
    this.fusionConfirmtext = "Conserver " + this.emailSelected;
  }

  selectEmail(email: string) {
    this.emailSelected = email;
    this.fusionConfirmtext = "Conserver " + this.emailSelected;
  }

  selectExperiences() {
    this.mergeStep = 'candidateMergeStep-experiences';
    this.candidateService.getCandidate().subscribe((res: any) => {
      this.userProfil = res;
      this.fusionConfirmtext = "Valider mes expériences";
    });
  }

  async presentAlertRefresh() {
    let message = 'Si vous avez cliqué sur le lien reçu par email, veuillez actualiser la page.';
    let buttonText = 'Actualiser la page';
    if (!environment.isPwa) {
      message = 'Pour terminer la fusion, veuillez fermer puis réouvrir l\'application.';
      buttonText = 'Ok';
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Fusion terminée',
      message: message,
      buttons: [{
        text: buttonText,
        handler: () => {
          this.updateApp();
        }
      }]
    });

    await alert.present();
  }

  updateApp() {
    if (environment.isPwa) {
      document.location.reload();
    }
  }

}