<ion-app *ngIf="!environment.isPwa || !isPlatformBrowser">
    <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
</ion-app>
<ion-app *ngIf="environment.isPwa && isPlatformBrowser">
    <ion-split-pane when="(min-width: 20000px)" contentId="main-content" *ngIf="isPlatformBrowser">
        <ion-menu contentId="main-content" type="overlay">
            <ion-content>
                <ion-list id="inbox-list">
                    <ion-list-header class="logoListheader">
                        <ion-buttons (click)="menuClick({url: ''}, null)">
                            <img loading="lazy" src="assets/imgs/fulllogo_small.png" height="47px" width="204px" />
                        </ion-buttons>
                    </ion-list-header>

                    <div>
                        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
                            <ion-item lines="none" detail="false" (click)="menuClick(p, i)">
                                <ion-icon slot="start" [ios]="p.icon + '-outline'"
                                    [md]="p.icon + '-outline'"></ion-icon>
                                <ion-label *ngIf="!p.action || p.action!='supportEmail'">{{ p.title}}</ion-label>
                                <ion-label *ngIf="p.action && p.action=='supportEmail'"><a class="supportEmail"
                                        href="mailto:contact+support@titop.fr?Subject=Demande de support&amp;body=Bonjour, je vous contacte concernant...">{{
                                        p.title }}</a></ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </div>
                </ion-list>
            </ion-content>
            <ion-footer>
                <ion-button *ngIf="userRoles?.includes('ROLE_RECRUITER')" shape="round" fill="outline" color="dark"
                    (click)="menuClick({url: '/login'}, null)">Publier
                    une offre</ion-button>
                <div class="appVersion">{{environment.appVersion}}</div>
            </ion-footer>

        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    <app-cookies-consent *ngIf="environment.isPwa && isPlatformBrowser"></app-cookies-consent>
</ion-app>