<ion-content>
    <ion-grid *ngIf="!mergeView && this.userRole == 'ROLE_CANDIDATE'" class="filtersGrid">
        <ion-row class="ion-no-margin ion-no-padding">
            <ion-col size="11" class="ion-no-margin ion-no-padding contactInfosCol">
                <h3>Validation par SMS</h3>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding phoneRow" *ngIf="userProfil && userProfil.phone">
            <ion-col size="12">
                <p class="centerText" *ngIf="displayValidationInput"> Un code de validation a été envoyé
                    par sms au :</p>
                <p class="centerText" *ngIf="!displayValidationInput"> Veuillez completer votre numéro de
                    téléphone mobile :</p>
            </ion-col>
            <ion-col class="ion-no-margin ion-no-padding" size="5">
                <ion-item>
                    <ion-label color="medium" position="floating"
                        [ngClass]="{'errorLabel': !userProfil?.phone?.indicator && (submitButtonClicked || resendButtonClicked)}">Indicatif</ion-label>
                    <ion-select [(ngModel)]="userProfil.phone.indicator" [interfaceOptions]="customPopoverOptions"
                        interface="popover" placeholder="Indicatif">
                        <ion-select-option value="33">France (+33)</ion-select-option>
                        <ion-select-option value="262">la Réunion et Mayotte (+262)</ion-select-option>
                        <ion-select-option value="508">Saint-Pierre-et-Miquelon (+508)</ion-select-option>
                        <ion-select-option value="590">Guadeloupe, Saint-Barthélemy et Saint-Martin
                            (+590)</ion-select-option>
                        <ion-select-option value="594">Guyane (+594)</ion-select-option>
                        <ion-select-option value="596">Martinique (+596)</ion-select-option>
                        <ion-select-option value="262">Mayotte (+262)</ion-select-option>
                        <ion-select-option value="687">Nouvelle-Calédonie (+687)</ion-select-option>
                        <ion-select-option value="689">polynésie française (+689)</ion-select-option>
                        <ion-select-option value="681">Wallis-et-futuna (+681)</ion-select-option>
                        <ion-select-option value="377">Monaco (+377)</ion-select-option>
                        <ion-select-option value="32">Belgique (+32)</ion-select-option>
                        <ion-select-option value="34">Espagne (+34)</ion-select-option>
                        <ion-select-option value="39">Italie (+39)</ion-select-option>
                        <ion-select-option value="41">Suisse (+41)</ion-select-option>
                        <ion-select-option value="352">Luxembourg (+352)</ion-select-option>
                        <ion-select-option value="44">Royaume Uni (+44)</ion-select-option>
                        <ion-select-option value="351">Portugal (+351)</ion-select-option>
                        <ion-select-option value="31">Pays-Bas (+31)</ion-select-option>
                        <ion-select-option value="212">Maroc (+212)</ion-select-option>
                        <ion-select-option value="216">Tunisie (+216)</ion-select-option>
                        <ion-select-option value="213">Algérie (+213)</ion-select-option>
                        <ion-select-option value="353">Irlande (+353)</ion-select-option>
                        <ion-select-option value="86">Chine (+86)</ion-select-option>
                        <ion-select-option value="52">Mexique (+52)</ion-select-option>
                        <ion-select-option value="47">Norvège (+47)</ion-select-option>
                        <ion-select-option value="7">Russie (+7)</ion-select-option>
                        <ion-select-option value="1">Etat Unis / Canada (+1)</ion-select-option>
                        <ion-select-option value="55">Bresil (+55)</ion-select-option>
                        <ion-select-option value="60">Malaisie (+60)</ion-select-option>
                        <ion-select-option value="61">Australie (+61)</ion-select-option>
                        <ion-select-option value="62">Indonésie (+62)</ion-select-option>
                        <ion-select-option value="64">Nouvelle Zélande (+64)</ion-select-option>
                        <ion-select-option value="65">Singapour (+65)</ion-select-option>
                        <ion-select-option value="66">Thailand (+66)</ion-select-option>
                        <ion-select-option value="81">Japon (+81)</ion-select-option>
                        <ion-select-option value="84">Viet Nam (+84)</ion-select-option>
                        <ion-select-option value="91">Inde (+91)</ion-select-option>
                        <ion-select-option value="49">Allemagne (+49)</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col class="ion-no-margin ion-no-padding" size="7">
                <ion-item
                    [ngClass]="{'errorItem': userProfil?.phone?.number?.length < 6 && (submitButtonClicked || resendButtonClicked)}">
                    <ion-label color="medium" position="floating"
                        [ngClass]="{'errorLabel': userProfil?.phone?.number?.length < 6 && (submitButtonClicked || resendButtonClicked)}">Téléphone</ion-label>
                    <ion-input [(ngModel)]="userProfil.phone.number" aria-label="tel"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding codeRow" *ngIf="userProfil && userProfil.phone">
            <ion-col size=12 [ngClass]="{'hideMe':!displayValidationInput}">
                <h3>Code reçu par SMS :</h3>
                <ion-item lines="none">
                    <ion-input placeholder="" type="number" [(ngModel)]="token" aria-label="token"
                        [ngClass]="{'errorInput': !token && userProfil?.phone?.indicator && userProfil?.phone?.number?.length >= 6 && (submitButtonClicked || resendButtonClicked)}"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <div size="12" class="ion-no-margin div--support">
            <ion-button color="dark" fill="clear" size="small"
                (click)="openSupport('https://assistance.clubofficine.fr/hc/fr/articles/360019896579-Que-faire-si-je-ne-re%C3%A7ois-pas-le-code-SMS-pour-activer-mon-compte-candidat')">Contacter
                le
                support</ion-button>
        </div>
    </ion-grid>

    <ion-grid *ngIf="mergeView" class="filtersGrid">
        <ion-row class="ion-no-margin ion-no-padding">
            <ion-col size="11" class="ion-no-margin ion-no-padding contactInfosCol">
                <h3>Il semble que vous ayez un autre compte utilisant les mêmes informations (nom, prénom, téléphone)
                </h3>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding phoneRow"
            *ngIf="userProfil?.email && duplicate?.firstname && mergeStep == 'candidateMergeStep-1'">
            <ion-col size="12">
                <p>
                    Pour simplifier votre expérience, nous vous proposons de fusionner vos comptes. Vos informations
                    seront rassemblées au même endroit. <span class="boldify">Toutes vos candidatures, vos messages,
                        votre photo, votre diplôme, etc.,
                        seront conservés.</span>
                </p>

                <p class="p--fusion-identity">Le compte de <span class="capitalize boldify">{{duplicate.firstname}}
                    </span>
                    <span class="capitalize boldify">{{duplicate.lastname}}</span> attaché à l'email
                    <span class="boldify">{{duplicate.email}}</span>
                    vous appartient-il ? Voulez-vous le fusionner avec <span class="boldify">{{userProfil.email}}
                        ?</span>
                </p>

            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding phoneRow"
            *ngIf="userProfil?.email && duplicate?.firstname && mergeStep == 'recruiterMergeStep-1'">
            <ion-col size="12">
                <p>
                    Pour simplifier votre expérience, nous vous proposons de fusionner vos comptes. Vos informations
                    seront rassemblées au même endroit. <span class="boldify">Toutes vos candidatures, vos messages,
                        vos offres, les informations de votre entreprise, votre accès premium et vos factures seront
                        conservés.</span></p>
                <p class="p--fusion-identity">Le compte de <span class="capitalize boldify">{{duplicate.firstname}}
                    </span>
                    <span class="capitalize boldify"> {{duplicate.lastname}} </span> attaché à l'email
                    <span class="boldify">{{duplicate.email}}</span>
                    vous appartient-il ? Voulez-vous le fusionner avec <span class="boldify">{{userProfil.email}}
                        ?</span>
                </p>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="mergeStep == 'candidateMergeStep-email'" class="ion-no-margin ion-no-padding phoneRow">
            <ion-col size="12" class="col--emails">
                <h4>Quelle adresse email souhaitez-vous conserver ?</h4>
                <ion-row>
                    <ion-col size="12" sizeLg="5.9" class="col--block"
                        [ngClass]="{'email-valide': userProfil.isEmailValidated, 'email-notValide': !userProfil.isEmailValidated, 'email-selected': emailSelected == userProfil.email}"
                        (click)="selectEmail(userProfil.email)">
                        <h5>{{userProfil.email}}</h5>
                        <div class="email-status" *ngIf="userProfil.isEmailValidated && !duplicate.isEmailValidated">
                            Email
                            confirmé</div>
                        <div class="email-status" *ngIf="!userProfil.isEmailValidated">Email non confirmé</div>
                        <div class="email-validation-text" *ngIf="!userProfil.isEmailValidated">Après avoir terminé la
                            fusion, vous devrez cliquer sur le lien de validation envoyé par e-mail.</div>
                    </ion-col>
                    <ion-col size="12" sizeLg="5.9" class="col--block"
                        [ngClass]="{'email-valide': duplicate.isEmailValidated, 'email-notValide': !duplicate.isEmailValidated, 'email-selected': emailSelected == duplicate.email}"
                        (click)="selectEmail(duplicate.email)">
                        <h5>{{duplicate.email}}</h5>
                        <div class="email-status" *ngIf="duplicate.isEmailValidated && !userProfil.isEmailValidated">
                            Email
                            confirmé</div>
                        <div class="email-status" *ngIf="!duplicate.isEmailValidated">Email non confirmé</div>
                        <div class="email-validation-text" *ngIf="!duplicate.isEmailValidated">Après avoir terminé la
                            fusion, vous devrez cliquer sur le lien de validation envoyé par e-mail.</div>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="mergeStep == 'recruiterMergeStep-email'" class="ion-no-margin ion-no-padding phoneRow">
            <ion-col size="12" class="col--emails">
                <h4>Quelle adresse email souhaitez-vous conserver ?</h4>
                <ion-row>
                    <ion-col size="12" sizeLg="5.9" class="col--block"
                        [ngClass]="{'email-valide': userProfil.isEmailValidated, 'email-notValide': !userProfil.isEmailValidated, 'email-selected': emailSelected == userProfil.email}"
                        (click)="selectEmail(userProfil.email)">
                        <h5>{{userProfil.email}}</h5>
                        <!-- <div class="email-status" *ngIf="userProfil.isEmailValidated && !duplicate.isEmailValidated">
                            Email
                            confirmé</div> -->
                        <div class="email-status" *ngIf="!userProfil.isEmailValidated">Email non confirmé</div>
                        <div class="email-validation-text"
                            *ngIf="!userProfil.isEmailValidated && emailSelected == userProfil.email">Après avoir
                            terminé la
                            fusion, vous devrez cliquer sur le lien de validation envoyé par e-mail.</div>
                    </ion-col>
                    <ion-col size="12" sizeLg="5.9" class="col--block"
                        [ngClass]="{'email-valide': duplicate.isEmailValidated, 'email-notValide': !duplicate.isEmailValidated, 'email-selected': emailSelected == duplicate.email}"
                        (click)="selectEmail(duplicate.email)">
                        <h5>{{duplicate.email}}</h5>
                        <!-- <div class="email-status" *ngIf="duplicate.isEmailValidated && !userProfil.isEmailValidated">
                            Email
                            confirmé</div>
                        <div class="email-status" *ngIf="!duplicate.isEmailValidated">Email confirmé avec la fusion</div>
                        <div class="email-validation-text" *ngIf="!duplicate.isEmailValidated">Vous devrez cliquer sur le lien de validation envoyé par e-mailpour valider le mail et terminer la fusion.</div> -->
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="mergeStep == 'recruiterMergeStep-email-validation'"
            class="ion-no-margin ion-no-padding phoneRow">
            <ion-col size="12" class="col--emails">
                <h4>Confirmez votre email {{emailSelected}}</h4>
                <ion-row>
                    <ion-col size="12">
                        <div class="link-sent-text">Un lien de confirmation a été envoyé à {{duplicate.email}},
                            Veuillez cliquer sur le lien pour terminer la fusion</div>
                        <div class="link-lost-text">je n'ai plus accès à la boite email {{duplicate.email}}, <span
                                class="link"
                                (click)="openSupport(undefined, undefined, 'Fusionner mes comptes', 'doublon_fusionner_mes_comptes')">
                                je
                                souhaite ouvrir un ticket de support </span></div>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>


        <ion-row *ngIf="mergeStep == 'candidateMergeStep-experiences'" class="ion-no-margin ion-no-padding phoneRow">
            <ion-col size="12" class="col--emails">
                <h4>Quelles expériences souhaitez-vous conserver ?</h4>
                <div class="helper">Veuillez relire et ajuster vos expériences professionnelles. Des boutons de
                    modification et de suppression sont à votre disposition pour chaque entrée</div>
                <app-experiences-list *ngIf="userProfil?.experiences?.length > 0" [experiences]="userProfil.experiences"
                    [isMobile]="isMobile" [candidate]="userProfil" [autoSaveChanges]=false></app-experiences-list>
            </ion-col>
        </ion-row>
        <ion-row class="ion-no-margin ion-no-padding phoneRow" *ngIf="userProfil?.email && duplicate?.firstname">
            <ion-col size="12" class="ion-no-margin col--support">
                <ion-button color="dark" fill="clear" size="small"
                    (click)="openSupport(undefined, undefined, 'Fusionner mes comptes', 'doublon_fusionner_mes_comptes')">Contacter
                    le
                    support</ion-button>
            </ion-col>
        </ion-row>
        <ion-spinner *ngIf="mergeView && (!userProfil?.email || !duplicate?.firstname)"></ion-spinner>
    </ion-grid>

</ion-content>
<ion-footer *ngIf="mergeView && userProfil?.email && duplicate?.firstname">
    <ion-button class="validStepButton" expand="block" (click)="requestFusion()">{{fusionConfirmtext}}</ion-button>
    <ion-button class="validStepButton" expand="block" fill="outline" color="secondary" (click)="closeModal()"
        disabled={{disableButton}}>Non, Ignorer</ion-button>
</ion-footer>

<ion-footer *ngIf="!mergeView">
    <ion-button class="validStepButton" expand="block" (click)="validSms()" *ngIf="displayValidationInput">Valider le
        code reçu par sms</ion-button>
    <ion-button class="validStepButton" expand="block" fill="outline" color="secondary" (click)="resendCode()"
        disabled={{disableButton}}>Renvoyez moi un code</ion-button>
</ion-footer>