<ion-grid class="ion-no-padding">
  <ion-row class="ion-no-padding" *ngFor="let zone of searchAddresses; let index = index">
    <ion-col class="ion-no-padding" size="12" *ngIf="zone">

      <ion-row class="ion-no-margin ion-no-padding localisationRow">



        <ion-col class="ion-no-padding">

          <ion-row class="ion-no-margin ion-no-padding searchbarRow" align-items-center>
            <ion-col class="ion-no-margin ion-no-padding col--searchAddressInitiation" size="12"
              *ngIf="associatesWishes && !searchAddresses?.[0]?.selectedAdress && !associatesWishes?.locations?.[0] && candidate?.wish?.searchAddresses?.[0]">
              <ion-button (click)="loadAssociatesZonesFromCandidate()" fill="outline" size="small"
                color="secondary">Initialiser avec mes zones de
                recherche d'emploi</ion-button>
            </ion-col>

            <ion-col *ngIf="displayGeolocationButton" size="2" class="ion-no-margin ion-no-padding col--geoloc">
              <ion-button (click)="geolocalise(searchAddresses[0])" size="small" color="secondary" fill="clear">
                <ion-icon slot="icon-only" name="location-outline"></ion-icon>
              </ion-button>
            </ion-col>

            <ion-col class="ion-no-padding">
              <ion-searchbar [disabled]="disabled" (ionFocus)="gotFocus(zone)" (ionBlur)="lostFocus(zone)"
                (ionClear)="clearAdressesList()" debounce="400" placeholder={{placeholder}}
                (ionInput)="updateCity(zone)" [(ngModel)]="zone.localisation"
                [ngStyle]="customSearchBarStyle"></ion-searchbar>
            </ion-col>

            <ion-col *ngIf="displayGeolocationButton" size="2" class="ion-no-margin ion-no-padding">
            </ion-col>


            <ion-col size="2" *ngIf="zone.focusState=='blur' && index>0">
              <ion-button fill="clear" (click)="removeZone(index)">
                <ion-icon slot="icon-only" name="remove-circle"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-card class="adressCard" *ngIf="zone.adressesList && zone.adressesList[0]">
            <ion-list>
              <ion-item lines="none" *ngFor="let adress of zone.adressesList" (click)="selectCity(zone, adress)">
                {{adress.Text}}
              </ion-item>
              <div *ngIf="displayGeolocationButton" class="lineBeforeGeoloc"> </div>
              <ion-item (click)="geolocalise(searchAddresses[0])" lines="none">
                <ion-icon slot="start" name="location-outline" color="secondary"></ion-icon> Votre
                position
              </ion-item>
            </ion-list>
          </ion-card>
        </ion-col>
      </ion-row>

      <ion-row class="ion-no-margin ion-no-padding ion-justify-content-center rayonRow" *ngIf="displayRadius">
        <ion-col class="ion-no-padding" size="12" sizeLg="8.3">
          <ion-row>
            <ion-col size="10">
              <p>Dans un rayon de <span>{{zone.radius}}km</span></p>
            </ion-col>
          </ion-row>

          <ion-item lines="none">
            <ion-range min="5" max="100" color="secondary" [(ngModel)]="zone.radius" step="5" aria-label="rayon">
              <ion-label slot="start">5km</ion-label>
              <ion-label slot="end">100km</ion-label>
            </ion-range>
          </ion-item>
        </ion-col>


        <ion-col class="ion-no-margin ion-no-padding col--zone-mobile" size="12" sizeLg="3.7"
          *ngIf="displayMobileIfHoused">
          <ion-item lines="none" class="item--zone-mobile">
            <ion-label color="dark">Uniquement si logé</ion-label>
            <ion-toggle color='secondary' slot="start" [(ngModel)]="zone.mobileIfHoused"
              aria-label="mobile"></ion-toggle>
          </ion-item>
        </ion-col>
      </ion-row>


    </ion-col>
  </ion-row>
  <ion-row *ngIf="nbMaxAddress > 1">
    <ion-col>
      <div size="12" class="error" *ngIf="searchAddresses && searchAddresses.length >= nbMaxAddress"> Nombre maximal de
        zones de
        recherche
        atteinte</div>
    </ion-col>
    <ion-col size="12" class="ion-text-center" *ngIf="searchAddresses.length < nbMaxAddress">
      <ion-button color="medium" class="addZone" fill="outline" (click)="addZone()">+ Ajouter
        une <span *ngIf="searchAddresses && searchAddresses.length>=1"> autre </span> {{zoneOrVilleText}}</ion-button>
    </ion-col>
  </ion-row>

</ion-grid>