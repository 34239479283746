import { Injectable } from '@angular/core';
import { map, catchError, take, tap } from 'rxjs/operators';
import { merge, Subscriber, combineLatest, observable, BehaviorSubject } from 'rxjs';
import { BackApiService } from '../back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { AlertController } from '@ionic/angular';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  fullSubscriptionDataObs: BehaviorSubject<any> = new BehaviorSubject(null);
  unpaidBillObs = new BehaviorSubject(false);
  ordersAlertsObs: BehaviorSubject<any> = new BehaviorSubject(null);
  environment: any = environment;

  constructor(
    private alertController: AlertController,
    private backApiService: BackApiService,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
  }

  /**
   * Get invoicesDatas
   * @returns 
   */
  getFullSubscriptionData() {
    return combineLatest([this.getSubscriptions(), this.getOrders()]).pipe(map(([subscriptions, orders]) => {
      console.log('INVOICESSERVICE getFullSubscriptionData() res retourned =');
      console.log(subscriptions);
      console.log(orders);
      this.fullSubscriptionDataObs.next({ subscriptions, orders });
      return { subscriptions, orders };
    }),
      catchError(e => {
        console.log("INVOICESSERVICE getFullSubscriptionData() res returned error");
        throw e;
      }));
  }

  getFullSubscriptionDataObs() {
    return this.fullSubscriptionDataObs;
  }


  /**
  * getsubscriptions from API
  * @return {BehaviorSubject} searchObs invoices list
  */
  getSubscriptions() {
    console.log('INVOICESSERVICE getSubscriptions() start');
    return this.backApiService.getData(this.environment.subscriptions, true, false).pipe(
      map((res: any) => {
        console.log('INVOICESSERVICE getSubscriptions() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("INVOICESSERVICE getSubscriptions() res returned error");
        this.showAlert("Impossible de récupérer les factures. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }


  /**
  * getsubscriptions from API
  * @return {BehaviorSubject} searchObs invoices list
  */
  getOrders() {
    console.log('INVOICESSERVICE getOrders() start');
    return this.backApiService.getData(this.environment.orders + "?itemsPerPage=40", true, false).pipe(
      map((res: any) => {
        console.log('INVOICESSERVICE getOrders() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("INVOICESSERVICE getOrders() res returned error");
        this.showAlert("Impossible de récupérer les factures. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  /**
  * getsubscriptions from API
  * @return {BehaviorSubject} searchObs invoices list
  */
  getOrdersAlerts() {
    console.log('INVOICESSERVICE getordersAlerts() start');
    return this.backApiService.getData(this.environment.ordersAlerts, true, false).pipe(
      map((res: any) => {
        console.log('INVOICESSERVICE getordersAlerts() res retourned =');
        console.log(res);
        if (res?.ordersNotFinalized?.[0]) {
          this.ordersAlertsObs.next(res);
          this.unpaidBillObs.next(true);
        } else {
          this.unpaidBillObs.next(false);
        }
        return res;
      }),
      catchError(e => {
        console.log("INVOICESSERVICE getordersAlerts() res returned error");
        throw e;
      }));
  }

  getUnpaidBillObs() {
    return this.unpaidBillObs;
  }

  getOrderAlertsObs() {
    return this.ordersAlertsObs;
  }

  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }


  /**
   * Retrieves the products observable.
   * 
   * @returns An observable that emits the products data.
   */
  getProductsObs() {
    return this.backApiService.getData(`${this.environment.products}?available=me`, true).pipe(take(1), tap((res: any) => {
      console.log('INVOICESERVICE getProducts() res retourned =');
      console.log(res);
      if (res) {
        return res;
      }
    }, error => {
      console.log("INVOICESERVICE getProducts() res returned error");
      this.showAlert("Impossible de récuperer les produits. Verifiez votre connexion ou réessayez plus tard");
    }));
  }


  /**
   * Retrieves the product observable for the given product UID.
   * 
   * @param productUid - The UID of the product.
   * @returns An observable that emits the product data.
   */
  getProductObs(productUid: string) {
    return this.backApiService.getData(`${this.environment.products}/${productUid}`, true).pipe(take(1), tap((res: any) => {
      console.log('INVOICESERVICE getProducts() res retourned =');
      console.log(res);
      if (res) {
        return res;
      }
    }, error => {
      console.log("INVOICESERVICE getProduct() res returned error");
      this.showAlert("Impossible de récuperer le produit. Verifiez votre connexion ou réessayez plus tard");
    }));
  }

  /**
   * Retrieves the payment link for a given real product.
   * 
   * @param realProduct - The real product for which to retrieve the payment link.
   * @returns An Observable that emits the payment link response.
   */
  getpaymentLink(realProduct: any) {
    return this.backApiService.postData(`${this.environment.paymentLink}`, realProduct).pipe(take(1), tap((res: any) => {
      console.log('INVOICESERVICE getProducts() res retourned =');
      console.log(res);
      if (res) {
        return res;
      }
    }, error => {
      console.log("INVOICESERVICE getProducts() res returned error");
      this.showAlert("Impossible de récuperer les produits. Verifiez votre connexion ou réessayez plus tard");
    }));
  }

  /**
   * Makes a payment using the specified payment type for the given order.
   * 
   * @param payementType - The type of payment to use.
   * @param orderUid - The unique identifier of the order.
   * @returns An Observable that emits the response from the payment API.
   */
  payBy(payementType: string, orderUid: string) {
    return this.backApiService.postData(`${this.environment.payWith}${payementType}`, {
      "order": [
        orderUid]
    }).pipe(take(1), tap((res: any) => {
      console.log('INVOICESERVICE payBy() res retourned =');
      console.log(res);
      if (res) {
        return res;
      }
    }, error => {
      console.log("INVOICESERVICE payBy() res returned error");
      console.log(error);
      if (!error?.error?.message?.includes('Order is not in good status')) {
        this.showAlert("Impossible d'ajouter le moyen de paiement. Verifiez votre connexion ou réessayez plus tard");
      }

    }));
  }

}