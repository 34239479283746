<ion-content>
    <ion-grid>
        <ion-row class="closeRow ion-padding ion-justify-content-end">
            <ion-col
                *ngIf="(outdatedVersion && !outdatedVersionForce) || rateApp || deleteOffer || pageFunction == 'info'"
                size="1" class="ion-no-margin ion-no-padding" (click)="closeModal()">
                <ion-icon class="closeModalButton" name="close-outline"></ion-icon>
            </ion-col>
        </ion-row>
        <ion-row class="titleBlock ion-no-padding ion-justify-content-center">
            <ion-col size="10" size-md="8" size-xl="6" class="ion-no-padding">
                <img src="./assets/imgs/fulllogo.png" />
            </ion-col>
        </ion-row>

        <ion-row class="titleRow" *ngIf="pageFunction == 'maintenance' || pageFunction == 'welcomeMessage'">
            <ion-col size="12">
                <h1 (click)="byPassMaintenance()"> {{pageTitle}}</h1>
                <div>{{pageDetail}}</div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="pageFunction == 'maintenance' || pageFunction == 'welcomeMessage'" class="buttonsRow">
            <ion-col size="12">
                <ion-button color="primary" expand="block" (click)="refresh()" *ngIf="pageFunction === 'maintenance'">
                    Rafraichir
                </ion-button>
                <ion-button color="primary" expand="block" (click)="closeModal()"
                    *ngIf="pageFunction === 'welcomeMessage'">
                    Continuer
                </ion-button>
            </ion-col>
        </ion-row>


        <ion-row *ngIf="outdatedVersion" class="titleRow">
            <ion-col size="12">
                <h1> Mise à jour disponible</h1>
                <div>Afin de fonctionner correctement et de bénéficier des dernières fonctionnalités, votre application
                    a besoin d'être mise à jour.</div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="outdatedVersion" class="buttonsRow">
            <ion-col size="12">
                <ion-button color="primary" expand="block" (click)="update()">
                    Mettre à jour
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="rateApp && userType == 'candidate'" class="titleRow">
            <ion-col size="12">
                <h1> Votre avis compte !</h1>
                <div>Êtes-vous satisfait de l'application ClubOfficine ?</div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="rateApp && userType == 'candidate'" class="buttonsRow">
            <ion-col size="12">
                <ion-button color="primary" expand="block" (click)="rate()">
                    Satisfait
                </ion-button>
                <ion-button color="secondary" fill='outline' expand="block" (click)="complain()">
                    Insatisfait
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="deleteOffer && !rateApp" class="titleRow">
            <ion-col size="12">
                <h1> Supprimer mon offre</h1>
                <div>Merci de bien vouloir nous indiquer la raison de la suppression de votre offre :</div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="deleteOffer && !rateApp" class="listRow">
            <ion-col size="12">
                <ion-list>
                    <ion-radio-group [(ngModel)]="deletedReason">
                        <ion-radio slot="start" value="renew"><span>Je souhaite renouveler ou modifier mon
                                offre</span></ion-radio>
                        <ion-radio slot="start" value="provided-cbo"><span>Offre pourvue grâce à
                                ClubOfficine</span></ion-radio>
                        <ion-radio slot="start" value="not-provided-cbo"><span>Offre pourvue par un autre
                                moyen</span></ion-radio>
                        <ion-radio slot="start" value="not-provided"><span>Offre non pourvue et plus
                                d'actualité</span></ion-radio>
                    </ion-radio-group>
                </ion-list>
                <!-- <ion-button class="validDeleteButton" color="primary" expand="block" (click)="validDeletedReason()">
                    Valider
                </ion-button> -->
            </ion-col>
        </ion-row>
        <ion-row *ngIf="deleteOffer && !rateApp" class="validDeleteButton">
            <ion-col size="12">
                <ion-button color="primary" expand="block" (click)="validDeletedReason()" [disabled]="!deletedReason">
                    Valider
                </ion-button>
            </ion-col>
        </ion-row>



        <ion-row *ngIf="deleteOffer && rateApp" class="titleRow">
            <ion-col size="12">
                <h1> Votre avis compte !</h1>
                <div>Êtes-vous satisfait de l'application ClubOfficine ?</div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="deleteOffer && rateApp" class="buttonsRow">
            <ion-col size="12">
                <ion-button color="primary" expand="block" (click)="rate()">
                    Satisfait
                </ion-button>
                <ion-button color="secondary" fill='outline' expand="block" (click)="complain()">
                    Insatisfait
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="pageFunction == 'info'" class="titleRow">
            <ion-col size="12">
                <h1>{{pageTitle}}</h1>
                <h1>{{pageSubtitle}}</h1>
                <div class="detail-text" [innerHTML]="pageDetail"></div>
                <div class="div--img">
                    <img *ngIf="pageImage" [height]=pageImgHeight class="img--infos"
                        [ngClass]="{'img--canClick': pageLink}" [src]="pageImage" (click)="openUrl(pageLink)">
                    <img *ngIf="pageLink" class="img--play" src="./assets/imgs/playBlack.png"
                        (click)="openUrl(pageLink)">
                </div>
            </ion-col>
        </ion-row>

    </ion-grid>
</ion-content>

<ion-footer *ngIf="pageFunction == 'info'"
    [ngClass]="{'footer035': initialBreakpoint == 0.35, 'footer05': initialBreakpoint == 0.5, 'footer08': initialBreakpoint == 0.8}">
    <ion-button color="primary" expand="block" (click)="closeModal()" *ngIf="skipButtonName && !openPageUrlButtonName"
        class="validStepButton">
        Continuer
    </ion-button>

    <div *ngIf="skipButtonName && openPageUrlButtonName" class="validStepButton--infos">
        <ion-button color="primary" expand="block" (click)="closeModal()" class="validStepButton">
            {{skipButtonName}}
        </ion-button>
        <ion-button class="validStepButton" color="secondary" expand="block"
            (click)="navigateTo(openPageUrl)">{{openPageUrlButtonName}}</ion-button>
    </div>
</ion-footer>