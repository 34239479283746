import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizeImg'
})
export class SizeImgPipe implements PipeTransform {

  transform(value: any, format: string = 'mobile_medium_scale'): any {
    return value + '?format=' + format;
  }

}