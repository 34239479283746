import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
// import { RecruiterGuardService } from './services/recruiter-guard/recruiter-guard.service';
import { DataResolverService } from './resolver/dataResolver/data-resolver.service';


const routes: Routes = [
  {
    path: 'tabs/candidat',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'tabs/recruteur',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs/transaction',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    data: { breadcrumb: '' },
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pwa/home/home.module').then(m => m.HomePageModule),
        data: { breadcrumb: '' },
      },
      {
        path: 'sso-linkedin',
        loadChildren: () => import('./pages/pwa/home/home.module').then(m => m.HomePageModule),
        data: { breadcrumb: 'sso-linkedin' },
      },
      {
        path: 'sso-google',
        loadChildren: () => import('./pages/pwa/home/home.module').then(m => m.HomePageModule),
        data: { breadcrumb: 'sso-google' },
      },
      {
        path: 'sso-apple',
        loadChildren: () => import('./pages/pwa/home/home.module').then(m => m.HomePageModule),
        data: { breadcrumb: 'sso-apple' },
      },
      {
        path: 'sso-facebook',
        loadChildren: () => import('./pages/pwa/home/home.module').then(m => m.HomePageModule),
        data: { breadcrumb: 'sso-facebook' },
      },
      {
        path: 'al',
        loadChildren: () => import('./pages/auto-login/auto-login.module').then(m => m.AutoLoginPageModule)
      },
      {
        path: 'al/:token',
        loadChildren: () => import('./pages/auto-login/auto-login.module').then(m => m.AutoLoginPageModule)
      },
      {
        path: 'recommendation/:recoTokenInvitation/:candidateUid',
        loadChildren: () => import('./pages/r-search/r-search.module').then(m => m.RSearchPageModule)
      },
      {
        path: 'login',
        data: { breadcrumb: 'Login' },
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
            data: { breadcrumb: null }
          },
          {
            path: ':redirect',
            data: { breadcrumb: null },
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
                data: { breadcrumb: null }
              },
              {
                path: 'email/:loginOrRegister/:resetPassword',
                loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
                data: { breadcrumb: null }
              }
            ]
          },
          {
            path: 'email/:loginOrRegister/:resetPassword',
            loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
            data: { breadcrumb: 'Email' },
          }
        ]
      }
    ]
  },
  {
    path: 'tunel/:role/:step/:isNewUser',
    loadChildren: () => import('./pages/tunel/tunel.module').then(m => m.TunelPageModule)
  },
  {
    path: 'rechercher/offres',
    children: [
      {
        path: '',
        data: { breadcrumb: "Offres d'emploi" },
        loadChildren: () =>
          import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
      },
      // {
      //   path: ':id',
      //   data: { breadcrumb: "Offres d'emploi" },
      //   resolve: { data: DataResolverService },
      //   loadChildren: () =>
      //     import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
      // },
      {
        path: 'offre/:id',
        resolve: { data: DataResolverService },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
          }
        ]
      },
      {
        path: ':city',
        data: { breadcrumb: "Offres d'emploi" },
        loadChildren: () =>
          import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
      }
    ]
  },
  {
    path: 'regions',
    children: [
      {
        path: '',
        data: { breadcrumb: 'Emploi par ville' },
        loadChildren: () => import('./pages/pwa/regions/regions.module').then(m => m.RegionsPageModule)
      },
      {
        path: ':id',
        data: { breadcrumb: 'Emploi par ville' },
        resolve: { data: DataResolverService },
        loadChildren: () =>
          import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
      },
    ]
  },
  {
    path: 'metier',
    children: [
      {
        path: '',
        data: { breadcrumb: 'Emploi par metier' },
        loadChildren: () => import('./pages/pwa/regions/regions.module').then(m => m.RegionsPageModule)
      },
      {
        path: ':id',
        data: { breadcrumb: 'Emploi par metier' },
        resolve: { data: DataResolverService },
        loadChildren: () =>
          import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
      },
    ]
  },
  {
    path: 'premium-info-modal',
    loadChildren: () => import('./pages/premium-info-modal/premium-info-modal.module').then(m => m.PremiumInfoModalPageModule)
  },
  {
    path: 'r-tunel/:isNewUser',
    loadChildren: () => import('./pages/r-tunel/r-tunel.module').then(m => m.RTunelPageModule)
  },
  /** WEBAPP ONLY START  */
  {
    path: 'rechercher/candidats',
    children: [
      {
        path: '',
        data: { breadcrumb: 'Les candidats' },
        loadChildren: () => import('./pages/r-search/r-search.module').then(m => m.RSearchPageModule)
      },
      {
        path: ':id',
        data: { breadcrumb: 'Les candidats' },
        resolve: { data: DataResolverService },
        loadChildren: () => import('./pages/r-search/r-search.module').then(m => m.RSearchPageModule)
      }
    ]
  },
  {
    path: 'candidat',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'rechercher/offres',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
          },
          {
            path: ':id',
            data: { breadcrumb: "Offres d'emploi" },
            resolve: { data: DataResolverService },
            loadChildren: () =>
              import('./pages/c-offers/c-offers.module').then(m => m.COffersPageModule)
          },
          {
            path: 'offre/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
              },
              {
                path: 'c-thread/:id',
                resolve: { data: DataResolverService },
                loadChildren: () =>
                  import('./pages/c-thread/c-thread.module').then(m => m.CThreadPageModule)
              }
            ]
          }
        ]
      },
      {
        path: '',
        redirectTo: '/candidat/rechercher/offres',
        pathMatch: 'full'
      },
      {
        path: 'mes-propositions',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-proposals/c-proposals.module').then(m => m.CProposalsPageModule)
          },
          {
            path: 'offre/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
              },
              {
                path: 'c-thread/:id',
                resolve: { data: DataResolverService },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/c-thread/c-thread.module').then(m => m.CThreadPageModule)
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'messagerie',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-chat/c-chat.module').then(m => m.CChatPageModule)
          },
          {
            path: 'c-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/c-thread/c-thread.module').then(m => m.CThreadPageModule)
              },
              {
                path: 'offre/:id',
                resolve: { data: DataResolverService },
                loadChildren: () =>
                  import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
              }
            ]
          },
        ]
      },
      {
        path: 'mes-candidatures',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-candidatures/c-candidatures.module').then(m => m.CCandidaturesPageModule)
          },
          {
            path: 'offre/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
              },
              {
                path: 'c-thread/:id',
                resolve: { data: DataResolverService },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/c-thread/c-thread.module').then(m => m.CThreadPageModule)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'c-profil',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          },
          {
            path: ':modalFieldValue',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          },
          {
            path: 'validation/email/:token',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          },
          {
            path: 'offer-newsletter/:offer-newsletter',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          }
        ]
      },
      {
        path: 'simulateur',
        loadChildren: () => import('./pages/pwa/simulateur/simulateur.module').then(m => m.SimulateurPageModule)
      },
      {
        path: 'comparateur-salaire/pharmacien',
        loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
      },
      {
        path: 'comparateur-salaire/preparateur-en-pharmacie',
        loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
      }
    ]
  },
  {
    path: 'recruteur',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'candidatures',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/r-candidates/r-candidates.module').then(m => m.RCandidatesPageModule)
          },
          {
            path: 'r-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
              },
              {
                path: 'offre/:id',
                loadChildren: () =>
                  import('./pages/r-offer/r-offer.module').then(m => m.ROfferPageModule),
                resolve: { data: DataResolverService }
              },
              {
                path: 'r-statistics/:id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-statistics/r-statistics.module').then(m => m.RStatisticsPageModule)
              }
            ]
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/r-candidates/r-candidates.module').then(m => m.RCandidatesPageModule)
              },
              {
                path: ':options',
                loadChildren: () => import('./pages/r-candidates/r-candidates.module').then(m => m.RCandidatesPageModule)
              }
            ]
          }
        ]
      },
      {
        path: 'cvtheque',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/r-search/r-search.module').then(m => m.RSearchPageModule)
          },
          {
            path: 'r-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
              },
              {
                path: 'r-statistics/:id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-statistics/r-statistics.module').then(m => m.RStatisticsPageModule)
              }
            ]
          }
        ]
      },
      {
        path: 'messages',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/r-chat/r-chat.module').then(m => m.RChatPageModule)
          },
          {
            path: 'r-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
              },
              {
                path: 'offre/:id',
                loadChildren: () =>
                  import('./pages/r-offer/r-offer.module').then(m => m.ROfferPageModule),
                resolve: { data: DataResolverService }
              },
              {
                path: 'r-statistics/:id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-statistics/r-statistics.module').then(m => m.RStatisticsPageModule)
              }
            ]
          }
        ]
      },
      {
        path: 'mes-offres',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/r-offers/r-offers.module').then(m => m.ROffersPageModule)
          },
          {
            path: 'r-offer/:id',
            resolve: { data: DataResolverService },
            loadChildren: () => import('./pages/r-offer/r-offer.module').then(m => m.ROfferPageModule)
          },
          {
            path: 'r-offer/:id/:refresh',
            resolve: { data: DataResolverService },
            loadChildren: () => import('./pages/r-offer/r-offer.module').then(m => m.ROfferPageModule)
          },
          {
            path: 'r-create-offer',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-create-offer/r-create-offer.module').then(m => m.RCreateOfferPageModule)
              },
              {
                path: ':id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-create-offer/r-create-offer.module').then(m => m.RCreateOfferPageModule)
              },
              {
                path: ':id/:action',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-create-offer/r-create-offer.module').then(m => m.RCreateOfferPageModule)
              }
            ]
          },
          {
            path: 'candidatures',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/r-candidates/r-candidates.module').then(m => m.RCandidatesPageModule)
              },
              {
                path: 'r-thread/:id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
              },
              {
                path: 'r-statistics/:id',
                resolve: { data: DataResolverService },
                loadChildren: () => import('./pages/r-statistics/r-statistics.module').then(m => m.RStatisticsPageModule)
              },
              {
                path: ':id',
                loadChildren: () => import('./pages/r-candidates/r-candidates.module').then(m => m.RCandidatesPageModule)
              },
            ]
          },
          {
            path: 'r-statistics/:id',
            resolve: { data: DataResolverService },
            loadChildren: () => import('./pages/r-statistics/r-statistics.module').then(m => m.RStatisticsPageModule)
          },
          {
            path: ':status',
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-offers/r-offers.module').then(m => m.ROffersPageModule)
              }
            ]
          },
          {
            path: 'cvtheque/:id',
            resolve: { data: DataResolverService },
            loadChildren: () => import('./pages/r-search/r-search.module').then(m => m.RSearchPageModule)
          },
          {
            path: 'cvtheque/:id/r-thread/:id',
            resolve: { data: DataResolverService },
            loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
          }
        ]
      },
      {
        path: 'r-profil',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/r-profil/r-profil.module').then(m => m.RProfilPageModule)
          },
          {
            path: ':modalFieldValue',
            loadChildren: () =>
              import('./pages/r-profil/r-profil.module').then(m => m.RProfilPageModule)
          },
          {
            path: 'validation/email/:token',
            loadChildren: () =>
              import('./pages/r-profil/r-profil.module').then(m => m.RProfilPageModule)
          },
          {
            path: 'candidates-newsletter/:candidates-newsletter',
            loadChildren: () =>
              import('./pages/r-profil/r-profil.module').then(m => m.RProfilPageModule)
          }
        ]
      },
      {
        path: 'payment-success',
        loadChildren: () => import('./pages/pwa/payment-success/payment-success.module').then(m => m.PaymentSuccessPageModule)
      },
      {
        path: 'tabs/payment-success',
        loadChildren: () => import('./pages/pwa/payment-success/payment-success.module').then(m => m.PaymentSuccessPageModule)
      },
      {
        path: '',
        redirectTo: '/tabs/recruteur/candidatures',
        pathMatch: 'full'
      },
      {
        path: 'souscrire',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/pwa/souscrire/souscrire.module').then(m => m.SouscrirePageModule)
      },
      {
        path: 'simulateur',
        loadChildren: () => import('./pages/pwa/simulateur/simulateur.module').then(m => m.SimulateurPageModule)
      },
      {
        path: 'comparateur-salaire/pharmacien',
        loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
      },
      {
        path: 'comparateur-salaire/preparateur-en-pharmacie',
        loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
      }
    ]
  },
  {
    path: 'simulateur',
    loadChildren: () => import('./pages/pwa/simulateur/simulateur.module').then(m => m.SimulateurPageModule)
  },
  {
    path: 'souscrire',
    canActivate: [],
    loadChildren: () => import('./pages/pwa/souscrire/souscrire.module').then(m => m.SouscrirePageModule)
  },
  {
    path: 'payment-modal',
    loadChildren: () => import('./pages/pwa/payment-modal/payment-modal.module').then(m => m.PaymentModalPageModule)
  },
  {
    path: 'tabs/payment-success',
    loadChildren: () => import('./pages/pwa/payment-success/payment-success.module').then(m => m.PaymentSuccessPageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./pages/pwa/payment-success/payment-success.module').then(m => m.PaymentSuccessPageModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/pwa/not-found/not-found.module').then(m => m.NotFoundPageModule)
  },
  /* Bellow email redirections */
  {
    path: 'disponible/true',
    redirectTo: '/candidat/c-profil/disponible',
    pathMatch: 'full'
  },
  {
    path: 'disponible/false',
    redirectTo: '/candidat/c-profil/indisponible',
    pathMatch: 'full'
  },
  {
    path: 'recruteur/offre/actualiser/:id',
    redirectTo: '/recruteur/mes-offres/r-offer/:id/true',
    pathMatch: 'full'
  },
  {
    path: 'candidat/validation/email/:token',
    redirectTo: '/candidat/c-profil/validation/email/:token',
    pathMatch: 'full'
  },
  {
    path: 'recruteur/validation/email/:token',
    redirectTo: '/recruteur/r-profil/validation/email/:token',
    pathMatch: 'full'
  },
  {
    path: 'not-found',
    redirectTo: '/404',
    pathMatch: 'full'
  },
  {
    path: 'validation/candidat/email/:email/:token',
    loadChildren: () => import('./pages/pwa/validation/validation.module').then(m => m.ValidationPageModule)
  },
  {
    path: 'validation/recruteur/email/:email/:token',
    loadChildren: () => import('./pages/pwa/validation/validation.module').then(m => m.ValidationPageModule)
  },
  {
    path: 'comparateur-salaire/pharmacien',
    loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
  },
  {
    path: 'comparateur-salaire/preparateur-en-pharmacie',
    loadChildren: () => import('./pages/salary-comparator/salary-comparator.module').then(m => m.SalaryComparatorPageModule)
  },
  {
    path: 'transaction',
    data: { breadcrumb: 'Transaction' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/transactions/t-home/t-home.module').then(m => m.THomePageModule)
      },
      {
        path: 'simulateur-vente',
        loadChildren: () => import('./pages/transactions/t-simulator/t-simulator.module').then(m => m.TSimulatorPageModule)
      },
      {
        path: 'vendre',
        data: { breadcrumb: 'Vendre mon officine' },
        loadChildren: () => import('./pages/transactions/t-sale/t-sale.module').then(m => m.TSalePageModule)
      },
      {
        path: 'creer-offre',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./pages/transactions/t-create-offer/t-create-offer.module').then(m => m.TCreateOfferPageModule)
      },
      {
        path: 'titulaire',
        loadChildren: () => import('./pages/transactions/t-owner/t-owner.module').then(m => m.TOwnerPageModule)
      },
      {
        path: 'souscrire',
        data: { breadcrumb: 'Souscrire' },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/transactions/t-subscribe/t-subscribe.module').then(m => m.TSubscribePageModule)
          },
          {
            path: ':pricingToDisplay',
            loadChildren: () =>
              import('./pages/transactions/t-subscribe/t-subscribe.module').then(m => m.TSubscribePageModule)
          }
        ]
      },
      {
        path: 'recherche',
        data: { breadcrumb: 'Rechercher' },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/transactions/t-search/t-search.module').then(m => m.TSearchPageModule)
          },
          {
            path: ':city',
            loadChildren: () =>
              import('./pages/transactions/t-search/t-search.module').then(m => m.TSearchPageModule)
          }
        ]
      },
      {
        path: 't-candidatures',
        loadChildren: () =>
          import('./pages/transactions/t-proposes/t-proposes.module').then(m => m.TProposesPageModule)
      },
      {
        path: 'accueil',
        children: [
          {
            path: '',
            data: { breadcrumb: 'Transaction' },
            loadChildren: () =>
              import('./pages/transactions/t-home/t-home.module').then(m => m.THomePageModule)
          },
          {
            path: ':subPage',
            loadChildren: () =>
              import('./pages/transactions/t-home/t-home.module').then(m => m.THomePageModule)
          }
        ]
      },
      {
        path: 'messagerie',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-chat/c-chat.module').then(m => m.CChatPageModule)
          },
          {
            path: 'c-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/c-thread/c-thread.module').then(m => m.CThreadPageModule)
              },
              {
                path: 'offre/:id',
                resolve: { data: DataResolverService },
                loadChildren: () =>
                  import('./pages/c-offer/c-offer.module').then(m => m.COfferPageModule)
              }
            ]
          },
        ]
      },
      {
        path: 'messages',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/r-chat/r-chat.module').then(m => m.RChatPageModule)
          },
          {
            path: 'r-thread/:id',
            resolve: { data: DataResolverService },
            children: [
              {
                path: '',
                loadChildren: () => import('./pages/r-thread/r-thread.module').then(m => m.RThreadPageModule)
              },
              {
                path: 'offre/:id',
                loadChildren: () =>
                  import('./pages/r-offer/r-offer.module').then(m => m.ROfferPageModule),
                resolve: { data: DataResolverService }
              }
            ]
          }
        ]
      },
      {
        path: 't-profil',
        loadChildren: () => import('./pages/transactions/t-profil/t-profil.module').then(m => m.TProfilPageModule)
      },
      {
        path: 'offre',
        loadChildren: () => import('./pages/transactions/t-offer/t-offer.module').then(m => m.TOfferPageModule)
      },
      {
        path: 'c-profil/:action',
        loadChildren: () => import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
      },
      {
        path: 'r-profil/:action',
        loadChildren: () => import('./pages/r-profil/r-profil.module').then(m => m.RProfilPageModule)
      },
      {
        path: 'profil-completion',
        loadChildren: () => import('./pages/transactions/t-profil/t-profil.module').then(m => m.TProfilPageModule)
      },
      {
        path: 'profil',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          },
          {
            path: ':projectListening',
            loadChildren: () =>
              import('./pages/c-profil/c-profil.module').then(m => m.CProfilPageModule)
          }
        ]
      },
    ]
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/cookies/cookies.module').then(m => m.CookiesPageModule)
  }
]

/** WEBAPP ONLY END  */


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
