import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';


/**
  Check Authentification state & update App routing accordingly
*/
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  /**
 * @ignore
 */
  constructor(public auth: AuthService) { }

  /**
 * Allow route if user Authetificated
 * @return {BehaviorSubject} Auth state
 */
  canActivate(): boolean {
    console.log('AUTH GUARD canActivate()');
    console.log(this.auth.isAuthenticated());
    return this.auth.isAuthenticated();
  }

}