<div *ngIf="consentGiven == 'pending'" class="cookie-consent">
  <h3>À propos des cookies sur clubofficine.fr</h3>
  <p>Bienvenue ! Ce site utilise des cookies pour mesurer la fréquentation du site afin d’en améliorer le fonctionnement
    et
    l’administration, pour améliorer votre expérience utilisateur, ainsi que pour vous proposer des services
    interactifs.
    Nous conservons votre choix mais vous pouvez changer ce choix à tout moment en vous rendant sur la page <span
      class="link" [routerLink]="['/cookies']"> Données personnelles et cookies.</span></p>
  <ion-button (click)="setConsent('refused')" fill="clear" color="dark">Tout refuser</ion-button>
  <ion-button (click)="setConsent('granted')" class="button--accept">Tout accepter</ion-button>
</div>