import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { AlgoliaService } from '../../services/algolia/algolia.service';
import { ReferencesService } from '../../services/references/references.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import { LoadingController } from '@ionic/angular';

import { IonContent } from '@ionic/angular';
import { ViewChild } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-c-experience',
  templateUrl: './c-experience.page.html',
  styleUrls: ['./c-experience.page.scss'],
})
export class CExperiencePage implements OnInit {
  @ViewChild(IonContent) content!: IonContent;
  buttonValidStepPressed: boolean = false;
  step1Form!: UntypedFormGroup;
  experience: any = null;
  experiences: any = [];
  index: number | null = null;
  //    inOffice: boolean = false;
  customPopoverOptions: any = {
    cssClass: 'whidePopover'
  };
  selectedAdress: boolean = false;
  adressesList: any = [];
  focusState = 'blur';
  companyTypeList: any = null;
  qualificationList: any = null;
  fullAdress: any = null;
  isLoading: boolean = false;
  candidate: any = null;

  today = new Date();
  dateMax = new Date();
  autoSaveChanges: boolean = true;
  searchAddressBorderStyle = { 'border': '0px' };
  dataInitiated: boolean = false;

  constructor(private loadingController: LoadingController, private candidateService: CandidateService, private referencesService: ReferencesService, private navParams: NavParams, private algoliaService: AlgoliaService, private modalController: ModalController, private formBuilder: UntypedFormBuilder) {
    this.dateMax.setDate(this.today.getDate() + 1000);
  }



  ngOnInit() {
    console.log('C-EXPERIENCE ngOnInit()');
    this.companyTypeList = this.referencesService.getList('companyTypes');
    this.companyTypeList.unshift({ 'uid': null, 'label': 'Autre / Non renseigné' });
    this.qualificationList = this.referencesService.getList('qualifications');
    this.qualificationList.unshift({ 'uid': null, 'label': 'Autre / Non renseigné' });
    if (this.navParams.data['experience']) {
      this.experience = this.navParams.data['experience'];
      console.log('C-EXPERIENCE experience =');
      console.log(this.experience);
      // this.fullAdress = this.experience.address;
    }
    if (this.navParams.data['experiences']) {
      this.experiences = this.navParams.data['experiences'];
      console.log('C-EXPERIENCE experiences =');
      console.log(this.experiences);
    }
    if (this.navParams.data['autoSaveChanges']) {
      this.autoSaveChanges = this.navParams.data['autoSaveChanges'];
    }
    if (this.navParams.data['index'] || this.navParams.data['index'] === 0) {
      this.index = this.navParams.data['index'];
      console.log('C-EXPERIENCE index in navParams');
    }
    console.log('C-EXPERIENCE index =');
    console.log(this.index);
    if (this.experience) {
      if (!this.experience.companyType) {
        this.experience.companyType = { 'uid': null };
      }
      if (!this.experience.qualification) {
        this.experience.qualification = { 'uid': null };
      }
      let dateStart;
      let dateEnd;
      if (this.experience.dateStart) {
        dateStart = this.experience.dateStart //.slice(0, 7);
      }
      if (this.experience.dateEnd) {
        dateEnd = this.experience.dateEnd // .slice(0, 7);
      }

      this.step1Form = this.formBuilder.group({
        companyType: [this.experience.companyType.uid],
        inOffice: [this.experience.inOffice],
        dateStart: [dateStart, [Validators.required]],
        dateEnd: [dateEnd, [Validators.required]],
        //  title: [this.experience.title, [Validators.required]],
        category: [this.experience.category],
        qualification: [this.experience.qualification.uid],
        companyName: [this.experience.companyName, [Validators.required]],
        address: [],
        description: [this.experience.description]
      });
      console.log("C-EXPERIENCE_P ngOnInit() experience = ");
      console.log(this.experience);
      if (this.experience?.address?.city) {
        this.step1Form.controls['address'].setValue(this.experience.address.city);
        this.fullAdress = { address: this.experience.address };
      }
    } else {
      this.step1Form = this.formBuilder.group({
        //         uid: [''],
        companyType: [''],
        inOffice: [false],
        dateStart: [new Date().toISOString(), [Validators.required]],
        dateEnd: [new Date().toISOString(), [Validators.required]],
        qualification: [''],
        companyName: ['', [Validators.required]],
        address: [''],
        description: ['']
      });
    }
    this.dataInitiated = true;
    console.log("C-EXPERIENCE_P ngOnInit() form = ");
    console.log(this.step1Form);
  }



  async closeModal(update: boolean = false) {
    console.log('C-EXPERIENCE closeModal()');
    if (update) {
      await this.modalController.dismiss({ candidate: this.candidate, experiences: this.experiences, experience: this.experience });
    } else {
      await this.modalController.dismiss();
    }
  }

  customCounterFormatter(inputLength: number, maxLength: number) {
    return `${maxLength - inputLength} caractères restants`;
  }

  /**
  * Request Algolia API dateEnd get adresses
  **/
  updateAdress(address: Array<any>) {
    console.log("C-EXPERIENCE updateAdress()=");
    console.log(this.step1Form);
    console.log(address);
    if (address?.[0]?.address?.zipcode && address?.[0]?.address?.city) {
      this.step1Form.controls['address'].setValue(address[0].address.city + ', ' + address[0].address.zipcode);
      this.fullAdress = address[0].address;
      this.selectedAdress = true;
    } else {
      this.selectedAdress = false;
    }
  }


  scollToElement(event: any, delay: number = 1) {
    console.log('scroolToBottom');
    let yPosition: number = event.srcElement.getBoundingClientRect().y;
    console.log(JSON.parse(JSON.stringify(event.srcElement.getBoundingClientRect())));
    if (yPosition > 280) {
      this.content.scrollToPoint(0, yPosition - 10, delay);
    }
  }

  gotFocus() {
    this.focusState = 'focus';
  }

  lostFocus() {
    this.focusState = 'blur';
  }

  /**
* Check if form complete and without error then validate experience
**/
  validExperience() {
    this.buttonValidStepPressed = true;
    if ((!this.step1Form.value.dateEnd || this.step1Form.value.dateEnd == "") && this.step1Form.value.inOffice) {
      this.step1Form.controls['dateEnd'].setValue(null);
      this.step1Form.controls['dateEnd'].setValidators(null);
      this.step1Form.controls['dateEnd'].setErrors(null);
      this.step1Form.controls['dateEnd'].markAsTouched();
      this.step1Form.controls['dateEnd'].updateValueAndValidity();
    }
    /*
     Manage date coherence
     }  */
    console.log('C-EXPERIENCE validExperience()');
    console.log(this.step1Form);

    if (this.step1Form.status == 'VALID') {
      console.log('C-EXPERIENCE validStep() step 1 valide :');
      console.log(this.step1Form.value);
      console.log(this.step1Form.value);
      let body: any = {};
      if (this.index || this.index == 0) {
        console.log("C-EXPERIENCE validStep() experiences updated. uid exist so existing experience");
        console.log(this.index);
        this.experiences[this.index] = this.step1Form.value;
        this.experiences[this.index].address = this.fullAdress;
        body['experiences'] = this.experiences;
        if (this.experiences[this.index]['qualification']?.uid) {
          this.experiences[this.index]['qualification'] = this.experiences[this.index]['qualification']?.uid;
        }
        let qualification = this.qualificationList.filter((type: any) => {
          return type.uid === this.experiences[this.index!]['qualification'];
        }
        );
        if (this.experiences[this.index]['companyType']?.uid) {
          this.experiences[this.index]['companyType'] = this.experiences[this.index]['companyType']?.uid;
        }
        let companyType = this.companyTypeList.filter((type: any) => {
          return type.uid === this.experiences[this.index!]['companyType'];
        });
        body['experiences'][this.index]['qualification'] = qualification[0];
        body['experiences'][this.index]['companyType'] = companyType[0];
        body['experiences'][this.index]['address'] = this.experiences[this.index]['address'];

        console.log("C-EXPERIENCE validStep() experiences updated before dateFix = ");
        console.log(body);
        console.log(this.experiences);

        /* Fix component bug on some devices - wrong date format */
        let dateStart = body['experiences'][this.index]['dateStart'];
        if (dateStart?.slice(-6) == '-aN:aN') {
          dateStart = dateStart.substring(0, dateStart.length - 6) + "+02:00";
          body['experiences'][this.index]['dateStart'] = dateStart;
        } else if (dateStart?.slice(-1) == 'Z') {
          dateStart = dateStart.substring(0, dateStart.length - 1) + "+02:00";
          body['experiences'][this.index]['dateStart'] = dateStart;
        }
        let dateEnd = body['experiences'][this.index]['dateEnd'];
        if (dateEnd?.slice(-6) == '-aN:aN') {
          dateEnd = dateEnd.substring(0, dateEnd.length - 6) + "+02:00";
          body['experiences'][this.index]['dateEnd'] = dateEnd;
        } else if (dateEnd?.slice(-1) == 'Z') {
          dateEnd = dateEnd.substring(0, dateEnd.length - 1) + "+02:00";
          body['experiences'][this.index]['dateEnd'] = dateEnd;
        }
        /* End Fix */

        console.log("C-EXPERIENCE validStep() experiences updated after dateFix = ");
        console.log(this.experiences);

        console.log("C-EXPERIENCE validStep() experiences updated. uid exist so existing experience : update done");
        console.log(this.experiences[this.index]);
      } else {
        this.experiences.push(this.step1Form.value);
        this.experiences[this.experiences.length - 1].address = this.fullAdress;
        let index = this.experiences.length - 1;
        body['experiences'] = this.experiences;
        if (this.experiences[index]['qualification']?.uid) {
          this.experiences[index]['qualification'] = this.experiences[index]['qualification']?.uid;
        }
        let qualification = this.qualificationList.filter((type: any) => {
          return type.uid === this.experiences[index]['qualification'];
        }
        );
        if (this.experiences[index]['companyType']?.uid) {
          this.experiences[index]['companyType'] = this.experiences[index]['companyType']?.uid
        }
        let companyType = this.companyTypeList.filter((type: any) => {
          return type.uid === this.experiences[index]['companyType'];
        });
        body['experiences'][index]['qualification'] = qualification[0];
        body['experiences'][index]['companyType'] = companyType[0];
        body['experiences'][index]['address'] = this.experiences[index]['address'];

        /* Fix component bug on some devices - wrong date format */
        let dateStart = body['experiences'][index]['dateStart'];
        if (dateStart?.slice(-6) == '-aN:aN') {
          dateStart = dateStart.substring(0, dateStart.length - 6) + "+02:00";
          body['experiences'][index]['dateStart'] = dateStart;
        } else if (dateStart?.slice(-1) == 'Z') {
          dateStart = dateStart.substring(0, dateStart.length - 1) + "+02:00";
          body['experiences'][index]['dateStart'] = dateStart;
        }
        let dateEnd = body['experiences'][index]['dateEnd'];
        if (dateEnd?.slice(-6) == '-aN:aN') {
          dateEnd = dateEnd.substring(0, dateEnd.length - 6) + "+02:00";
          body['experiences'][index]['dateEnd'] = dateEnd;
        } else if (dateEnd?.slice(-1) == 'Z') {
          dateEnd = dateEnd.substring(0, dateEnd.length - 1) + "+02:00";
          body['experiences'][index]['dateEnd'] = dateEnd;
        }
      }
      /* End fix */
      console.log("C-EXPERIENCE validStep() experiences updated = ");
      console.log(this.experiences);


      console.log('C-EXPERIENCE validStep() body =');
      console.log(body);
      if (this.autoSaveChanges) {
        this.presentLoading();
        this.candidateService.putCandidate(undefined, body).subscribe((res: any) => {
          console.log("C-EXPERIENCE validStep() res =");
          console.log(res);
          this.candidate = res;
          this.dismiss();
          this.closeModal(true);
        });
      } else {
        console.log("C-EXPERIENCE validStep() no autoSaveChanges");
        this.candidate = body;
        this.closeModal(true);
      }
    }
  }

  removeExperience() {
    console.log("C-EXPERIENCE removeExperience() ");
    console.log(this.index);
    if (this.index == -1) {
      this.closeModal();
    } else {
      this.experiences.splice(this.index, 1);
      let body: any = {};
      body['experiences'] = this.experiences;
      console.log("C-EXPERIENCE removeExperience() experiences updated = ");
      console.log(this.experiences);
      console.log('C-EXPERIENCE removeExperience() body =');
      console.log(body);
      if (this.autoSaveChanges) {
        this.presentLoading();
        this.candidateService.putCandidate(undefined, body).subscribe((res: any) => {
          console.log("C-EXPERIENCE removeExperience() res =");
          console.log(res);
          this.candidate = res;
          this.dismiss();
          this.closeModal(true);
        });
      } else {
        console.log("C-EXPERIENCE validStep() no autoSaveChanges");
        this.candidate = body;
        this.closeModal(true);
      }


    }
  }


  async presentLoading() {
    this.isLoading = true;
    const loading = await this.loadingController.create({
      message: 'Sauvegarde en cours',
      duration: 8000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }
  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      console.log('dismissed');
    });
  }




}
