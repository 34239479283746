import { Injectable } from '@angular/core';
import { MaintenanceModalPage } from '../../pages/maintenance-modal/maintenance-modal.page';
import { ModalController } from '@ionic/angular';
import { SettingsService } from '../settings/settings.service';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { CandidateService } from '../candidate/candidate.service';
import { ProfilService } from '../profil/profil.service';

@Injectable({
  providedIn: 'root'
})
export class InfosLiveService {
  isMobile: boolean | null = null;
  userRolesObs = new BehaviorSubject<Array<string>>([]);
  userRoles: Array<string> = [];
  infosViewedIds: any = [];
  subscriptionList: Array<any> = [];
  subscribedToInfosLive: boolean = false;

  constructor(
    private modalController: ModalController,
    private settingsService: SettingsService,
    private storage: LocalStorageService,
    private candidateService: CandidateService,
    private profilService: ProfilService,
  ) {

    let mobileSubs = this.settingsService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.subscriptionList.push(mobileSubs);

    this.storage.get('infosViewedIds').then((infosViewedIds: any) => {
      console.log('INFOSLIVE_S constructor() infosViewedIds');
      console.log(infosViewedIds);
      if (infosViewedIds?.length > 0) {
        this.infosViewedIds = infosViewedIds;
      }
      let roleSubs = this.profilService.getUserRolesObs().pipe(filter(val => val !== null), distinctUntilChanged()).subscribe(roles => {
        console.log('INFOSLIVE_S initializeApp() roles =');
        console.log(roles);
        this.userRoles = roles;
        this.userRolesObs.next(roles);
      });
      this.subscriptionList.push(roleSubs);

    });
  }

  ngOnDestroy() {
    if (this.subscriptionList[0]) {
      this.subscriptionList.forEach(element => {
        element.unsubscribe();
      });
    }
  }

  subscribeToInfosLive() {
    console.log('INFOSLIVE_S subscribeToInfosLive() start');
    if (this.subscribedToInfosLive) {
      return;
    }
    this.subscribedToInfosLive = true;
    let rolesSubs = this.userRolesObs.pipe(filter(val => val != null),
      distinctUntilChanged()).subscribe(roles => {
        console.log('INFOSLIVE_S subscribeToInfosLive() userRoleObs');
        console.log(roles);
        let today = new Date();
        // let august2024 = new Date('2024-08-12'); 
        // if (today < august2024 && this.infosViewedIds.indexOf('1') == -1 && role == 'ROLE_CANDIDATE') {
        //   console.log('INFOSLIVE_S subscribeToInfosLive() openMaintenanceModal mobile');
        //   this.openInfosModal(undefined, 'info', false, { title: 'Nous améliorons notre système de zones de recherche !', subtitle: null, detail: "Grâce à vos retours, l’option « Mobile si logé » est désormais plus précise. Vous pouvez indiquer pour chaque zone géographique si elle vous intéresse en général ou seulement si un logement est fourni. L’option initiale « Mobile si logé sur toute la France métropolitaine et l’outre-mer » reste disponible.", image: 'https://storage.clubofficine.fr/api/storage/statics/b1f4a220-6907-4680-ada6-69af31786990', pageImgHeight: '200', link: 'https://www.youtube.com/watch?v=GwrsVATPXCY', openPageUrl: 'candidat/c-profil/searchAddresses', skipButtonName: 'Ignorer', openPageUrlButtonName: 'Mettre à jour', id: '1' });
        // }
        if (roles?.includes('ROLE_CANDIDATE')) {
          console.log('INFOSLIVE_S subscribeToInfosLive() openMaintenanceModal new dispo/indispo bar');
          this.candidateService.getCandidate().pipe(filter(val => val != null),
            distinctUntilChanged()).subscribe(candidate => {
              console.log('INFOSLIVE_S subscribeToInfosLive() openMaintenanceModal new dispo/indispo bar candidate');
              console.log(candidate);
              let november2024 = new Date('2024-11-06');
              let january2025 = new Date('2025-01-01');
              if (!candidate?.lastActivity || new Date(candidate.createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000)) {
                return;
              }
              if (this.infosViewedIds.indexOf('2') === -1 && today < january2025) {
                console.log('INFOSLIVE_S subscribeToInfosLive() openMaintenanceModal new dispo/indispo bar candidate lastActivity');
                this.openInfosModal(undefined, 'info', false, {
                  title: 'Indiquez vos indisponibilités (contrats en cours, engagements, vacances).',
                  subtitle: null,
                  detail: `
                    <div>
                        <p><strong>DISPONIBLE</strong> : Recherche d'emploi / remplacements /stage.</p>
                        <p><strong>EN POSTE, À L'ÉCOUTE</strong> : En contrat mais ouvert (prêt à quitter pour mieux).</p>
                        <p><strong>INDISPONIBLE</strong> : Pas de sollicitation ni candidature.</p>
                    </div>
                `,
                  image: 'https://clubofficine-static-production.s3.eu-west-3.amazonaws.com/nouveaux-status.png',
                  pageImgHeight: '200',
                  link: undefined,
                  openPageUrl: 'candidat/c-profil',
                  skipButtonName: 'Ignorer',
                  openPageUrlButtonName: 'Mettre à jour',
                  id: '2'
                });
              }

            });
        }

      });
    this.subscriptionList.push(rolesSubs);
  }




  async openInfosModal(detail: any, type: string | null = null, canDismiss: boolean = false, info: any = null) {
    console.log('INFOSLIVE_S openMaintenanceModal()');
    let breakpoints: Array<number> | undefined;
    let initialBreakpoint: number | undefined;
    if (this.isMobile) {
      breakpoints = [0, 0.35, 0.5, 0.8, 1];
      initialBreakpoint = 0.8;
    }
    let dismissOptions: any = (data?: any, role?: string) => {
      return new Promise<boolean>(resolve => resolve(role === "close"))
    };
    if (canDismiss === true) {
      dismissOptions = true;
    }
    const modal = await this.modalController.create({
      component: MaintenanceModalPage,
      canDismiss: dismissOptions,
      backdropDismiss: true,
      componentProps: {
        // "detail": detail,
        "type": type,
        "info": info,
        "initialBreakpoint": initialBreakpoint,
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      console.log('INFOSLIVE_S openMaintenanceModal() onDidDismiss');
      console.log(dataReturned);
      if (dataReturned?.data?.infosViewedId) {
        console.log('INFOSLIVE_S openMaintenanceModal() onDidDismiss res retourned');
        this.infosViewedIds.push(dataReturned.data.infosViewedId);
        this.storage.set('infosViewedIds', this.infosViewedIds);
      }
    });
    return await modal.present();
  }


}

