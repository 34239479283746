import { Component, OnInit } from '@angular/core';
import { DataLayerService } from 'src/app/services/data-layer/data-layer.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  consentGiven: string = 'pending';

  constructor(
    private dataLayerService: DataLayerService,
    public toastController: ToastController) {

  }

  ngOnInit(): void {
    console.log("CookiesComponent ngOnInit() start");
    console.log("CookiesComponent ngOnInit() this.consentGiven: " + this.consentGiven);
    this.dataLayerService.getCookiesConsent().subscribe((consent) => {
      this.consentGiven = consent;
      console.log("CookiesComponent ngOnInit() this.consentGiven after Obs : " + this.consentGiven);
    });
  }

  setConsent(consent: string): void {
    this.dataLayerService.setCookiesConsent(consent);
    this.consentGiven = consent;
    this.presentToast('Consentement enregistré');
  }

  async presentToast(message: string = '') {
    let cssClass = 'cssToast';
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      //    cssClass: cssClass,
      color: 'secondary'
    });
    toast.present();
  }

}
