import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { map, tap, catchError, take, switchMap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../../services/profil/profil.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SettingsService } from '../settings/settings.service';
import { Browser } from '@capacitor/browser';
import { WindowService } from 'src/app/services/window/window.service';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class RecruiterService {
  userUid: string | null = null;
  recruitersList: any = [];
  myRecruiterProfilObs: BehaviorSubject<any> = new BehaviorSubject(null);
  isPremium: boolean = false;
  myRecruiterProfilSubscribtion: Subscription | undefined;
  isMerging: boolean = false;
  environment: any = environment;

  private userFields = [
    'firstname', 'lastname', 'maidenName', 'email', 'birthdate',
    'phone', 'personalAddress', 'projectListening', 'rpps', 'sponsorCode', 'isAnonymous'
  ];

  private filesValidationFields = ['kbisFile'];

  constructor(private storage: LocalStorageService,
    private profilService: ProfilService,
    private alertController: AlertController,
    private backApiService: BackApiService,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private envService: EnvService
  ) {
    this.environment = this.envService.getEnvironmentVariables();
    this.profilService.getuserUidObs().subscribe((res: any) => {
      this.userUid = res;
    });
    /** Update profil after paiement success */
    this.isPremium = this.profilService.getIsPremium();
    this.profilService.getIsPremiumObs().subscribe(isPremium => {
      if (!this.isPremium && isPremium) {
        this.initMyRecruiterProfil();
      }
    });
    console.log('RECRUITERSERVICE constructor()');
    this.restoreStoredrecruiter();
    this.getLogout();
  }

  /**
 * Helper function to separate user, recruiter and file validation fields
 * @param {any} data Complete data object to separate
 * @returns {Object} Separated user, recruiter and files data
 */
  private separateUserRecruiterAndFileData(data: any): {
    userUpdate: any,
    recruiterUpdate: any,
    filesUpdate: Array<any>
  } {
    const userUpdate: any = {};
    const recruiterUpdate: any = {};
    const filesUpdate: Array<any> = [];

    Object.entries(data).forEach(([key, value]) => {
      console.log('RECRUITERSERVICE separateUserRecruiterAndFileData()');
      console.log(key);
      console.log(value);

      if (this.filesValidationFields.includes(key)) {
        console.log('RECRUITERSERVICE separateUserRecruiterAndFileData() is file');
        filesUpdate.push(value);
      } else if (this.userFields.includes(key)) {
        userUpdate[key] = value;
      } else {
        recruiterUpdate[key] = value;
      }
    });

    return { userUpdate, recruiterUpdate, filesUpdate };
  }

  /**
* Handles file updates for the recruiter
* @param userUid User ID
* @param filesUpdate Array of files to update
* @returns Observable of file update results
*/
  private handleFileUpdates(userUid: string, filesUpdate: Array<any>): Observable<any[]> {
    console.log('RECRUITERSERVICE handleFileUpdates()');
    console.log(filesUpdate);
    console.log(userUid);

    if (filesUpdate.length === 0) {
      return of([]);
    }

    const fileUploadObservables = filesUpdate.map(file => {
      console.log('RECRUITERSERVICE handleFileUpdates() file =');
      console.log(file);
      console.log(file?.uid);
      console.log(file?.validationName);
      console.log(file?.analyseDocument
      );
      console.log(userUid);
      if (file?.id) {
        return this.profilService.patchFile(userUid, file?.filesUuid?.[0], file?.validationName, file?.analyseDocument, file.id).pipe(
          catchError(error => {
            console.error(`Error uploading ${file.field}:`, error);
            this.showAlert(`Impossible de mettre à jour le fichier ${file.field}. Vérifiez votre connexion ou réessayez plus tard.`);
            throw error;
          })
        );
      } else {
        return this.profilService.postFile(userUid, file?.filesUuid?.[0], file?.validationName, file?.analyseDocument).pipe(
          catchError(error => {
            console.error(`Error uploading ${file.field}:`, error);
            this.showAlert(`Impossible de mettre à jour le fichier ${file.field}. Vérifiez votre connexion ou réessayez plus tard.`);
            throw error;
          })
        );
      }
    });

    return forkJoin(fileUploadObservables);
  }

  /**
   * PATCH recruiter data to Recruiter API
   * @param {string} uid User ID
   * @param {any} recruiterData Recruiter data to update
   * @returns {Observable<any>} Recruiter API response
   */
  private patchRecruiterData(uid: string, recruiterData: any): Observable<any> {
    if (Object.keys(recruiterData).length === 0) {
      return of(null);
    }
    return this.backApiService.patchData(
      `${this.environment.recruiter}/${uid}`,
      recruiterData,
      true,
      false
    ).pipe(tap((recruiter: any) => {
      console.log('Recruiter data updated:', recruiter);
      this.setMyRecruiterProfilObs(recruiter);
      return recruiter;
    }),
      catchError(error => {
        console.error('Error updating recruiter data:', error);
        this.showAlert("Impossible de mettre à jour les informations du profil recruteur. Vérifiez votre connexion ou réessayez plus tard.");
        throw error;
      })
    );
  }


  /**
   * Updates the recruiter profile with separated user, recruiter, and file data
   * @param uid User ID
   * @param recruiter Recruiter data to update
   * @returns Observable of the update operation
  */
  /**
   * Updates the recruiter profile with separated user, recruiter, and file data
   * @param uid User ID
   * @param recruiter Recruiter data to update
   * @returns Observable of the update operation with structured response
   */
  patchRecruiterUserAndFiles(uid: string | null = this.userUid, recruiter: any): Observable<any> {
    console.log('RECRUITERSERVICE patchRecruiterUserAndFiles()');
    console.log(uid);

    if (!uid) {
      return throwError(() => new Error('No UID provided'));
    }

    const { userUpdate, recruiterUpdate, filesUpdate } = this.separateUserRecruiterAndFileData(recruiter);

    // Start with an empty observable chain
    let updateChain: Observable<any> = of({
      files: null,
      user: null,
      recruiter: null
    });

    // Step 1: Handle file updates first if any exist
    if (filesUpdate.length > 0) {
      updateChain = updateChain.pipe(
        switchMap((result) => {
          return this.handleFileUpdates(uid, filesUpdate).pipe(
            map(fileResults => {
              // Combine file results into a single object
              let filesObj: any = {};
              if (Array.isArray(fileResults)) {
                fileResults.forEach((fileResult: any) => {
                  if (fileResult) {
                    const field = Object.keys(fileResult)[0];
                    filesObj[field] = fileResult[field];
                  }
                });
              }
              return {
                ...result,
                files: Object.keys(filesObj).length > 0 ? filesObj : null
              };
            })
          );
        })
      );
    }

    // Step 2: Then handle user updates if any exist
    if (Object.keys(userUpdate).length > 0) {
      updateChain = updateChain.pipe(
        switchMap((result) => {
          return this.profilService.patchUser(uid, userUpdate).pipe(
            map(userResult => ({
              ...result,
              user: userResult || null
            }))
          );
        })
      );
    }

    // Step 3: Finally handle recruiter updates if any exist
    if (Object.keys(recruiterUpdate).length > 0) {
      updateChain = updateChain.pipe(
        switchMap((result) => {
          return this.patchRecruiterData(uid, recruiterUpdate).pipe(
            map(recruiterResult => ({
              ...result,
              recruiter: recruiterResult || null
            }))
          );
        })
      );
    }

    // Process the final results
    return updateChain.pipe(
      map(result => {
        // Également mettre à jour les données locales avec les résultats combinés
        let combinedData = {};

        // Combiner tous les résultats pour mise à jour locale
        if (result.files) {
          combinedData = { ...combinedData, ...result.files };
        }
        if (result.user) {
          combinedData = { ...combinedData, ...result.user };
        }
        if (result.recruiter) {
          combinedData = { ...combinedData, ...result.recruiter };
        }
        if (!result.recruiter && (result.user || result.filesUpdate)) {
          this.initMyRecruiterProfil();
        }


        return result;
      }),
      catchError(error => {
        console.error('RECRUITERSERVICE patchRecruiterUserAndFiles() error:', error);
        this.showAlert("Une erreur est survenue lors de la mise à jour du profil. Veuillez réessayer plus tard.");
        throw error;
      })
    );
  }


  /**
  * Get detail of my recruiter profil
  * @param {string} uid the recruiter uid to request
  * @return {BehaviorSubject} searchObs recruiter
  */
  initMyRecruiterProfil(uid: string | null = this.userUid) {
    console.log('RECRUITERSERVICE initMyRecruiterProfil()');
    console.log(`${this.environment.recruiter}/${uid}`);
    if (this.myRecruiterProfilSubscribtion) {
      this.myRecruiterProfilSubscribtion.unsubscribe();
    }
    this.myRecruiterProfilSubscribtion = this.backApiService.getData(`${this.environment.recruiter}/${uid}`, true).pipe(take(1)).subscribe((res: any) => {
      console.log('RECRUITERSERVICE initMyRecruiterProfil() res retourned =');
      console.log(res);
      if (res) {
        this.setMyRecruiterProfilObs(res);
      }
    }, error => {
      console.log("RECRUITERSERVICE initMyRecruiterProfil() res returned error");
      if (error.status == 404) {
        this.postRecruiter().pipe(take(1)).subscribe(newRecruiter => {
          console.log('RECRUITERSERVICE initMyRecruiterProfil() 404 error -> recruiter created');
        });
      } else {
        this.showAlert("Impossible de récuperer les information sur votre profil. Verifiez votre connexion ou réessayez plus tard");
      }
    });
  }

  getMyRecruiterProfilObs() {
    return this.myRecruiterProfilObs.asObservable();
  }

  setMyRecruiterProfilObs(recruiter: any) {
    this.myRecruiterProfilObs.next(recruiter);
  }



  /**
   * Retrieves the logout status and performs necessary actions.
   */
  getLogout() {
    this.profilService.getLogOutObs().subscribe(logOut => {
      if (logOut === true) {
        this.setMyRecruiterProfilObs(null);
      }
    });
  }


  /**
   * Get detail of a recruiter
   * @param {string} uid the recruiter uid to request
   * @return {Observable<any>} recruiter observable
   */
  getRecruiter(uid: string | null = null): Observable<any> {
    console.log('RECRUITERSERVICE getRecruiter()');
    console.log(uid);

    let existingRecruiter: any = null;
    if (uid) {
      existingRecruiter = this.isExistingRecruiter(uid);
    } else if (this.userUid) {
      uid = this.userUid;
    } else {
      this.showAlert("Impossible de récupérer les informations du recruteur. Veuillez vous connecter ou contacter le support.");
      return throwError(() => new Error('Impossible de récupérer les informations du recruteur. Veuillez vous connecter ou contacter le support.'));
    }

    return new Observable<any>((observer) => {
      let useCache = false;

      if (existingRecruiter && existingRecruiter.cachedAt && uid !== this.userUid) {
        const cacheAge = (new Date().getTime() - existingRecruiter.cachedAt.getTime()) / 1000 / 60; // Age en minutes
        if (cacheAge < 10) {
          console.log('RECRUITERSERVICE getRecruiter() using cached data');
          observer.next(existingRecruiter);
          observer.complete();
          useCache = true;
        }
      }

      if (!useCache) {
        console.log('RECRUITERSERVICE getRecruiter() fetching recruiter from API');
        this.backApiService.getData(`${this.environment.recruiter}/${uid}`, true).pipe(
          take(1),
          catchError(e => {
            console.log("RECRUITERSERVICE getRecruiter() API call returned error");
            console.log(e);
            //  this.showAlert("Impossible de récupérer les informations du recruteur. Vérifiez votre connexion ou réessayez plus tard.");
            observer.error(e);
            return throwError(e);
          })
        ).subscribe(
          (res: any) => {
            console.log('RECRUITERSERVICE getRecruiter() res returned from API:');
            console.log(res);
            if (res?.uid) {
              res.cachedAt = new Date();
              if (existingRecruiter) {
                // Mettre à jour le recruteur existant dans le cache
                const index = existingRecruiter.recruitersListIndex;
                this.recruitersList[index] = res;
              } else {
                // Ajouter un nouveau recruteur dans le cache
                res.recruitersListIndex = this.recruitersList.length;
                this.recruitersList.push(res);
              }
              this.storeRecruiters();
            }
            // Émettre les nouvelles données
            observer.next(res);
            observer.complete();
          }
        );
      }
    });
  }




  /**
* POST detail of a recruiter throw the API
* @return {BehaviorSubject} searchObs recruiter
*/
  postRecruiter() {
    console.log('RECRUITERSERVICE postRecruiter()');
    console.log('RECRUITERSERVICE postRecruiter() requette =');
    console.log(`${this.environment.recruiter}`);
    let recruiter: any = {};
    if (this.settingsService.getFromCampaign()) {
      recruiter = { fromCampaign: this.settingsService.getFromCampaign() };
    }
    return this.backApiService.postData(`${this.environment.recruiter}`, recruiter, true, false).pipe(
      map((res: any) => {
        console.log('RECRUITERSERVICE postRecruiter() res retourned =');
        console.log(res);
        this.setMyRecruiterProfilObs(res);
        return res;
      }),
      catchError(e => {
        console.log("RECRUITERSERVICE postRecruiter() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur le profil. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
   * Checks if a recruiter with the given UID exists in the recruiters list.
   * 
   * @param uid - The UID of the recruiter to check.
   * @returns The recruiter object if found, otherwise false.
   */
  isExistingRecruiter(uid: string) {
    if (this.recruitersList && this.recruitersList[0]) {
      for (let i = 0; i < this.recruitersList.length; i++) {
        if (uid == this.recruitersList[i].uid && this.recruitersList[i].firstname) {
          this.recruitersList[i].recruitersListIndex = i;
          return this.recruitersList[i];
          break;
        }
      }
      return false;
    }
  }

  /**
   * store recruiter data
   * @return {object} recruiter  - full profile  
   */
  storeRecruiters() {
    console.log('RECRUITERSERVICE storereRecruiter  =');
    console.log(this.recruitersList);
    this.storage.set('recruitersList', this.recruitersList);
  }

  /**
   * Return stored recruiter data
   */
  restoreStoredrecruiter() {
    console.log('RECRUITERSERVICE restoreStoredrecruiter()');
    this.storage.get('recruitersList').then((recruitersList: any) => {
      console.log('RECRUITERSERVICE restoreStoredrecruiter() recruiter = ');
      console.log(this.recruitersList);
      if (recruitersList) {
        this.recruitersList = recruitersList;
      }
      else {
        console.log('RECRUITERSERVICE restoreStoredrecruiter() no recruiter stored');
      }
    });
  }


  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: [{
        text: 'Support',
        handler: () => {
          this.openSupport();
          return true;
        }
      },
      {
        text: 'Ignorer',
        role: 'cancel'
      }
      ]
    });
    alert.then(alert => alert.present());
  }




  /**
  * reSend validation email
  */
  resendValidationEmail() {
    console.log('RECRUITERSERVICE resendValidationEmail()');
    console.log(this.environment.resendValidationEmailRecruiter);
    return this.backApiService.getData(`${this.environment.resendValidationEmailRecruiter}`, true).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE resendValidationEmail() res retourned =');
        return;
      }),
      catchError(e => {
        console.log("RECRUITERSERVICE resendValidationEmail() res returned error");
        this.showAlert("Impossible de renvoyer l'email, veuillez contacter le support");
        throw e;
      }));
  }

  /**
   * Checks for duplicates in the recruiter data.
   * 
   * @returns An Observable that emits the response from the server.
   * @throws Throws an error if there is an error while checking for duplicates.
   */
  checkDuplicates() {
    console.log('RECRUITERSERVICE checkDuplicates()');
    return this.backApiService.getData(`${this.environment.recruiterDuplicatesCheck}`, true).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE checkDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE checkDuplicates() error=');
        console.log(e);
        throw e;
      }));
  }

  /**
   * Merges duplicate recruiters.
   *
   * @param token - The token used for authentication.
   * @returns An observable that emits the response from the server.
   * @throws An error if the merging process fails.
   */
  mergeRecruiterDuplicates(token: string | null) {
    console.log('RECRUITERSERVICE mergeDuplicates()');
    this.isMerging = true;
    return this.backApiService.postData(`${this.environment.mergeRecruiterDuplicates}`, { 'token': token }).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE mergeDuplicates() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE mergeDuplicates() error=');
        console.log(e);
        this.showAlert("Impossible de fusionner, veuillez contacter le support");
        throw e;
      }));
  }

  /**
   * Sends a merge token to a candidate for merging their account.
   * 
   * @param candidateToMergeUid The UID of the candidate to merge.
   * @param updateEmail Indicates whether to update the email during the merge process. Default is false.
   * @returns An Observable that emits the response from the API call.
   * @throws Throws an error if there is an error during the API call.
   */
  sendMergeToken(candidateToMergeUid: string, updateEmail: boolean = false) {
    console.log('RECRUITERSERVICE sendMergeToken()');
    return this.backApiService.postData(`${this.environment.sendMergeTokenRecruiter}`, { 'targetUuid': candidateToMergeUid, 'updateEmail': updateEmail }).pipe(
      tap((res: any) => {
        console.log('RECRUITERSERVICE sendMergeToken() res=');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log('RECRUITERSERVICE sendMergeToken() error=');
        console.log(e);
        throw e;
      }));
  }

  /**
   * Opens the support page in a browser window.
   * 
   * @param url The URL of the support page. Default is 'https://assistance.clubofficine.fr/hc/fr'.
   * @param browserOption The browser option to use. Default is '_system'.
   */
  openSupport(url = 'https://assistance.clubofficine.fr/hc/fr', browserOption = '_system') {
    if (this.windowService.isPlatformServer()) {
      return;
    }
    console.log("C-OFFER openSupport()");
    Browser.open({ url: url, windowName: browserOption });
  }

  /**
   * Retrieves the value of the isMerging property to know if a merge is in progress.
   * 
   * @returns {boolean} The value of the isMerging property.
   */
  getIsMerging() {
    return this.isMerging;
  }

}
