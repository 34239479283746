import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlgoliaService } from '../../services/algolia/algolia.service';
import { take } from 'rxjs';


@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss'],
})
export class SearchAddressComponent implements OnInit {
  @Input() searchAddresses: Array<any> = [];
  @Input() associatesWishes: any = null;
  @Input() candidate: any = null;
  @Input() nbMaxAddress: number = 15;
  @Input() displayRadius: any = true;
  @Input() addressType: string = "city";
  @Input() placeholder: string = "Ville";
  @Input() customSearchBarStyle: any = {};
  @Input() content: any = null;
  @Input() displayMobileIfHoused: boolean = false;
  @Input() displayGeolocationButton: boolean = false;
  @Input() disabled: boolean = false;
  @Output() updateSearchAddresses = new EventEmitter<any>();
  @Output() scrollDown = new EventEmitter<any>();
  zoneOrVilleText: string = 'zone';
  adressesList: any = [];
  searchBarFocusState: string | null = null;
  //zones: any = [{ localisation: '', radius: 20, adressesList: [], selectedAdress: false }];

  constructor(
    private algoliaService: AlgoliaService,
  ) { }

  ngOnInit() {
    console.log('SEARCHADDRESS_C ngOnInit()');
    console.log(JSON.parse(JSON.stringify(this.searchAddresses)));
    console.log(this.associatesWishes);
    console.log(this.nbMaxAddress);
    if (this.searchAddresses[0] === '' || this.searchAddresses[0] === null) { // For adding exeprience of candidates
      this.searchAddresses = [];
    }
    if (!this.searchAddresses?.[0]) {
      console.log('SEARCHADDRESS_C ngOnInit() addZone()');
      this.addZone(false);
    } else if (this.searchAddresses?.length >= 1) {
      this.formatSearchAdresses(this.searchAddresses);
    }
    if (this.addressType == 'city') {
      this.zoneOrVilleText = 'ville';
    }
  }

  // formatSearchAdresses(addresses: Array<any>) {
  //   console.log('SEARCHADDRESS_C formatSearchAdresses()');
  //   console.log(addresses);
  //   addresses.forEach((res: any) => {
  //     if (res.radius > 1000) {
  //       res.radius = res.radius / 1000;
  //     }
  //     res.adressesList = [];
  //     if (this.addressType == "city" && res?.address?.city && res?.address?.zipcode) {
  //       res.localisation = res.address.city + ', ' + res.address.zipcode;
  //     }
  //     else if (this.addressType == "address" && res.address?.street && res.address?.number && res.address?.zipcode && res.address?.city) {
  //       res.localisation = res.address.number + ' ' + res.address.street + ', ' + res.address.zipcode + ' ' + res.address.city;
  //     } else if (this.addressType == "address" && res.address?.street && res.address?.zipcode && res.address?.city) {
  //       res.localisation = res.address.street + ', ' + res.address.zipcode + ' ' + res.address.city;
  //     } else if (this.addressType == "address" && res.address?.zipcode && res.address?.city) {
  //       res.localisation = res.address.zipcode + ' ' + res.address.city;
  //     } else {
  //       res.localisation = '';
  //     }


  //     res.selectedAdress = true;
  //     res.focusState = 'blur';
  //   });
  // }

  formatSearchAdresses(addresses: Array<any>) {
    console.log('SEARCHADDRESS_C formatSearchAdresses()');
    console.log(addresses);
    addresses.forEach((res: any) => {
      if (res.radius > 1000) {
        res.radius = res.radius / 1000;
      }
      res.adressesList = [];

      res.localisation = '';

      const address = res.address || {};
      const number = address.number;
      const street = address.street;
      const zipcode = address.zipcode;
      const city = address.city;

      // Vérifier si le type d'adresse est 'address' ou 'addressOrCity'
      if (this.addressType === 'address' || this.addressType === 'addressOrCity') {
        if (number && street && zipcode && city) {
          res.localisation = `${number} ${street}, ${zipcode} ${city}`;
        } else if (street && zipcode && city) {
          res.localisation = `${street}, ${zipcode} ${city}`;
        } else if (zipcode && city) {
          res.localisation = `${zipcode} ${city}`;
        }
      }

      // Vérifier si le type d'adresse est 'city' ou 'addressOrCity' et que 'res.localisation' est toujours vide
      if ((this.addressType === 'city' || this.addressType === 'addressOrCity') && res.localisation === '') {
        if (city && zipcode) {
          res.localisation = `${city}, ${zipcode}`;
        } else if (city) {
          res.localisation = city;
        }
      }

      // Si 'res.localisation' est toujours vide, la définir comme une chaîne vide
      res.localisation = res.localisation || '';

      res.selectedAdress = true;
      res.focusState = 'blur';
    });
  }


  addZone(autoscroll: boolean = true) {
    if (this.searchAddresses?.length < this.nbMaxAddress) {
      this.searchAddresses.push({ address: {}, localisation: '', radius: 20, adressesList: [], selectedAdress: false });
      setTimeout(() => {
        if (autoscroll && this.content) {
          this.content.scrollByPoint(0, 150, 300);
        }
      }, 100);
    }
    this.scrollDown.emit(180);
    console.log(this.searchAddresses);
  }

  removeZone(index: number) {
    if (this.searchAddresses.length > 1) {
      this.searchAddresses.splice(index, 1);
    } else {
      this.searchAddresses[0].localisation = '';
    }
  }

  gotFocus(zone: { focusState: string; }) {
    zone.focusState = 'focus';
    this.searchBarFocusState = 'focus';
  }

  lostFocus(zone: { focusState: string; }) {
    zone.focusState = 'blur';
    this.searchBarFocusState = 'blur';
  }

  loadAssociatesZonesFromCandidate() {
    console.log('SEARCHADDRESS_C loadAssociatesZonesFromCandidate()');
    console.log(this.associatesWishes);
    console.log(this.searchAddresses);
    this.searchAddresses = JSON.parse(JSON.stringify(this.candidate.wishes.searchAddresses));
    // if this.searchAddresses country == "worldwide" then remove this zone
    this.searchAddresses = this.searchAddresses.filter((zone: any) => zone.address.country != "worldwide");
    this.searchAddresses.forEach((res: any) => {
      if (res.radius > 1000) {
        res.radius = res.radius / 1000;
      }
      res.adressesList = [];
      res.localisation = res.address.city + ', ' + res.address.zipcode;
      res.selectedAdress = true;
      res.focusState = 'blur';
    });
    this.updateSearchAddresses.emit(this.searchAddresses);
  }


  /** 
  * Clear Algolia result items
  **/
  clearAdressesList() {
    this.adressesList = [];
  }

  /**
  * Request Algolia API to get city
  **/
  updateCity(zone: { localisation: string | any[]; focusState: string; adressesList: boolean; }) {
    console.log("SEARCHADDRESS_C updateCity()=");
    if (zone?.localisation.length > 1 && zone.focusState == 'focus') {
      let getAddressOrCity: any = null;
      if (this.addressType == "city") {
        getAddressOrCity = this.algoliaService.getCity(zone.localisation as string);
      } else if (this.addressType == "address") {
        getAddressOrCity = this.algoliaService.getAdress(zone.localisation as string);
      } else if (this.addressType == "addressOrCity") {
        getAddressOrCity = this.algoliaService.getAddressOrCity(zone.localisation as string);
      }
      getAddressOrCity.subscribe((res: any) => {
        console.log("SEARCHADDRESS_C updateCity() res =");
        console.log(res);
        if (res[0]) {
          zone.adressesList = res;
          console.log('SEARCHADDRESS_C updateCity() list =');
          console.log(zone.adressesList);
        }
      });
    } else if (zone.adressesList) {
      zone.adressesList = false;
    } else if (zone.localisation.length == 0) {
      zone.adressesList = false;
    }
    if (zone.focusState != 'focus') {
      zone.focusState = 'focus';
    }
  }

  /** 
  * Fired when item city or addresse is selected
  **/
  selectCity(zone: { address: any; localisation: any; adressesList: null[]; selectedAdress: boolean; rayon: any }, address: any) {
    console.log('SEARCHADDRESS_C selectCity()');
    console.log(zone);
    console.log(address);
    zone.address = address;
    zone.localisation = address.Text;
    zone.adressesList = [null];
    zone.selectedAdress = true;
    this.algoliaService.getCoordonateFromPlaceId(address.PlaceId).pipe(take(1)).subscribe((res: any) => {
      zone.address = res;
      if (zone?.rayon) {
        zone.address.radius = (zone.rayon * 1000).toString();
      }
      console.log('SEARCHADDRESS_C selectCity() res =');
      console.log(res);
      if (!zone.address.zipcode) {
        this.algoliaService.getZipCodeFromPlacetext(address.Text).subscribe((res2: any) => {
          zone.address.zipcode = res2;
          zone.address.postcode = res2;
          this.updateSearchAddresses.emit(this.searchAddresses);
          console.log('SEARCHADDRESS_C selectCity() seleted');
          console.log(this.searchAddresses);
          console.log(zone);
        });
      } else {
        this.updateSearchAddresses.emit(this.searchAddresses);
      }
    });
  }



  geolocalise(zone: any) {
    console.log('SEARCHADDRESS_C Geolocalise');
    this.algoliaService.getCurrentLatLng().pipe(take(1)).subscribe((res: any) => {
      this.searchAddresses[0].address = { '_geoloc': { lat: res.lat, lng: res.lng }, latitude: res.lat, longitude: res.lng }
      this.searchAddresses[0].radius = (zone.rayon * 1000).toString();
      this.searchAddresses[0].localisation = 'Votre position';
      this.searchAddresses[0].adressesList = [null];
      this.searchAddresses[0].selectedAdress = true;
      this.updateSearchAddresses.emit(this.searchAddresses);
    });
  }



}
