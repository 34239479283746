import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor(
    public alertController: AlertController,
  ) { }


  /**
   * Displays an error message based on the provided error object.
   * 
   * @param error - The error object to display.
   */
  displayError(error: any) {
    console.log("ERROR_S displayError() error");
    console.log(error);
    // Gestion spécifiques à exclure
    if (error?.error?.code?.includes('AUT009')) { //  && error?.error?.message == 'Votre email est utilisé par un autre compte'
      return;
    }
    let title = "Erreur";
    let message = "Une erreur est survenue";
    if (error?.error?.code && error?.error?.message) {
      message = error.error.message + " (" + error.error.code + ")";
      title = "Erreur";
    }
    this.presentAlert(title, message);
  }



  /**
   * Presents an alert with the given title and message.
   * 
   * @param title - The title of the alert.
   * @param message - The message of the alert.
   * @returns A promise that resolves when the alert is presented.
   */
  async presentAlert(title: string, message: string) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      cssClass: 'error-alert',
      buttons: ['OK']
    });

    await alert.present();
  }


}
