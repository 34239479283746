import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private router: Router,
    private meta: Meta,
    private metaTitle: Title,
    @Inject(DOCUMENT) private doc: any
  ) {
    // this.initTaging();
  }

  /**
   * Sets the page tags for SEO.
   * 
   * @param metaTitle - The meta title for the page.
   * @param metaDescription - The meta description for the page.
   */
  setpageTags(metaTitle: string, metaDescription: string) {
    this.metaTitle.setTitle(metaTitle);
    this.meta.updateTag(
      {
        name: 'description',
        content: metaDescription,
      }
    );
    if (environment.production === true) {
      this.meta.updateTag(
        {
          name: 'robots',
          content: 'index, follow'
        }
      );
    } else {
      this.meta.updateTag(
        {
          name: 'robots',
          content: 'noindex, nofollow'
        }
      );
    }
  }


  /**
   * Sets the canonical URL for the page.
   * 
   * @param url - The URL to set as the canonical URL. If not provided, the current document URL will be used.
   */
  setCanonical(url: string = this.doc.URL) {
    if (url.includes('http://')) {
      url = url.replace('http://', 'https://');
    }
    if (!url.includes('https://www.')) {
      url = url.replace('https://', 'http://www.');
    }
    if (!this.doc.getElementById('cboCanonical')) {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('id', 'cboCanonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', url);
    } else {
      let link = this.doc.getElementById('cboCanonical');
      link.setAttribute('href', url);
    }
  }


  /**
   * Sets the microformats for a job offer.
   * 
   * @param offer - The job offer object.
   * @param title - The title of the job offer.
   * @param description - The description of the job offer.
   */
  setMicroFormats(offer: any, title: string, description: string | null) {
    let text: any = {
      "@context": "http://schema.org",
      "@type": "Jobposting",
      "datePosted": new Date(offer.createdAt),
      "validThrough": new Date(new Date().setDate(new Date(offer.createdAt).getDate() + 180)),
      //    "industry": offer.companyCategory.label,
      "identifier": { "@type": "PropertyValue", "name": "ClubOfficine", "value": offer.id.toString() },
      // "workHours": offer.contract.weeklyWorkingTimeDuration,
      "title": title,
      "description": description,
      "hiringOrganization":
      {
        "@type": "Organization",
        "name": "ClubOfficine",
        "logo": "https://www.clubofficine.fr/blog/wp-content/uploads/2017/12/LOGO-CLUB-OFFICINE-CARRE.png"
      },
      "jobLocation":
      {
        "@type": "Place",
        "address":
        {
          "@type": "PostalAddress",
          "addressCountry": 'FR',
          "addressLocality": offer.address.city,
          "postalCode": offer.address.zipcode,
          "addressRegion": offer.address.locality
        }
      },
      "baseSalary":
      {
        "@type": "MonetaryAmount",
        "currency": "EUR",
        "value": {
          "@type": "QuantitativeValue",
          "unitText": "MONTH",
          "minValue": offer.contract.remuneration.anualAmountMin,
          "maxValue": offer.contract.remuneration.anualAmountMax
        }
      },
      "educationRequirements": {
        "@type": "EducationalOccupationalCredential",
        "credentialCategory": "high school"
      },
      "occupationalCategory": "Santé",
      "salaryCurrency": "EUR",
      "url": environment.websiteUrl + this.router.url + '?utm_source=google-jobs',
    };
    console.log('MICROFORMAT');
    console.log(text);
    if (offer?.requirements?.minimumExperience != 'less-than-1') {
      let month = 12;
      switch (offer.requirements.minimumExperience) {
        case '1-to-3':
          month = 12;
          break;
        case '3-to-5':
          month = 36;
          break;
        case '5-to-10':
          month = 60;
          break;
        case 'more-than-10':
          month = 120;
          break;
      }
      text.experienceRequirements = {
        "@type": "OccupationalExperienceRequirements",
        "monthsOfExperience": month
      }
    } else {
      text.experienceRequirements = "no requirements";
    }
    text.employmentType = 'FULL_TIME';
    if (offer.contract.type == "cdd") {
      text.employmentType = 'PART_TIME';
    } else if (offer.contract.type == "formation") {
      text.employmentType = 'INTERN';
    }
    if (this.doc.getElementById('microformat')) {
      this.doc.getElementById('microformat').remove();
    }
    let script: HTMLScriptElement = this.doc.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.innerHTML = JSON.stringify(text);
    script.setAttribute('id', 'microformat');
    this.doc.head.appendChild(script);
  }

  /**
   * Sets the Open Graph (OG) meta tags for SEO purposes.
   * 
   * @param url - The URL to set as the 'og:url' property.
   * @param type - The type to set as the 'og:type' property.
   * @param title - The title to set as the 'og:title' property.
   * @param description - The description to set as the 'og:description' property.
   * @param offerId - The offer ID to set as part of the 'og:image' property.
   * @param updated_time - The updated time to set as the 'og:updated_time' property.
   */
  setOg(url: string = this.router.url, type: string | null = null, title: string | null = null, description: string | null = null, offerId: string | null = null, updated_time: any = null) {
    console.log("setOg :");
    console.log(url);
    console.log(type);
    console.log(title);
    console.log(description);
    console.log(offerId);
    console.log(updated_time);

    if (url) {
      this.meta.updateTag(
        {
          property: 'og:url',
          content: url
        }
      );
    }
    if (type) {
      this.meta.updateTag(
        {
          property: 'og:type',
          content: type
        }
      );
    }
    if (title) {
      this.meta.updateTag(
        {
          property: 'og:title',
          content: title
        }
      );
    }
    if (description) {
      this.meta.updateTag(
        {
          property: 'og:description',
          content: description
        }
      );
    }
    if (offerId) {
      let image = environment.imgFromId + '/' + offerId;
      this.meta.updateTag(
        {
          property: 'og:image',
          content: image
        }
      );
    }
    if (updated_time) {
      this.meta.updateTag(
        {
          property: 'og:updated_time',
          content: updated_time.toString()
        }
      );
    }
  }

  /**
   * Initializes the tagging for SEO.
   * 
   * This method adds meta tags based on the current router URL. It sets the author tag to 'ClubOfficine' and the charset tag to 'UTF-8'.
   * It also sets the title and description tags based on the router URL, and updates the robots tag accordingly.
   * 
   * @returns {void}
   */
  initTaging() {
    this.meta.addTags([
      { name: 'author', content: 'ClubOfficine' },
      { charset: 'UTF-8' },
    ]);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        console.log("PAGE CHANGED");
        console.log(this.router.url);
        let title: string = "";
        let description: string = "";
        switch (this.router.url) {
          case "/":
            title = "ClubOfficine - emploi pharmacien, emploi préparateur en pharmacie";
            description = "ClubOfficine la plateforme d'emploi des métiers de la pharmacie - emploi pharmacien - emploi préparatrice en pharmacie - Officine - PUI - PSAD - Répartiteur";
            this.setCanonical();
            this.setOg(undefined, undefined, title, description, undefined, undefined);
            break;
          case "/rechercher/offres":
            // managed in page Offers
            break;
          case "/rechercher/candidats":
            // managed in page Candidat
            break;
          case "/login":
            title = "ClubOfficine - Se connecter";
            description = "Se connecter à ClubOfficine, la plateforme d'emploi des métiers de la pharmacie";
            this.setCanonical();
            this.setOg(undefined, undefined, title, description, undefined, undefined);
            break;
          case "/regions":
            title = "ClubOfficine - Emploi en pharmacie - recherche par ville";
            description = "ClubOfficine la plateforme d'emploi des métiers de la pharmacie - présent dans toute la France, dans les DOMS-TOMs, en Belgique et en Suisse";
            break;
          case "/metiers":
            title = "ClubOfficine - Emploi en pharmacie - recherche par métier";
            description = "ClubOfficine la plateforme d'emploi des métiers de la pharmacie - recherchez un emploi par métier : pharmacien - préparateur en pharmacie - Officine - PUI - PSAD - Répartiteur";
            break;
          case "/simulateur":
            title = "Simulateur de salaire des métiers de la pharmacie - ClubOfficine";
            description = "Quels sont les salaires des métiers de la pharmacie : pharmacien - préparateur en pharmacie - Officine - PUI - PSAD - Répartiteur";
            this.setCanonical();
            this.setOg(undefined, undefined, title, description, undefined, undefined);
            break;
          case "/comparateur-salaire/pharmacien":
            title = "Comparateur de salaire des pharmaciens en pharmacie d'officine - ClubOfficine";
            description = "Comparez les salaires des pharmaciens par région et par ville - Officine - PUI - PSAD";
            this.setCanonical();
            this.setOg(undefined, undefined, title, description, undefined, undefined);
            break;
          case "/comparateur-salaire/preparateur-en-pharmacie":
            title = "Comparateur de salaire des préparateurs en pharmacie - ClubOfficine";
            description = "Comparez les salaires des préparateurs en pharmacie par région et par ville - Officine - PUI - PSAD";
            this.setCanonical('/comparateur-salaire/pharmacien');
            this.setOg(undefined, undefined, title, description, undefined, undefined);
            break;

          default:
            break;
        }
        if (this.router.url.includes('/candidat/') || this.router.url.includes('/recruteur/') || this.router.url.includes('/al/') || this.router.url.includes('/tunel/') || this.router.url.includes('/souscrire/') || this.router.url.includes('/payment-') || (this.router.url.includes('/rechercher/candidats') && this.router.url !== '/rechercher/candidats')) {
          this.meta.updateTag(
            {
              name: 'robots',
              content: 'noindex, nofollow'
            }
          );
        }
        if (title) {
          this.metaTitle.setTitle(title);
          this.meta.updateTag(
            {
              name: 'description',
              content: description,
            }
          );
          if (environment.production === true) {
            this.meta.updateTag(
              {
                name: 'robots',
                content: 'index, follow'
              }
            );
          } else {
            this.meta.updateTag(
              {
                name: 'robots',
                content: 'noindex, nofollow'
              }
            );
          }
        }
      })).subscribe();
  }
}
