<ion-header no-border>
    <ion-toolbar class="ion-text-center">
        <ion-title>Ajouter une experience</ion-title>
        <ion-buttons slot="end" class="modalButton">
            <ion-button (click)="closeModal()">
                <ion-icon color="blanc" name="close" showWhen="android,windows, ios"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>


<ion-content>

    <form [formGroup]="step1Form">
        <ion-grid class="mainGrid ion-no-padding">

            <ion-row align-items-top>
                <ion-col size="4" class="topLeftImageCol">
                    <img src="./assets/imgs/experience/officine.jpg">
                </ion-col>
                <ion-col size="8" class="colRightOfImage ion-no-margin ion-no-padding">

                    <ion-row *ngIf="companyTypeList && step1Form?.controls?.['companyType']"
                        class="ion-no-margin ion-no-padding">
                        <ion-col class="ion-no-margin ion-no-padding" size="12">
                            <ion-item>
                                <ion-label color="medium" position="floating">Établissement</ion-label>
                                <ion-select interface="popover" [interfaceOptions]="customPopoverOptions"
                                    formControlName="companyType">
                                    <ion-select-option *ngFor="let companyTypeEl of companyTypeList"
                                        value={{companyTypeEl.uid}}>{{companyTypeEl.label}} </ion-select-option>
                                </ion-select>
                            </ion-item>
                            <!-- <p *ngIf="step1Form?.controls?.['companyType']?.errors && step1Form?.controls?.['companyType']?.['errors']?.['required'] && (step1Form?.controls?.['companyType']?.touched || buttonValidStepPressed)"
                                class=errorFormInfo>Type d'établissement manquant</p> -->
                        </ion-col>
                    </ion-row>

                    <ion-row class="ion-no-margin ion-no-padding">
                        <ion-col class="ion-no-margin ion-no-padding" size="12">
                            <ion-item class="occupeActuellement">
                                <ion-toggle color='secondary' labelPlacement="end" formControlName="inOffice">J'occupe
                                    actuellement ce poste</ion-toggle>
                            </ion-item>
                        </ion-col>
                    </ion-row>

                </ion-col>
            </ion-row>

            <ion-row class="ion-no-margin ion-no-padding">
                <ion-col class="ion-no-margin ion-no-padding" size="6">
                    <ion-item>
                        <ion-label color="medium" position="stacked">Date de début</ion-label>

                        <!-- <ion-input type="month" formControlName="dateStart" min="1970" [max]="today | date:'y-MM-dd'"></ion-input> -->
                        <!-- <ion-datetime formControlName="dateStart" min="1970" [max]="dateMax | date:'y-MM-dd'" doneText="OK" cancelText="Annuler" displayFormat="MM/YYYY"></ion-datetime> -->
                        <ion-datetime-button datetime="datetime"></ion-datetime-button>
                        <ion-modal [keepContentsMounted]="true">
                            <ng-template>
                                <ion-datetime id="datetime" formControlName="dateStart" min="1970"
                                    [max]="dateMax | date:'y-MM-dd'" doneText="OK" cancelText="Annuler"
                                    presentation="month-year"></ion-datetime>
                            </ng-template>
                        </ion-modal>


                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['dateStart']?.errors?.['required'] && (step1Form?.controls?.['dateStart']?.touched && step1Form?.controls?.['title']?.touched || buttonValidStepPressed)"
                        class=errorFormInfo>date manquante</p>
                </ion-col>
                <ion-col class="ion-no-margin ion-no-padding" size="6">
                    <ion-item>
                        <ion-label color="medium" position="stacked">Date de fin</ion-label>
                        <!-- <ion-input *ngIf="!step1Form.value.inOffice" type="month" formControlName="dateEnd" min="1970" [max]="dateMax | date:'y'" pattern="\d{4}-\d{2}"></ion-input> -->
                        <!-- <ion-datetime *ngIf="!step1Form.value.inOffice" formControlName="dateEnd" min="1970" [max]="dateMax | date:'y'" doneText="OK" cancelText="Annuler" displayFormat="MM/YYYY"></ion-datetime> -->
                        <ion-datetime-button *ngIf="!step1Form.value.inOffice"
                            datetime="datetime2"></ion-datetime-button>
                        <ion-modal [keepContentsMounted]="true">
                            <ng-template>
                                <ion-datetime id="datetime2" formControlName="dateEnd" min="1970"
                                    [max]="dateMax | date:'y-MM-dd'" doneText="OK" cancelText="Annuler"
                                    presentation="month-year"></ion-datetime>
                            </ng-template>
                        </ion-modal>

                        <ion-input *ngIf="step1Form.value.inOffice" class="step1Form.value.inOffice" color="noir"
                            value="aujourd'hui" readonly aria-label="en poste"> </ion-input>
                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['dateEnd']?.errors?.['required'] && step1Form?.controls?.['dateEnd']?.touched && step1Form?.controls?.['title']?.touched && !step1Form?.value.inOffice || (buttonValidStepPressed && !step1Form?.value?.inOffice && step1Form?.value?.dateEnd == '')"
                        class=errorFormInfo>date manquante</p>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="qualificationList && step1Form?.controls?.['qualification']"
                class="ion-no-margin ion-no-padding">
                <ion-col class="ion-no-margin ion-no-padding" size="12">
                    <ion-item>
                        <ion-label color="medium" position="floating">Qualification</ion-label>
                        <ion-select interface="popover" [interfaceOptions]="customPopoverOptions"
                            formControlName="qualification">
                            <ion-select-option *ngFor="let qualification of qualificationList"
                                value={{qualification.uid}}>{{qualification.label}} </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['qualification']?.errors?.['required'] && (step1Form?.controls?.['qualification']?.touched || buttonValidStepPressed)"
                        class=errorFormInfo>Qualification manquante</p>
                </ion-col>
            </ion-row>

            <ion-row class="ion-no-margin ion-no-padding">
                <ion-col class="ion-no-margin ion-no-padding" size="12">
                    <ion-item>
                        <ion-label color="medium" position="floating">Nom de l'établissement</ion-label>
                        <ion-input formControlName="companyName" aria-label="Entreprise"></ion-input>
                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['companyName']?.errors?.['required'] && (step1Form?.controls?.['companyName']?.touched || buttonValidStepPressed)"
                        class=errorFormInfo>Nom manquant</p>
                </ion-col>
            </ion-row>
            <ion-row class="ion-no-margin ion-no-padding adressRow">
                <ion-col class="ion-no-margin ion-no-padding" size="12">

                    <ion-item class="item--searchAddress">
                        <app-search-address *ngIf="dataInitiated" [searchAddresses]="[fullAdress]" [nbMaxAddress]="1"
                            [displayRadius]=false [addressType]="'city'" [placeholder]="'Ville de l\'expérience'"
                            [displayMobileIfHoused]=false [customSearchBarStyle]="searchAddressBorderStyle"
                            (updateSearchAddresses)="updateAdress($event)"></app-search-address>
                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['address']?.errors?.['required'] && (step1Form?.controls?.['address']?.touched || buttonValidStepPressed)"
                        class=errorFormInfo>Adresse manquante</p>
                </ion-col>
            </ion-row>



            <ion-row class="ion-no-margin ion-no-padding">
                <ion-col class="ion-no-margin ion-no-padding" size="12">
                    <ion-item>
                        <ion-label color="medium" position="floating">Description :</ion-label>
                        <ion-textarea rows="4" formControlName="description" [counter]="true" label=""
                            labelPlacement="floating" [counterFormatter]="customCounterFormatter" maxlength="1000">
                            <!-- <div slot="label"><ion-text color="medium">Description</ion-text></div> -->
                        </ion-textarea>
                    </ion-item>
                    <p *ngIf="step1Form?.controls?.['description']?.errors?.['required'] && (step1Form?.controls?.['description']?.touched || buttonValidStepPressed)"
                        class=errorFormInfo>Lieu manquant</p>
                </ion-col>
            </ion-row>

            <p *ngIf="step1Form && step1Form.status != 'VALID' && buttonValidStepPressed" class=errorFormInfoGlobal>Des
                erreurs ont été détectées dans le formulaire. Veuillez corriger les champs en rouge ci-dessus.</p>
            <ion-button class="validStepButton" expand="block" (click)="validExperience()">Valider
                l'expérience</ion-button>
            <ion-button class="validStepButton" color="danger" expand="block" (click)="removeExperience()">Supprimer
                l'expérience</ion-button>


        </ion-grid>
    </form>

</ion-content>