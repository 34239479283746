import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, from, EMPTY } from 'rxjs';
import { tap, map, switchMap, take, filter, flatMap, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { PlatformService } from '../services/platform/platform.service';
import { environment } from 'src/environments/environment';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  lastErrorUrl: string = "";
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private previousForbidenRequest: any = null;

  constructor(
    private authService: AuthService,
    private platformService: PlatformService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let nbError: number = 0;
    return next.handle(request).pipe(
      catchError(err => {
        if (nbError == 0) {
          console.log('error interceptor');
          console.log(request);
          console.log(err);
          if (this.lastErrorUrl != request.urlWithParams || this.platformService.is('ios')) {
            let body = "";
            if (request.body && (request.method == 'PUT' || request.method == 'POST') && !request.urlWithParams.includes('auth')) {
              body = JSON.stringify(request.body);
              body = ' PAYLOAD ' + body;
            }
            if (!([0, 403].includes(err.status))) {
              let error = "";
              if (typeof err === 'string') {
                error = err;
              } else if (typeof err.error === 'string') {
                error = err.error;
              } else if (err.message) {
                error = err.message;
              } else if (err['hydra:description']) {
                error = err['hydra:description'];
              } else if (err.error['hydra:description']) {
                error = err.error['hydra:description'];
              }
              let errorStatus = "";
              if (err?.status) {
                errorStatus = err.status.toString();
              }
              let method = "";
              if (request.method) {
                method = request.method;
              }
              if (!environment.isPwa) {
                FirebaseCrashlytics.recordException({ message: method + ' BODY' + request.urlWithParams + ' ' + body + ' ERROR : ' + errorStatus + ' : ' + error });
              }
            }
            this.lastErrorUrl = request.urlWithParams;
          }
          nbError++;
        }
        if ([401, 403].includes(err.status)) {
          console.log('INTERCEPTOR 401 or 403');
          console.log(err);
          console.log(request);
          if (this.previousForbidenRequest?.url == request?.url) {
            console.log('INTERCEPTOR 403 previousForbidenRequest');
            this.previousForbidenRequest = request;
            return throwError(err);
          } else {
            console.log('INTERCEPTOR 403 previousForbidenRequest null');
            this.previousForbidenRequest = request;
            return this.handleForbidenError(request, next);
          }
        }
        return throwError(err);
      }));
  }



  private handleForbidenError(request: HttpRequest<any>, next: HttpHandler) {
    console.log('INTERCEPTOR handleForbidenError start');

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.requestNewAccessToken().pipe(
        switchMap((token: any) => {
          console.log('INTERCEPTOR new token created');
          console.log(token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token['accessToken']);
          return next.handle(this.addToken(request, token['accessToken']));
        }));
    } else {
      console.log('INTERCEPTOR handleForbidenError not refreshing');
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }

  }


  private addToken(request: HttpRequest<any>, token: string) {
    console.log('INTERCEPTOR addToken');
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/ld+json',
      }
    });
  }
}
